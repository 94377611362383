import { Grid, withStyles } from '@material-ui/core';

export const GridContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
    height: '100%',
    position: 'relative',
    flexWrap: 'nowrap',
  },
}))(Grid);
