import {
	GETOPENEDCHATS,
	GETOPENEDCHATS_RESULT,
	GETOPENEDCHATS_ERROR,
	onMessage,
	CHATMESAGESTATE,
	GETMESSSAGESBEFORE,
	GETMESSAGEHISTORY_RESULT,
	SETLASTCHATID,
  ONLINESTATUSCHANGED,
  CHATOPENEDSTATUSCHANGED,
  SHOWNOTEMESSAGE,
  ASSIGNCHAT,
  ASSIGNCHAT_RESULT,
  ASSIGNCHAT_ERROR,
  ALLOWCHATSCROLLING,
  SETMESSAGESTATE,
  SENDMESSAGE,
  SENDMESSAGE_RESULT,
  SENDMESSAGE_ERROR,
  SENDTYPINGSTATE,
  USERTYPING,
  SHOWTYPINGOFF,
  LEAVECHAT,
  LEAVECHAT_RESULT,
  LEAVECHAT_ERROR,
  BEGINUPLOADMESSAGE
} from './constants';
import { sendMessage } from '../comm/sender';
import { getChatHistoryDate, saveHistoryMessages, setLastUserMessage, setMessageState, setMessageSent } from '../../lib/utils';
import moment from 'moment';

let initialState = {
  openedChats: [],
	lastMessage: -1,
	exportUserHistroyChat: [],
  openSnackbar: false,
  messageSnackbar: '',
  usertyping: false,
  imageMessage: false,
}


export default (state = initialState, action) => {
	switch (action.type) {
    case GETOPENEDCHATS:
      sendMessage('getOpenedChats');
			return {...state}
    case GETOPENEDCHATS_RESULT: {
      let params = [];
      if (action.params.params[0] !== null) {
        params = action.params.params[0];
      }
      return { ...state, openedChats: params};
    }
    case GETOPENEDCHATS_ERROR:
			return {...state}
		case onMessage:
      setLastUserMessage(action.params);
      return { ...state, lastMessage: action.params[2].msg_id, allowChatScroll: true };
    case CHATMESAGESTATE:
      setMessageState(action.params);
      return { ...state, lastMessageStatus: action.params[0] + '-' + action.params[1] };
    case GETMESSSAGESBEFORE: {
      const chat_date = getChatHistoryDate(action.params[0], action.params[1]);

      if (!chat_date) return { ...state, oldMessagesDate: false, allowChatScroll: false };

      if (chat_date) {
        let dayStart = moment(chat_date).format('YYYY-MM-DD 00:00:00');
        let dayEnd = moment(dayStart).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        sendMessage('getMessageHistory', [1, 0, action.params[0], dayStart, dayEnd]);
        return { ...state, oldMessagesDate: dayStart, allowChatScroll: action.params[1] === false ? false : true };
      } else {
        return { ...state, oldMessagesDate: false, allowChatScroll: true };
      }
    }
    case GETMESSAGEHISTORY_RESULT:
      if (action.params.params[0].length > 0) {
        action.params.params[0].forEach((item) => {
          if (item.sender_user_type === 3 || item.sender_user_type === 2) {
            item.sender = parseInt(localStorage.getItem('uid'))
          }
        })
        saveHistoryMessages(action.params.params[0]);
      }
      return { ...state, oldMessagesDate: false, exportUserHistroyChat: action.params.params[0] };
    case SETLASTCHATID:
      return { ...state, lastChatId: action.params };
    case ONLINESTATUSCHANGED:
      sendMessage('getOpenedChats');
      return { ...state, onlineStatusChanged: action.params };
    case CHATOPENEDSTATUSCHANGED:
      sendMessage('getOpenedChats');
      return { ...state, chatOpenedStatusShanged: action.params };
    case SHOWNOTEMESSAGE:
      return { ...state, openSnackbar: true, messageSnackbar: action.params };
    case ASSIGNCHAT:
      sendMessage('assignChat', [action.params])
			return {...state}
    case ASSIGNCHAT_RESULT:
      sendMessage('getOpenedChats');
			return {...state}
    case ASSIGNCHAT_ERROR:
			return {...state}
    case ALLOWCHATSCROLLING:
      return { ...state, allowChatScroll: true };
    case SETMESSAGESTATE:
      sendMessage('setChatMessageWebState', action.params);
      return { ...state, lastMessageId: action.params[0] };
    case SENDMESSAGE:
      sendMessage('sendMessage', action.params);
			return {...state}
    case SENDMESSAGE_RESULT:
      setMessageSent(action.params.params[0]);
			return {...state}
    case SENDMESSAGE_ERROR:
			return {...state}
    case SENDTYPINGSTATE:
      sendMessage('sendTypingState', action.params);
			return {...state}
		case USERTYPING:
      if (state.userType === 4) {
        return { ...state, usertyping: action.params[0], merchantUidTyping: action.params[1] };
      } else {
        return { ...state, usertyping: action.params };
      }
    case SHOWTYPINGOFF:
      return { ...state, usertyping: false };
    case LEAVECHAT:
      sendMessage('leaveChat', [action.params])
			return {...state}
    case LEAVECHAT_RESULT:
      sendMessage('getOpenedChats');
			return {...state}
    case LEAVECHAT_ERROR:
			return {...state}
    case BEGINUPLOADMESSAGE:
      sendMessage('beginUpload', action.params);
      return { ...state, imageMessage: true };
		default: 
		return {...state}
	}
}