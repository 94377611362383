import React, {Component} from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, Typography, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './styles.css';

export default class InviteCustomers extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Dialog
				open={this.props.openInviteCustomers}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text'>
					<ArrowBackIcon onClick={this.props.handleCloseInviteCustomers} className="order-item-edit-dialog-back-btn" />
					Invite Customers to Piip
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogContent className='setting-about-content'>
					<Grid item container className='settings-about-main-text-grid'>
						<Typography className='settings-about-text'>Piip</Typography>
						<Typography className='settings-invite-customers-link'>https://play.google.com/store/apps/details?id=com.piip.piipstandard</Typography>
						<Typography className='settings-about-text'>Biiz</Typography>
						<Typography className='settings-invite-customers-link'>https://play.google.com/store/apps/details?id=com.piip.piipbiiz</Typography>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}