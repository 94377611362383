import { withStyles, Button, FormControl } from '@material-ui/core';

export const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#cf5ada',
    '&:hover': {
      backgroundColor: '#cf5ada',
    },
		fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
		lineHeight: 1.4,
    borderRadius: '12px',
    width: '200px',
    height: '50px',
    position: 'relative',
    boxShadow: '2px 3px 6px 0 rgba(0, 0, 0, 0.16)',
    textTransform: 'none',
  },
}))(Button);

export const FormControlCss = withStyles(theme => ({
  root: {
    fontSize: '20px', 
    color: 'var(--title-color)',
    lineHeight: 1, 
    fontWeight: 300,
    ['@media (min-width: 769px)']: {
      width: '360px'
    },
    padding: 0,
    ['@media (max-width: 768px)']: {
      maxWidth: '360px',
      marginBottom: '5px'
    },
  },
}))(FormControl);