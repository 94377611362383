export const GETPROMOTIONS = 'GETPROMOTIONS'
export const GETPROMOTIONS_RESULT = 'GETPROMOTIONS_RESULT'
export const GETPROMOTIONS_ERROR = 'GETPROMOTIONS_ERROR'
export const RECREATEPROMOTION = 'RECREATEPROMOTION'
export const RECREATEPROMOTION_RESULT = 'RECREATEPROMOTION_RESULT'
export const RECREATEPROMOTION_ERROR = 'RECREATEPROMOTION_ERROR'
export const GETSUBPROMOTIONS = 'GETSUBPROMOTIONS'
export const GETSUBPROMOTIONS_RESULT = 'GETSUBPROMOTIONS_RESULT'
export const GETSUBPROMOTIONS_ERROR = 'GETSUBPROMOTIONS_ERROR'
export const DELETESUBPROMOTIONS = 'DELETESUBPROMOTIONS'
export const DELETESUBPROMOTIONS_RESULT = 'DELETESUBPROMOTIONS_RESULT'
export const DELETESUBPROMOTIONS_ERROR = 'DELETESUBPROMOTIONS_ERROR'
export const SETPROMOTIONLONG = 'SETPROMOTIONLONG'
export const SETPROMOTIONLONG_RESULT = 'SETPROMOTIONLONG_RESULT'
export const SETPROMOTIONLONG_ERROR = 'SETPROMOTIONLONG_ERROR'
export const PUBLISHPROMOTION = 'PUBLISHPROMOTION'
export const PUBLISHPROMOTION_RESULT = 'PUBLISHPROMOTION_RESULT'
export const PUBLISHPROMOTION_ERROR = 'PUBLISHPROMOTION_ERROR'
export const ENDPROMOTION = 'ENDPROMOTION'
export const ENDPROMOTION_RESULT = 'ENDPROMOTION_RESULT'
export const ENDPROMOTION_ERROR = 'ENDPROMOTION_ERROR'
export const ASSIGNSSUBPPROMOTION = 'ASSIGNSSUBPPROMOTION'
export const DELETEPROMOTIONS = 'DELETEPROMOTIONS'
export const DELETEPROMOTIONS_RESULT = 'DELETEPROMOTIONS_RESULT'
export const DELETEPROMOTIONS_ERROR = 'DELETEPROMOTIONS_ERROR'
export const SETSUBPROMOTION = 'SETSUBPROMOTION'
export const SETSUBPROMOTION_RESULT = 'SETSUBPROMOTION_RESULT'
export const SETSUBPROMOTION_ERROR = 'SETSUBPROMOTION_ERROR'
export const PUBLISHSUBPROMOTION = 'PUBLISHSUBPROMOTION'
export const PUBLISHSUBPROMOTION_RESULT = 'PUBLISHSUBPROMOTION_RESULT'
export const PUBLISHSUBPROMOTION_ERROR = 'PUBLISHSUBPROMOTION_ERROR'
export const UNPUBLISHSUBPROMOTION = 'UNPUBLISHSUBPROMOTION'
export const UNPUBLISHSUBPROMOTION_RESULT = 'UNPUBLISHSUBPROMOTION_RESULT'
export const UNPUBLISHSUBPROMOTION_ERROR = 'UNPUBLISHSUBPROMOTION_ERROR'

