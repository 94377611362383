/* eslint-disable */
import { Redirect, Route, Switch, Router } from "react-router-dom";
import { history } from '../store/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from "react";
import { getDefaultRoute } from '../lib/utils';
import ProtectedRoute from '../components/ProtectedRoute';
import navConfig from '../navConfig';
import Load from './load';
import Spinner from './spinner';
import { connectWeb, verifyAdmin, loader } from '../store/auth/actions';

const mapRoutes = (routes, extraProps = {}) => {
	return routes.filter(route => !!route.path && !!route.component).map(item => {
		const { path } = item
		const ActualRoute = item.protected ? ProtectedRoute : Route
		return (
			<ActualRoute exact={ item.exact } path={ path } key={ 'nav-key-' + path } route={ item }
				cmp={ item.component }
				render={ props =>
					item.render ?
						item.render({ ...props, ...extraProps, route: item })
						:
						<item.component { ...props } { ...extraProps } route={ item } />
				}
			/>
		)
	})
}

class App extends React.Component {
	constructor(props) {
		super(props)

		this.defaultRoute = []
		this.routeComponents = []
		this.authRouteComponents = []
		this.additionalRouteComponents = []
		this.notInMenuRouteComponents = []

		this.content = null
	}

	componentDidMount() {
    history.listen(this.handleRouteChange)
    this.handleRouteChange(location)
		this.props.loader(false)
	}

	generateRoutes = () => {
		this.defaultRoute = getDefaultRoute()
		this.routeComponents = mapRoutes(navConfig.routes)
		this.authRouteComponents = mapRoutes(navConfig.authRoutes)
		this.additionalRouteComponents = mapRoutes(navConfig.additionalRoutes)
		this.notInMenuRouteComponents = mapRoutes(navConfig.notInMenuRoutes)
	}

  handleRouteChange = (location) => {
    if (location.search !== "") {
      const queryString = location.search;
			const urlParams = new URLSearchParams(queryString);
			const tokk = urlParams.get('tokk')
			const uid = parseInt(urlParams.get('uid'))
			const realToken = tokk.substring(0, tokk.length - 2);
			const tokener = tokk.slice(-2);
			localStorage.setItem('token', realToken);
			localStorage.setItem('tokener', tokener + 'd8dG73FfDr4rE5ddf');
			localStorage.setItem('adminlogin', 1);
			localStorage.setItem('uid', uid);
			localStorage.removeItem('chatMessages');
			localStorage.removeItem('messages');
			localStorage.removeItem('contactsInfo');
			localStorage.removeItem('usersData');
			localStorage.removeItem('merchantCard');
    }
  }

	// historyListener = (location, a,b, c) => {
  //   this.handleRouteChange(location)
	// }

  render() {
		const {initLoading} = this.props
		// wait for init to finish
		if (!initLoading) {
			this.generateRoutes()
			this.content = null
		} else {
			this.content =	<Spinner/>
		}

    return (
      <Router history={ history }>
			{ this.content !== null ? this.content :
        <Switch>
					{ this.routeComponents }
					{ this.authRouteComponents }
					{ this.additionalRouteComponents }
					{ this.notInMenuRouteComponents }
					{ this.defaultRoute ? <Route exact path="/" render={ () => <Redirect to={ this.defaultRoute.path } /> } /> : null }
        </Switch>
			}
      </Router>
    );
  }
}

export default connect(({ auth }) => {
  return {
    uid: auth.uid,
		userType: auth.userType,
		isAuthorized: auth.isAuthorized,
		initLoading: auth.initLoading,
		isRegistered: auth.isRegistered,
  }
}, (dispatch) => {
  const actions = {connectWeb, verifyAdmin, loader};
  return bindActionCreators(actions, dispatch);
})(App);
