import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';
// import Icon from '@material-ui/core/Icon';
import WrapperContent from '../../components/WrapperContent'
import Layout from '../../components/Layout';
import './styles.css'
import { connectWeb } from '../../store/auth/actions';
import { getAgentInfo, getContacts, getAgents, setFlag, toggleContactBlock } from '../../store/common/actions';
import { getOpenedChats, assignChat, getMessagesBefore, setLastChatId, leaveChat } from '../../store/messages/actions';
import {
  resetUnreadCounter,
  seeMessages,
  getProfileData,
  displayOpenedChat,
  clearChatHistory,
  assignedByAgent,
  blockUser,
  getUserBlockStatus
} from '../../lib/utils';
import { history } from '../../store/index';
import moment from 'moment';
import UserProfile from '../../components/UserProfile';
import Chat from '../../components/Chat';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMessages: '',
      openedChats: [],
      newMessage: false,
      userProfileArr: [],
      userSelected: false,
      selectedUserId: null,
      spinner: false,
      selectedUserProfile: '',
			userId: null,
			qrCode: false,
      selectedChat: '',
      enableAssign: false,
      chatAssigned: false,
      dataArr: []
    }
    this.oldestMessageTime = null;
  }

  componentDidMount() {
    if (this.props.contactsInfo.length > 0) {
      this.handleUserProfile();
    }
    this.props.getOpenedChats();
    if (this.props.userType !== 3 && this.props.userType !== 4) {
			this.props.connectWeb([this.props.uid, this.props.token, this.props.userType]);
		}

		if (this.props.userType === 3) {
			this.props.getAgentInfo([this.props.uid]);
		} else if (this.props.userType === 4) {
			this.props.getGeneralAgentInfo([this.props.uid]);
		}

		if (this.props.userType !== 4) {
			// this.props.setActivePage('messages');
			this.props.getContacts();
			this.props.getOpenedChats();
			this.props.getAgents();
		}

    if (this.props.lastChatId !== undefined) {     //lastChatId je na kog sam user-a zadnjeg kliknuo
      let assignedChatItem = this.props.openedChats.find(item => {
        if (this.props.lastChatId === parseInt(item.user)) {
          return item
        }
      })
      if (assignedChatItem !== undefined && assignedChatItem.assigned && assignedChatItem.agent === this.props.uid) {
        this.renderChat(this.props.lastChatId);
      } else if (assignedChatItem === undefined){
        this.renderChat(this.state.lastChatId);
      } else {
        this.setState({selectedChat: (
          <Chat
            messages='Please assign chat if not assigned by another agent'
          />
        )});
      }
    }
    this.setState({openedChats: this.props.openedChats})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onlineStatusChanged !== this.props.onlineStatusChanged) {
      if (this.state.dataArr.length > 0) {
        this.onlineStatusChange(this.state.dataArr)
      } else {
        this.onlineStatusChange(this.state.userProfileArr)
      }
    }

    if (prevProps.contactsInfo !== this.props.contactsInfo) {
      this.handleUserProfile();
    }
    if (prevProps.openedChats !== this.props.openedChats) {
      this.setState({openedChats: this.props.openedChats})
    }
    if (!this.props.isValidToken) history.push('/register');
    resetUnreadCounter(this.state.selectedUserId);
    let that = this;
    if (
       prevProps.lastMessage !== this.props.lastMessage ||
       prevProps.lastMessageStatus !== this.props.lastMessageStatus ||
       prevProps.contactsInfo !== this.props.contactsInfo ||
       prevProps.oldMessagesDate !== this.props.oldMessagesDate
    ) 
    {
      if (this.state.selectedChat !== '') {
        let assignedChatItem = prevProps.openedChats.find(item => {
          if (prevProps.lastChatId === parseInt(item.user)) {
            return item
          }
        })
        if (assignedChatItem !== undefined && assignedChatItem.assigned && assignedChatItem.agent === prevProps.uid) {
          this.renderChat(this.state.selectedUserId);
        } else if (assignedChatItem === undefined) {
            if (this.state.selectedUserId !== null) {
              this.renderChat(this.state.selectedUserId);
            } else {
              this.setState({
                selectedChat: (
                  <Chat
                    messages='Answer and communicate with client as soon as possible to achieve best customer service and satisfaction!'
                  />
                ),
              });
            }
        } else {
          if (parseInt(assignedChatItem.user) === this.state.selectedUserId) {
            this.setState({
              selectedChat: (
                <Chat
                  messages='Please assign chat if not assigned by another agent'
                />
              ),
            });
          }
        }
      }
      // this.forceUpdate();
    }

    if (prevProps.lastMessage !== this.props.lastMessage) {
      this.setState({ newMessage: true });
    }

    //some of users change his profile
    if (prevProps.userChangeProfile !== this.props.userChangeProfile) {
      setTimeout(function(){
        that.handleUserProfile();
      },0);
      //reset value
      this.props.setFlag({ key: 'userChangeProfile', value: false });
    }
  }

  handleUserProfile = () => {
    const arr = [];
    let contacts = localStorage.getItem('contactsInfo');
    // let usersData = localStorage.getItem('usersData');
    contacts = contacts === null ? [] : JSON.parse(contacts);
    // usersData = usersData === null ? [] : JSON.parse(usersData)

    contacts.forEach(contact => {
      const userId = contact.phone_data[0].user_id;
      const phoneNumber = contact.phone_data[0].phone;
      const profileData = getProfileData(userId);
      const element = {};
      element.id = userId;
      element.phone = phoneNumber;
      element.userName = profileData.display_name;
      element.avatar = profileData.avatar;
      element.lastMessageTime = contact.lastMessageTime !== undefined ? moment(contact.lastMessageTime) : moment().subtract(userId, 'hours');
      if (element.userName !== '' && element.userName !== null) {
        arr.push(element);
      }
    });
    const retArr = arr.sort(this.compare2);
    this.oldestMessageTime = retArr[retArr.length - 1].lastMessageTime;
    this.setState({userProfileArr: retArr})
  };

  compare2 = (a, b) => {
    if (a.lastMessageTime > b.lastMessageTime) {
      return -1;
    }
    if (a.lastMessageTime < b.lastMessageTime) {
      return 1;
    }
    return 0;
  }

  turnOfSpinner = () => {
    this.setState({ spinner: false });
  }

  getAvatar = (uid) => {
    const profileData = getProfileData(uid);
    return profileData.avatar;
  };

  getAllUserMessages = (uid) => {
    const arr = {};
    let i;
    let day;
    const ls = localStorage.getItem('chatMessages');
    const chatMessages = ls === null ? [] : JSON.parse(ls);
    const resIndex = chatMessages.findIndex(message => {
      return (
        message !== undefined &&
        message !== null &&
        (
          message[0] !== undefined &&
          message[0] !== null
        ) &&
        message[0][0] === uid
      );
    });

    const data = resIndex !== -1 ? chatMessages[resIndex] : [];
    //{mid: 1, content: "New messae 1.", uid: 5, time: new Date()},
    const avatars = {};
    for (i in data) {
      const element = {};
      const msg = data[i];
      if (msg !== undefined && msg[0] !== undefined) {
        if (avatars[msg[0]] === undefined) {
          avatars[msg[0]] = this.getAvatar(uid);
        }
        element.mid = msg[2].msg_id;
        element.content = msg[2].text;
        element.read = msg[2].read;
        element.type = msg[2].msg_type;
        element.attachment = msg[2].attachment;
        element.location = msg[2].location;
        element.uid = msg[0];
        element.time = new Date(msg[1]);
        element.forWeb = msg[2].forWeb;
        element.view_status = msg[2].view_status;
        element.mineMsg = msg[4];
        element.avatar = avatars[msg[0]];
      }
      day = moment(element.time).format('YYYY-MM-DD');
      if (!arr[day]) {
        arr[day] = [];
      }
      arr[day].push(element);
    }

    const retArr = [];
    const that = this;

    const ret = {};
    Object.keys(arr).sort().forEach(key => {
      ret[key] = arr[key];
    });

    Object.keys(ret).forEach(key => {
      ret[key].sort(that.compare);
      ret[key].forEach((item) => retArr.push(item));
    });

    this.turnOfSpinner();
    return retArr;
  };

  renderChat = (id) => {
    const allMessages = this.getAllUserMessages(id);
    let dataSort = allMessages.sort(function(a,b) {
      return(
        a.time - b.time
      )
    })

    const uid = id;
    const userData = getProfileData(uid);

    this.setState({
      selectedChat: (
        <Chat
          handleMessageItemLeave={this.handleMessageItemLeave}
          chatAssigned={this.state.chatAssigned}
          data={dataSort}
          userUid={id}
          userData={userData}
          messages=''
          renderChat={this.renderChat}
          userSelected={this.state.userSelected}
        />
      )
    })
  };

  getOpenedChat = (uid) => {
    return displayOpenedChat(uid, this.props.openedChats);
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clearHistory = () => {
    return new Promise(resolve => {
      clearChatHistory();
      resolve();
    });
  }

  getOpenedChatTimer = (uid, time) => {
    let localStorageChatTimer = localStorage.getItem('openedChatTime' + uid);
    if (localStorageChatTimer === null) {
      localStorage.setItem('openedChatTime' + uid, time);
      return time
    } else {
      return parseInt(localStorageChatTimer);
    }
  }

  handleMessageItemClick = (id) => {
    this.props.getOpenedChats();

    this.setState({ spinner: true });
    this.sleep((200)).then(() => {
      this.clearHistory().then(() => {
        let assignedChatItem = this.state.openedChats.find(item => {
          if (id === parseInt(item.user)) {
            return item
          }
        })
        if (this.props.userType === 3 || this.props.userType === 4) {
          if (assignedChatItem !== undefined && assignedChatItem.assigned && assignedChatItem.agent === this.props.uid) {
            resetUnreadCounter(id);
            this.renderChat(id);
            this.props.getMessagesBefore([id, true]);
            this.props.setLastChatId(id);
            this.getOpenedChatTimer(id, Date.now())
            this.setState({ selectedUserProfile: '' });
          } else if (assignedChatItem === undefined) {
            localStorage.removeItem('openedChatTime' + id);
            resetUnreadCounter(id);
            this.renderChat(id);
            localStorage.removeItem('openedChatTime' + id)
            this.props.getMessagesBefore([id, true]);
            this.props.setLastChatId(id);
            this.setState({ selectedUserProfile: '' });
          } else {
            localStorage.removeItem('openedChatTime' + id)
            this.props.setLastChatId(id);
            this.setState({selectedChat: (
              <Chat
                messages='Please assign chat if not assigned by another agent'
              />
            )});
            this.setState({ spinner: false });
          }
        } else {
          resetUnreadCounter(id);
          this.renderChat(id);
          this.props.getMessagesBefore([id, true]);
          this.props.setLastChatId(id);
          this.setState({ selectedUserProfile: '' });
        }
      });
    });
  }

  resetNewMessage = () => {
    this.setState({ newMessage: false });
  };

  getAssignedStatus = (uid) => {
    const agentId = this.props.uid
    return assignedByAgent(uid, this.props.openedChats, agentId)
  }

	handleUserSelect = (id, qrCode) => {
    let openedChat = this.getOpenedChat(id);
    let assignedBy = this.getAssignedStatus(id);
    if (openedChat === 'Opened chat') {
      this.setState({
        enableAssign: true,
        chatAssigned: true
      })
    } else {
      this.setState({
        enableAssign: false,
        chatAssigned: false
      })
    }
    if (assignedBy[0] === 'Assigned') {
      if (assignedBy[1] === this.props.uid) {
        this.setState({
          enableAssign: false,
          chatAssigned: true
        })
      }
    }

    if (openedChat === 'Opened chat' && assignedBy[0] === 'Assigned') {
      this.setState({
        chatAssigned: true
      })
    }

		this.setState({
      selectedUserId: id,
			userSelected: true,
			qrCode: true,
		})
    this.handleMessageItemClick(id)
	}

  handleUserAssign = () => {
    const { selectedUserId } = this.state;
    this.setState({enableAssign: false})
    let oChat = this.props.openedChats.find(item => {
      if (selectedUserId === parseInt(item.user)) {
        return item
      }
    })
    this.props.getOpenedChats();
    if (oChat !== undefined && oChat.agent === null || oChat !== undefined && oChat.agent === this.props.uid) {
      this.props.assignChat(selectedUserId);
      this.handleMessageItemClick(selectedUserId)
    } else {
      this.setState({selectedChat: (
        <Chat
          messages='Chat is already assigned'
        />
      )});
    }
  }

  handleMessageItemLeave = () => {
    const { selectedUserId } = this.state;
    let oChat = this.props.openedChats.find(item => {
      if (selectedUserId === parseInt(item.user)) {
        return item
      }
    })
    if (oChat!== undefined && oChat.agent === parseInt(this.props.uid)) {
      localStorage.removeItem('timer' + selectedUserId);
      localStorage.removeItem('openedChatTime' + selectedUserId);
      this.props.leaveChat(selectedUserId);
      this.props.setLastChatId(null);
      this.setState({
        selectedUserId: null,
        chatAssigned: false,
        userSelected: false,
        qrCode: false,
        selectedUserProfile: '',
        enableAssign: false,
        selectedChat: (
          <Chat
            messages='Answer and communicate with client as soon as possible to achieve best customer service and satisfaction!'
          />
        ),
        spinner: false
      });
    }
  };

  blockUser = (params) => {
    this.props.toggleContactBlock(params.params);
    this.forceUpdate();
  }

  handleBlockClient = () =>{
    const { selectedUserId } = this.state;
    const userStatus = getUserBlockStatus(selectedUserId);
    blockUser(selectedUserId, !userStatus);
    const pd = getProfileData(selectedUserId, true)
    if (pd !== undefined && pd.contact_id !== undefined) {
      this.blockUser({ params: [pd.contact_id, !userStatus, false] });
    }
  }

  onlineStatusChange = (data) => {
    const { onlineStatusChanged } = this.props;

    let result = data.map((data) => {
      let online = false;
      if (data.id === onlineStatusChanged.uid) {
        if (onlineStatusChanged.last_seen === 0) {
            online = false
        } else {
            online = true
        }
      } else if (data.online) {
        online = true
      }
      return {
        ...data,
        online: online
      }
    })
    this.setState({dataArr: result})
  }

  render() {
    const { enableAssign, selectedChat, dataArr, userSelected, userProfileArr, selectedUserId } = this.state
    if (!seeMessages(localStorage.getItem('tokener'))) {  //admins dont have permissions to view messages
      return (
        <WrapperContent header headerAsideTitle="Messages" badgeNum={5} logout={true}>
          <Layout>
            <Grid item container className='messages-admin-permission'>
              <Typography className='messages-admin-permission-text'>You don &apos t have permission to view messages</Typography>
            </Grid>
          </Layout>
        </WrapperContent>
      );
    }
    let userData = dataArr.length > 0 ? dataArr : userProfileArr;
    const ci = localStorage.getItem('contactsInfo');
		const ud = localStorage.getItem('usersData');
    const users = ud === null ? [] : JSON.parse(ud);
    let contacts = ci === null ? [] : JSON.parse(ci);
    contacts = contacts.filter(item => {
      const user_id = item.phone_data[0].user_id;
      const found = users.find(user => {
        return user.uid === user_id;
      });
      return found ? found.blocked : false;
    });

    return (
      <WrapperContent header headerAsideTitle="Messages" badgeNum={5} logout={true}>
        <Layout>
          <div className="message-flex">
          <Grid container item xs={8} direction="column">
            <Grid className="messages-wrapper messages-wrapper--all-mess" item container direction="column">
              <Typography className="messages-wrapper-title">All Messages</Typography>
                <Grid className="messages-box messages-box--users shadow">
                  {userData.length > 0 ? userData.map((item, index) => {
                    let findBlockedUser =  contacts.filter((data) => {
                      if (data.phone_data[0].user_id === item.id) {
                        return true
                      }
                    })
                    if (findBlockedUser.length > 0) {
                      return null
                    }
                    return (
                      <UserProfile
                        key={index}
                        data={item}
                        profileData={getProfileData(item.id, true)}
                        resetNewMessage={this.resetNewMessage}
                        openedChats={this.state.openedChats}
                        handleUserSelect={this.handleUserSelect}
                        userId={selectedUserId}
                      />
                    )
                  }) : null }
                </Grid>
              <Grid className="messages-wrapper-buttons" container item justify="flex-end">
                <Button
                  onClick={this.handleBlockClient}
                  disabled={!userSelected}
                  className="messages-wrapper-button"
                  variant="outlined"
                >
                  Block Client
                </Button>
                <Button onClick={this.handleUserAssign}
                  disabled={enableAssign ? false : true}
                  className="messages-wrapper-button" variant="contained">
                  Assign to me
                </Button>
              </Grid>
            </Grid>
            <Grid className="messages-wrapper messages-wrapper--chat" item container direction="column">
              <Typography className="messages-wrapper-title">Current Chat</Typography>
                { this.state.spinner === true ? <Chat spinner={this.state.spinner} /> : selectedChat !== '' && selectedChat !== null && selectedChat !== undefined? selectedChat : <Chat/>}
            </Grid>
          </Grid>

          <Grid className="messages-wrapper messages-wrapper--right" container item xs={4} direction="column">
            <Typography className="messages-wrapper-title messages-wrapper-title--preview">Preview</Typography>
            <Grid className="messages-box messages-box--gray shadow"></Grid>
            <Grid className="messages-wrapper-spacer"></Grid>
          </Grid>
          </div>
        </Layout>
      </WrapperContent>
    );
  }
}

Messages.propTypes = {
  userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.number,
}; 

export default connect(({common, auth, messages}) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
    openedChats: messages.openedChats,
    isValidToken: auth.isValidToken,
    lastMessage: messages.lastMessage,
    lastMessageStatus: messages.lastMessageStatus,
    contactsInfo: common.contactsInfo,
    oldMessagesDate: messages.oldMessagesDate,
    lastChatId: messages.lastChatId,
    userChangeProfile: common.userChangeProfile,
    onlineStatusChanged: messages.onlineStatusChanged,
    merchantInfo: common.merchantInfo
  }
}, (dispatch) => {
  const actions = {
    connectWeb,
    getAgentInfo,
    getContacts,
    getAgents,
    getOpenedChats,
    setFlag,
    assignChat,
    leaveChat,
    getMessagesBefore,
    setLastChatId,
    toggleContactBlock
  };
  return bindActionCreators(actions, dispatch);
})(Messages);