import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Divider,
	List,
	ListItem,
	Typography,
	Card,
	CardMedia
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon	}  from '@material-ui/icons/';
import './styles.css';
import {toggleContactBlock} from '../../store/common/actions';
import DefaultAvatar from './images/default_avatar.png';
import { getUserBlockStatus, blockUser, getProfileData } from '../../lib/utils';
import moment from 'moment';

class BlockedClients extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBlockedItem: null
		}
	}

	handleUnblock = (id) => {
		const userStatus = getUserBlockStatus(id);
    blockUser(id, !userStatus);
    const pd = getProfileData(id, true);

    if(pd !== undefined && pd.contact_id !== undefined){
      this.handleBlockUser({params: [pd.contact_id, !userStatus, false]});
    }
    // this.props.reloadList(); zove metodu this.forceUpdate
	}

  handleBlockUser = (params) => {
    this.props.toggleContactBlock(params.params);
		this.forceUpdate();
	};
	
	handleSelectBlockedItem = (indexOfItem) => {
		this.setState({ selectedBlockedItem: indexOfItem });
	}

	render() {
    const ci = localStorage.getItem('contactsInfo');
    const ud = localStorage.getItem('usersData');
    let contacts = ci === null ? [] : JSON.parse(ci);
    const users = ud === null ? [] : JSON.parse(ud);

    contacts = contacts.filter(item => {
      const user_id = item.phone_data[0].user_id;
      const found = users.find(user => {
        return user.uid === user_id;
      });

      return found ? found.blocked : false;
    });

		return (
			<Fragment>
				<Dialog
					open={this.props.openBlockedClients}
					className='settings-dialog'
				>
					<DialogTitle className='settings-manage-templates-dialog-text'>
						<ArrowBackIcon onClick={this.props.handleCloseBlockedClients} className="order-item-edit-dialog-back-btn" />
						Blocked Clients
						<Divider className='settings-dialog-title-divider'/>
					</DialogTitle>
					{contacts.length > 0 ?
						<Grid container item alignItems="center" className="settings-blocked-clients-content-header-wrapper">
							<Grid item container className='settings-blocked-clients-content-header-text'>
								<Typography className='settings-blocked-clients-content-header-text settings-blocked-clients-content-header-text--blank'></Typography>
								<Typography className='settings-blocked-clients-content-header-text'>Client`s name</Typography>
								<Typography className='settings-blocked-clients-content-header-text'>Client`s number</Typography>
							</Grid>
							<Grid item container className='settings-blocked-clients-content-header-text settings-blocked-clients-content-header-text--right'>
								<Typography className='settings-blocked-clients-content-header-text'>Agent & Reason for blocking</Typography>
								<Typography className='settings-blocked-clients-content-header-text'>Last Contact</Typography>
								<Typography className='settings-blocked-clients-content-header-text'>Action</Typography>
							</Grid>
						</Grid>
						:	<Typography className='settings-blocked-clients-content-text'>* There is no Blocked Clients</Typography>}
					<DialogContent className='settings-blocked-clients-content popup-content-xs'>
						<List className='settings-manage-templates-list-main settings-manage-templates-list-main--block-clients'>
							{contacts.length > 0 ?
								contacts.map((data, index) => {
									const userData = data.phone_data[0];
									const profile = userData.profile_data;
									const phone = userData.phone;
									const id = userData.user_id;
									const lastContact = data.lastMessageTime;
									const avatar = profile.avatar !== "null" && profile.avatar !== null && profile.avatar !== undefined && profile.avatar !== "" ? profile.avatar : DefaultAvatar;
									const selectedBlockedItemClass = id === this.state.selectedBlockedItem ? 'selected' : 'initial'
									return (
										<ListItem 
											className={`settings-blocked-clients-list-item settings-blocked-clients-list-item--${selectedBlockedItemClass}`} 
											disableGutters 
											divider 
											key={index}
											onClick={() => { this.handleSelectBlockedItem(id) }}
											>
											<Grid item container className='settings-blocked-clients-content-header-text-main'>
												<Card	className='settings-blocked-clients-card'>
													<CardMedia
														className='settings-blocked-clients-card-media'
														image={avatar}
													/>
												</Card>
												<Typography className='settings-blocked-clients-content-header-text'>{profile.display_name}</Typography>
												<Typography className='settings-blocked-clients-content-header-text'>{`+${phone}`}</Typography>
											</Grid>
											<Grid item container className='settings-blocked-clients-content-header-text-main settings-blocked-clients-content-header-text-main--right'>
												<Typography className='settings-blocked-clients-content-header-text'>Agent 07 / Profanity Language</Typography>
												<Typography className='settings-blocked-clients-content-header-text'>{moment(lastContact).format("YY-MM-DD hh:mm")}</Typography>
												<Typography 
													onClick={this.handleUnblock.bind(this,id)}
													className='settings-blocked-clients-content-header-text settings-blocked-clients-content-header-text--cursor'
												>
													UNBLOCK
												</Typography>
											</Grid>
										</ListItem>
									)
								})
								: null
							}
						</List>
						<DialogActions>
							
						</DialogActions>
					</DialogContent>
				</Dialog>
			</Fragment>
		)
	}
}

BlockedClients.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ common, auth }) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
  }
}, (dispatch) => {
  const actions = {
		toggleContactBlock
	};
  return bindActionCreators(actions, dispatch);
})(BlockedClients);