import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import App from './pages/App';
// import configureStore, { history } from './store/configureStore';
import store, { history } from './store/index';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import './styles/styles.css'
require('./favicon.ico'); // Tell webpack to load favicon.ico

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#cf5ada'
		},
		secondary: {
			main: '#c8c8c8'
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiFormControl: {
			root: {
				margin: '0 0 20px 0'
			}
		},
		MuiInputBase: {
			input: {
				WebkitAppearance: 'none',
				'&:hover': {
					WebkitAppearance: 'none'
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				fontSize: '20px',
				backgroundColor: 'var(--input-bgd-color)',
				color: 'var(--title-color)',
				'&:hover $notchedOutline': {
					borderColor: 'var(--primary-color)',
					WebkitAppearance: 'none'
				}
			},
			notchedOutline: {
				borderColor: 'var(--primary-color)',
				borderWidth: '2px'
			},
			inputMarginDense: {
				paddingTop: '6px',
				paddingBottom: '6px'
			}
		},
		MuiButton: {
			root: {
				borderRadius: '8px',
				textTransform: 'none',
				fontSize: 'var(--subtitle)',
				lineHeight: '20px',
				padding: '14px 16px',
				'&$disabled': {
					color: '#fff',
					backgroundColor: 'rgba(var(--primary-color-rgb), 0.6)'
				}
			},
			label: {
				lineHeight: 'calc(var(--subtitle) / 1)'
			},
			sizeSmall: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				fontSize: '16px',
				lineHeight: '16px'
			},
			contained: {
				'&$disabled': {
					color: '#fff',
					backgroundColor: 'rgba(var(--primary-color-rgb), 0.6)'
				}
			},
			containedSizeSmall: {
				padding: '6px 20px'
			}
		},
		MuiSelect: {
			root: {
				padding: '2px 12px',
				fontSize: '20px'
			},
			select: {
				color: 'var(--primary-color)'
			}
		},
		MuiPopover: {
			paper: {
				outline: '2px solid rgba(var(--primary-color-rgb), .40)'
			}
		},
		MuiMenu: {
			paper: {
				// maxHeight: 'calc(100% - 650px) !important',
				height: '250px',
				maxWidth: '350px !important',
				top: '525px !important',
				left: '50% !important',
				transform: 'translate(-50%, 0) !important'
			}
		}
	}
})

render(
	<Provider store={store} history={history}>
		<MuiThemeProvider theme={theme}>
			<App />
		</MuiThemeProvider>
	</Provider>,
	document.getElementById('app')
)
