import React, { Component } from 'react';
import text from './index.md';
import logo from './images/logo.svg';
import data from './data.json';
import Layout from '../../components/Layout'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MenuItem, Select, TextField, Button, FormControl } from '@material-ui/core';
import { registerWeb } from '../../store/auth/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { history } from '../../store/index';
import ErrorModal from '../../components/ErrorModal';
import WrapperContent from '../../components/WrapperContent'
import './styles.css';

//default selection for country dropdown
const default_dial_code = '+63';

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null,
			items: [],
			selectedCountry: {},
			number: '',
			prefix: '',
			numberError: '',
		};
	}

	componentDidUpdate (prevProps) {
		if (prevProps.registerError !== this.props.registerError) this.setState({numberError: this.props.registerError});
		if (prevProps.codeConfirmationStarted !== this.props.codeConfirmationStarted) {
			if (this.props.codeConfirmationStarted === true) {
				history.push('/confirmationCode')
			}
		}
	}

	componentDidMount() {
		document.title = text.title;
		localStorage.clear();
		const items = [];
		let country = '';
		let val = '';
		for (let i = 0; i < data.length; i++) {
			items.push(
				<MenuItem className="regiter-country" value={i} key={i}>{data[i].name} {data[i].dial_code}</MenuItem>
			);
			if (data[i].dial_code === default_dial_code) {
				country = data[i];
				val = i;
			}
		}
		this.setState({ items });
		this.setState({ selectedCountry: country });
		this.setState({ value: val });
		this.setState({ prefix: country.dial_code });
	}

	handleChange = (event) => {
		this.setState({ value: event.target.value });
		this.setState({ selectedCountry: data[event.target.value] });
		this.setState({ prefix: data[event.target.value].dial_code });
	};

	handleNumberChange = (event) => {
		if (event.target.value.charAt(0) === '0') {
			this.setState({ numberError: 'Please skip 0 value at the beginning' });
			return;
		} else {
			this.setState({ numberError: '' });
		}

		this.setState({ number: event.target.value });
	};

	handleSubmit = () => {
		if (!this.validate()) {
			return;
		} else {
			const phoneNumber = this.state.prefix + this.state.number;
			this.props.registerWeb([phoneNumber, this.props.userType, this.props.appVersion]);
		}
	};
	
	handleAgentLogin = () => {
		history.push('/loginAgent');
	};

	validate = () => {
		const state = this.state;
		if (state.number === '' || this.state.number.charAt(0) === '0') {
			this.setState({ numberError: 'Please enter valid phone number' });
			return false;
		}
		return true;
	}

	handleGeneralAgentLogin = () => {
		history.push('/loginGeneralAgent');
	};

	render() {
		return (
			<WrapperContent header title="Register">
				<Layout className="register-layout">
					<div className="centered">
						<img className="register-logo" src={ logo } alt="logo"/>
					</div>
					<div className="centered">
						{this.props.isValidToken === false ? (<div className="register-alert padded-top"><br />Your session expired. Please register again.</div>) : ''}
						<div className="color-primary text-subheading padded-v">Enter Your Phone Number</div>
					</div>
					<div className="centered">
						<FormControl>
							<Select
								className='register-select'
								value={this.state.value ? this.state.value : ''}
								onChange={this.handleChange}
								IconComponent={() => (<ExpandMoreIcon className="register-select-icon" fontSize="large"/>)}
								variant="outlined">
								{this.state.items}
							</Select>
						</FormControl>
					</div>
					<div className="centered">
						<TextField
							required={true}
							onChange={this.handleNumberChange}
							id="filled-basic"
							label="Your Number"
							defaultValue=""
							variant="outlined"
							size="small"
							/>
					</div>
					<div className="centered">
						<FormControl>
							<Button className='register-buttons' onClick={this.handleSubmit} variant="contained" color="primary">
								Send Confirmatione Code
							</Button>
						</FormControl>
					</div>
					<div className="centered">
						<FormControl>
							<Button className='register-buttons' onClick={this.handleAgentLogin} variant="contained" color="primary">
								Agent Login
							</Button>
						</FormControl>
					</div>
					<ErrorModal/>
				</Layout>
			</WrapperContent>
		);
	}
}

Register.propTypes = {
	codeConfirmationStarted: PropTypes.bool.isRequired,
	userType: PropTypes.number.isRequired,
	appVersion: PropTypes.number.isRequired,
	registerWeb: PropTypes.func.isRequired,
	isValidToken: PropTypes.bool.isRequired,
	isAuthorized: PropTypes.bool.isRequired
}; 

export default connect((store) => {
	const { auth } = store
	return {
		codeConfirmationStarted: auth.codeConfirmationStarted,
		userType: auth.userType,
		appVersion: auth.appVersion,
		codeConfirmed: auth.codeConfirmed,
		registerError: auth.registerError,
		isValidToken: auth.isValidToken,
		isAuthorized: auth.isAuthorized
	}
}, (dispatch) => {
	const actions = { registerWeb };
	return bindActionCreators(actions, dispatch);
})(Register);
