import React from 'react';
import PropTypes from 'prop-types';
import text from './index.md';
import Layout from '../../components/Layout';
import { history } from '../../store/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { verifyWeb, registerWeb } from '../../store/auth/actions';
import { Grid, TextField, Typography } from '@material-ui/core';
import WrapperContent from '../../components/WrapperContent';
import { FormControlCss, FirstButton, SecondButton } from './styles.js'
import './styles.css'

const codeLenght = 6;

class ConfirmationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      numberError: '',
    };
  }

  componentDidMount() {
    document.title = text.title;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verifyError !== this.props.verifyError) this.setState({numberError: this.props.verifyError});
    if (prevProps.isRegistered !== this.props.isRegistered) {
      if (this.props.isRegistered === true) {
        history.push('/load')
      }
    }
  }

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    } else {
			localStorage.setItem('userType', 2)
      this.props.verifyWeb([this.props.uid, this.state.number]);
    }
  };

  handleResend = () => {
    this.setState({numberError: ''});
    this.props.registerWeb([this.props.phoneNumber, this.props.userType, this.props.appVersion]);
  };

  validate = () => {
    const state = this.state;
    if (state.number === '') {
      this.setState({numberError: 'Please enter code'});
      return false;
    }
    if (state.number.length < codeLenght || state.number.length > codeLenght) {
      this.setState({ numberError: 'Code must have ' + codeLenght + ' digits' });
      return false;
    }
    if (this.state.numberError && this.props.numberError !== '') {
      return false;
    }
    return true;
  };

  handleNumberInput = (event) => {
    if (isNaN(parseInt(event.key))) {
      event.preventDefault();
      return;
    }
    this.setState({ numberError: '' });
    this.setState({ number: this.state.number + event.key });
  };

  handleNumberChange = (event) => {
    this.setState({ number: event.target.value });
  };

  render() {
    return (
      <WrapperContent header title="Confirmation Code">
        <Layout className="code-layout">
          <Grid container alignContent="center">
            <Grid container>
              <Grid container item xs={12} direction="column" alignItems="center" className="code-wrapper">
                <Typography className="code-title">Enter the {codeLenght}-digit Code We Sent You to:</Typography>
                <Typography className="code-number">{this.state.number}</Typography>
              </Grid>

              <Grid className="code-wrapper code-wrapper--textfield" container item justify="center">
                <FormControlCss variant="filled">
                  <TextField
                    className="code-input"
                    required={true}
                    onChange={this.handleNumberChange}
                    id="filled-basic"
                    label="Code"
                    variant="filled"
                    defaultValue=""
                    size="small"
                  />
                </FormControlCss>
              </Grid>
              <Grid className="code-wrapper code-wrapper--buttons" container item direction="column" alignItems="center">
                <FirstButton className="code-wrapper-button code-wrapper-button--first" onClick={this.handleSubmit} variant="contained" size="small" color="primary">
                  Continue
                </FirstButton>
                <SecondButton  onClick={this.handleResend} variant="contained" size="small" color="primary">
                  Resend Code
                </SecondButton>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </WrapperContent>
    );
  }
}

ConfirmationCode.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number,
  verifyWeb: PropTypes.func,
  registerWeb: PropTypes.func,
  phoneNumber: PropTypes.string,
  appVersion: PropTypes.number,
  numberError: PropTypes.string
};

export default connect(({auth}) => {
  return {
    userType: auth.userType,
    appVersion: auth.appVersion,
    uid: auth.uid,
    verifyError: auth.verifyError,
    isRegistered: auth.isRegistered,
    phoneNumber: auth.phoneNumber
  }
}, (dispatch) => {
  const actions = {verifyWeb, registerWeb};
  return bindActionCreators(actions, dispatch);
})(ConfirmationCode);