export const USECLIPBOARD = 'USECLIPBOARD'
export const HIDE_ERROR = 'HIDE_ERROR'
export const GETSTORECATEGORIES = 'GETSTORECATEGORIES'
export const GETSTORECATEGORIES_RESULT = 'GETSTORECATEGORIES_RESULT'
export const GETSTORECATEGORIES_ERROR = 'GETSTORECATEGORIES_ERROR'
export const GETMERCHANTCARD2 = 'GETMERCHANTCARD2'
export const GETMERCHANTCARD2_RESULT = 'GETMERCHANTCARD2_RESULT'
export const GETMERCHANTCARD2_ERROR = 'GETMERCHANTCARD2_ERROR'
export const GETMERCHANTMAXDISTANCE = 'GETMERCHANTMAXDISTANCE'
export const GETMERCHANTMAXDISTANCE_RESULT = 'GETMERCHANTMAXDISTANCE_RESULT'
export const GETMERCHANTMAXDISTANCE_ERROR = 'GETMERCHANTMAXDISTANCE_ERROR'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const BEGINUPLOAD = 'BEGINUPLOAD'
export const BEGINUPLOAD_RESULT = 'BEGINUPLOAD_RESULT'
export const BEGINUPLOAD_ERROR = 'BEGINUPLOAD_ERROR'
export const ADDMERCHANTMAXDISTANCE = 'ADDMERCHANTMAXDISTANCE'
export const ADDMERCHANTMAXDISTANCE_RESULT = 'ADDMERCHANTMAXDISTANCE_RESULT'
export const ADDMERCHANTMAXDISTANCE_ERROR = 'ADDMERCHANTMAXDISTANCE_ERROR'
export const ADDMERCHANTCARD = 'ADDMERCHANTCARD'
export const ADDMERCHANTCARD_RESULT = 'ADDMERCHANTCARD_RESULT'
export const ADDMERCHANTCARD_ERROR = 'ADDMERCHANTCARD_ERROR'
export const GETPAYMENTS = 'GETPAYMENTS'
export const GETPAYMENTS_RESULT = 'GETPAYMENTS_RESULT'
export const GETPAYMENTS_ERROR = 'GETPAYMENTS_ERROR'
export const VERIFYEMAIL = 'VERIFYEMAIL'
export const VERIFYEMAIL_RESULT = 'VERIFYEMAIL_RESULT'
export const VERIFYEMAIL_ERROR = 'VERIFYEMAIL_ERROR'
export const GETEMAILVERIFICATIONSTATUS = 'GETEMAILVERIFICATIONSTATUS'
export const GETEMAILVERIFICATIONSTATUS_RESULT = 'GETEMAILVERIFICATIONSTATUS_RESULT'
export const GETEMAILVERIFICATIONSTATUS_ERROR = 'GETEMAILVERIFICATIONSTATUS_ERROR'
export const GETCONTACTSWEB = 'GETCONTACTSWEB'
export const GETCONTACTSWEB_RESULT = 'GETCONTACTSWEB_RESULT'
export const GETCONTACTSWEB_ERROR = 'GETCONTACTSWEB_ERROR'
export const GETAGENTS = 'GETAGENTS'
export const GETAGENTS_RESULT = 'GETAGENTS_RESULT'
export const GETAGENTS_ERROR = 'GETAGENTS_ERROR'
export const GETGENERALAGENTS = 'GETGENERALAGENTS'
export const GETGENERALAGENTS_RESULT = 'GETGENERALAGENTS_RESULT'
export const GETGENERALAGENTS_ERROR = 'GETGENERALAGENTS_ERROR'
export const GETTELLERS = 'GETTELLERS'
export const GETTELLERS_RESULT = 'GETTELLERS_RESULT'
export const GETTELLERS_ERROR = 'GETTELLERS_ERROR'
export const UPDATEEMAIL = 'UPDATEEMAIL'
export const UPDATEEMAIL_RESULT = 'UPDATEEMAIL_RESULT'
export const UPDATEEMAIL_ERROR = 'UPDATEEMAIL_ERROR'
export const GETAUTOREPLYMESSAGE = 'GETAUTOREPLYMESSAGE'
export const GETAUTOREPLYMESSAGE_RESULT = 'GETAUTOREPLYMESSAGE_RESULT'
export const GETAUTOREPLYMESSAGE_ERROR = 'GETAUTOREPLYMESSAGE_ERROR'
export const ADDAUTOREPLYMESSAGE = 'ADDAUTOREPLYMESSAGE'
export const ADDAUTOREPLYMESSAGE_RESULT = 'ADDAUTOREPLYMESSAGE_RESULT'
export const ADDAUTOREPLYMESSAGE_ERROR = 'ADDAUTOREPLYMESSAGE_ERROR'
export const LISTMERCHANTCANNEDIMAGES = 'LISTMERCHANTCANNEDIMAGES'
export const LISTMERCHANTCANNEDIMAGES_RESULT = 'LISTMERCHANTCANNEDIMAGES_RESULT'
export const LISTMERCHANTCANNEDIMAGES_ERROR = 'LISTMERCHANTCANNEDIMAGES_ERROR'
export const ADDMERCHANTCANNEDIMAGE = 'ADDMERCHANTCANNEDIMAGE'
export const ADDMERCHANTCANNEDIMAGE_RESULT = 'ADDMERCHANTCANNEDIMAGE_RESULT'
export const ADDMERCHANTCANNEDIMAGE_ERROR = 'ADDMERCHANTCANNEDIMAGE_ERROR'
export const EDITMERCHANTCANNEDIMAGECAPTION = 'EDITMERCHANTCANNEDIMAGECAPTION'
export const EDITMERCHANTCANNEDIMAGECAPTION_RESULT = 'EDITMERCHANTCANNEDIMAGECAPTION_RESULT'
export const EDITMERCHANTCANNEDIMAGECAPTION_ERROR = 'EDITMERCHANTCANNEDIMAGECAPTION_ERROR'
export const DELETEMERCHANTCANNEDIMAGE = 'DELETEMERCHANTCANNEDIMAGE'
export const DELETEMERCHANTCANNEDIMAGE_RESULT = 'DELETEMERCHANTCANNEDIMAGE_RESULT'
export const DELETEMERCHANTCANNEDIMAGE_ERROR = 'DELETEMERCHANTCANNEDIMAGE_ERROR'
export const LISTMERCHANTCANNEDANSWERS = 'LISTMERCHANTCANNEDANSWERS'
export const LISTMERCHANTCANNEDANSWERS_RESULT = 'LISTMERCHANTCANNEDANSWERS_RESULT'
export const LISTMERCHANTCANNEDANSWERS_ERROR = 'LISTMERCHANTCANNEDANSWERS_ERROR'
export const ADDMERCHANTCANNEDANSWER = 'ADDMERCHANTCANNEDANSWER'
export const ADDMERCHANTCANNEDANSWER_RESULT = 'ADDMERCHANTCANNEDANSWER_RESULT'
export const ADDMERCHANTCANNEDANSWER_ERROR = 'ADDMERCHANTCANNEDANSWER_ERROR'
export const DELETEMERCHANTCANNEDANSWER = 'DELETEMERCHANTCANNEDANSWER'
export const DELETEMERCHANTCANNEDANSWER_RESULT = 'DELETEMERCHANTCANNEDANSWER_RESULT'
export const DELETEMERCHANTCANNEDANSWER_ERROR = 'DELETEMERCHANTCANNEDANSWER_ERROR'
export const EDITMERCHANTCANNEDANSWER = 'EDITMERCHANTCANNEDANSWER'
export const EDITMERCHANTCANNEDANSWER_RESULT = 'EDITMERCHANTCANNEDANSWER_RESULT'
export const EDITMERCHANTCANNEDANSWER_ERROR = 'EDITMERCHANTCANNEDANSWER_ERROR'
export const TOGGLECONTACTBLOCK = 'TOGGLECONTACTBLOCK'
export const TOGGLECONTACTBLOCK_RESULT = 'TOGGLECONTACTBLOCK_RESULT'
export const TOGGLECONTACTBLOCK_ERROR = 'TOGGLECONTACTBLOCK_ERROR'
export const REGISTERAGENT = 'REGISTERAGENT'
export const REGISTERAGENT_RESULT = 'REGISTERAGENT_RESULT'
export const REGISTERAGENT_ERROR = 'REGISTERAGENT_ERROR'
export const EDITQRCODE = 'EDITQRCODE'
export const EDITQRCODE_RESULT = 'EDITQRCODE_RESULT'
export const EDITQRCODE_ERROR = 'EDITQRCODE_ERROR'
export const EDITAGENT = 'EDITAGENT'
export const EDITAGENT_RESULT = 'EDITAGENT_RESULT'
export const EDITAGENT_ERROR = 'EDITAGENT_ERROR'
export const GETAGENTINFO = 'GETAGENTINFO'
export const GETAGENTINFO_RESULT = 'GETAGENTINFO_RESULT'
export const GETAGENTINFO_ERROR = 'GETAGENTINFO_ERROR'
export const GETGENERALAGENTINFO = 'GETGENERALAGENTINFO'
export const GETGENERALAGENTINFO_RESULT = 'GETGENERALAGENTINFO_RESULT'
export const GETGENERALAGENTINFO_ERROR = 'GETGENERALAGENTINFO_ERROR'
export const USERCHANGEPROFILE = 'USERCHANGEPROFILE'
export const USERCHANGEPROFILE_RESULT = 'USERCHANGEPROFILE_RESULT'
export const USERCHANGEPROFILE_ERROR = 'USERCHANGEPROFILE_ERROR'
export const SETFLAG = 'SETFLAG'
export const REFRESHNAVIGATION = 'REFRESHNAVIGATION'
