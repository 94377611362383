import React from 'react';
import './styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';

class SimpleSpinner extends React.Component {

  render() {
    return (
      <div className='mainContent'>
        <CircularProgress size={80} thickness={5} color="primary"/>
      </div>
    );
  }
}

export default SimpleSpinner;
