import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
// import ErrorModal from '../../components/ErrorModal';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getPromotions,
  recreatePromotion,
  getSubPromotions,
  deletePromotion,
  deleteSubPromotions,
  setSubPromotion
  } from '../../store/promotions/actions';
import { connectWeb, setActivePage } from '../../store/auth/actions';
import {   setFlag } from '../../store/common/actions';
import Layout from '../../components/Layout';
import Promotions from '../../components/Promotions';
import OrderItems from '../../components/OrderItems';
import PreviewPromotion from '../../components/PreviewPromotions';
import PreviewOrderItems from '../../components/PreviewOrderItems';
import WrapperContent from '../../components/WrapperContent';
import PromotionEdit from '../../components/promotionEdit';
import OrderItemEdit from '../../components/OrderItemEdit';

class Promos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      allPromotions: [],
      selectedPromotionId: null,
      selectedOrderItem: null,
      promoGridSelected: false,
      orderItemGridSelected: false,
      orderItemButton: true,
      openPreview: false,
      openSubPreview: false,
      editPromotion: false,
      editOrderItem: false,
      promotionData: [],
      orderItemData: {},
      promoStatus: '',
      editType: '',
      openOrderItemPreview: false,
      editPromoTitle: '',
      editPromoDescription: '',
      editPromoLogo: '',
      editPromoImages: '',
      editPromoDate: new Date(),
      editOrderItemTitle: '',
      editOrderItemDescription: '',
      editOrderItemCurrency: '',
      editOrderItemPrice: '',
      editOrderItemImages: [],
      btnType: false,
      isExistBackdrop: false,
    };
  }
  componentDidMount() {
    this.props.getPromotions([null, 0]); //get all promotions
    this.props.getPromotions([null, 2]); //get active promotions
    this.props.getSubPromotions([null]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allPromotions !== this.props.allPromotions) {
      this.setState({ allPromotions: this.props.allPromotions })
    }
  }

  openPromotion = () => {
    this.setState({editPromotion: true})
  }

  copyPromotion = () => {
    const { selectedPromotionId } = this.state;
    this.props.recreatePromotion([selectedPromotionId]);
    this.props.getPromotions([null, 0]);
  }

  deletePromotion = () => {
    const { selectedPromotionId } = this.state;
    this.props.deletePromotion([[selectedPromotionId]]);
    this.props.getPromotions([null, 0]);
  }

  createNewPromotion = (btnType) => {
    this.setState({editPromotion: true, editType: btnType})
  }

  handleEditOrderItemShow = (btnType, value) => {
    if (btnType === 'open' || btnType === 'create') {
      if (value) {
        let findOrderItem = this.props.subPromotions.find((item => item.id === value))
        this.setState({editOrderItem: true, promoGridSelected: false, btnType: true, orderItemData: findOrderItem})
      } else {
        this.setState({editOrderItem: true, promoGridSelected: false, btnType: true})
      }
    }
  }

  handleEditPromotionClose = () => {   // zatvaranje komponente edit promocije
    this.setState({
      editPromotion: false,
      editType: '',
      promoGridSelected: false,
      openPreview: false,
      selectedPromotionId: null,
      promotionData: [],
      promoStatus: '',
    })
  }

  handleEditOrderItemClose = () => {
    this.setState({
      editOrderItem: false,
      editType: '',
      orderItemGridSelected: false,
      selectedOrderItem: null,
      btnType: false,
      openOrderItemPreview: false,
      orderItemData: {},
      openPreview: false,
      promoGridSelected: false,
      selectedPromotionId: null,
      promotionData: [],
      promoStatus: '',
    
    })
  }

  gridSelect = (type) => {
    if (type === 'Promotions') {
      this.setState({
        promoGridSelected: true,
        orderItemGridSelected: false,
        openOrderItemPreview: false,
        selectedOrderItem: null,
        editOrderItem: false,
        btnType: false,
        orderItemData: {},
      })
		} else {
			this.setState({
        editPromotion: false,
        promoStatus: '',
        orderItemGridSelected: true,
        promoGridSelected: false,
        openPreview: false,
        selectedPromotionId: null,
      })
		}
  }

  handlePromotionSelect = (data) => {
    let findPromo = this.props.allPromotions.find((item => item.id === data))
    let status = findPromo.expired ? findPromo.state === 0 ? 'Draft' : 'Expired' : findPromo.state === 0 ? 'Draft' : 'Active'
    this.setState({ openPreview: true, selectedPromotionId: data, promotionData: findPromo, promoStatus: status }) //openPreview promocija selektovana, selectedPromotion je id promocije
    this.props.getSubPromotions([data])
  }

  handleOrderItemSelect = (data) => {
    let findOrderItem = this.props.subPromotions.find((item => item.id === data))
		this.setState({ openOrderItemPreview: true, selectedOrderItem: data, orderItemData: findOrderItem, btnType: false })
  }

  renderOrderItems = () => {
    this.props.getSubPromotions([null]);
  }

  showEditPromotionTitle = (value) => {
    this.setState({editPromoTitle: value})
  }

  showEditPromotionDescription = (value) => {
    this.setState({editPromoDescription: value})
  }

  showEditPromotionLogo = (value) => {
    this.setState({editPromoLogo: value})
  }

  showEditPromotionImages = (value) => {
    this.setState({editPromoImages: value})
  }

  showEditPromotionDate = (value) => {
    this.setState({editPromoDate: value})
  }

  handleOpenPreviewPromotionBackdrop = (value) => {
    this.setState({ isExistBackdrop: value });
  }

  handleEditOrderItemTitle = (value) => {
    this.setState({editOrderItemTitle: value})
  }

  handleEditOrderItemDescription = (value) => {
    this.setState({editOrderItemDescription: value})
  }

  handleEditOrderItemCurrency = (name, checked) => {
    this.setState({editOrderItemCurrency: name})
  }
  
  handleEditOrderItemPrice = (value) => {
    this.setState({editOrderItemPrice: value})
  }

  handleEditOrderItemImages = (url, id) => {
    let newObj = {};
    if (id === 0) {
      this.setState({editOrderItemLogo: url})
    } else if (id === 1) {
        newObj['img_1'] = url
        this.setState({editOrderItemImages: {...this.state.editOrderItemImages, ...newObj}})
    } else if (id === 3) {
        newObj['img_2'] = url
        this.setState({editOrderItemImages: {...this.state.editOrderItemImages, ...newObj}})
    } else {
        newObj['img_3'] = url
        this.setState({editOrderItemImages: {...this.state.editOrderItemImages, ...newObj}})
    }
  }

  deleteOrderItem = () => {
    const { selectedOrderItem } = this.state;
    let orderItemData = this.props.subPromotions.find(item => item.id === selectedOrderItem)
    if (this.props.activePromotions.length > 0) {
      if (this.props.activePromotions[0].id !== orderItemData.promotion_id) {
        this.props.deleteSubPromotions([[selectedOrderItem]])
        this.props.getSubPromotions([null])
      }
    }
  }

  copyOrderItem = () => {
    const { selectedOrderItem } = this.state;
    let orderItemData = this.props.subPromotions.find(item => item.id === selectedOrderItem)
    let data = {
      title: orderItemData.title,
      description: orderItemData.description,
      currency: orderItemData.currency,
      price: orderItemData.price,
      logo_url: orderItemData.logo_url,
      images: orderItemData.images,
    }
    this.props.setSubPromotion([data, null, null]);
    this.props.getSubPromotions([null])
  }

  render() {
    const {
      isExistBackdrop,
      promoGridSelected,
      orderItemGridSelected,
      openPreview,
      editPromotion,
      promoStatus,
      selectedPromotionId,
      openOrderItemPreview,
      btnType,
      selectedOrderItem,
      editOrderItem,
      editType,
      promotionData,
      orderItemData,
      editPromoTitle,
      editPromoDescription,
      editPromoLogo,
      editPromoImages,
      editPromoDate,
      editOrderItemTitle,
      editOrderItemDescription,
      editOrderItemCurrency,
      editOrderItemImages,
      editOrderItemPrice,
      editOrderItemLogo
    } = this.state
    const isVisibleBackdrop = isExistBackdrop ? 'visible' : 'hidden'
    
    return (
      <WrapperContent header headerAsideTitle="Promos & Order Items" badgeNum={5} logout={true}>
        <Layout className="promos-layout">
          <Grid onClick={() => { this.handleOpenPreviewPromotionBackdrop(false) }} className={`promos-backdrop promos-backdrop--${isVisibleBackdrop}`}></Grid>
          <div className="flex-cols" 
            // style={{height: '100%'}}
            >
            <Grid className="promos-wrapper" item container xs={8}>
              <Grid className="promos-item space-between" item container xs={12} direction='row'>
                <Promotions
                  type='Promotions'
                  flag = {promoGridSelected}
                  flagThird={orderItemGridSelected}
                  gridSelect={this.gridSelect}
                  handlePromotionSelect={this.handlePromotionSelect}
                  openPreview={openPreview}
                  editPromoFlag={editPromotion}
                  status={promoStatus}
                  selectedPromotionId={selectedPromotionId}
                  openPromotion={this.openPromotion}
                  copyPromotion={this.copyPromotion}
                  deletePromotion={this.deletePromotion}
                  createNewPromotion={this.createNewPromotion}
                />
                <OrderItems
                  type='OrderItem'
                  flag={orderItemGridSelected}
                  flagSecond={openOrderItemPreview}
                  flagThird={promoGridSelected}
                  flagFour={btnType}
                  gridSelect={this.gridSelect}
                  handleOrderItemSelect={this.handleOrderItemSelect}
                  selectedOrderItem={selectedOrderItem}
                  editOrderItemFlag={editOrderItem}
                  editOrderItem={this.handleEditOrderItemShow}
                  deleteOrderItem={this.deleteOrderItem}
                  copyOrderItem={this.copyOrderItem}
                />
              </Grid>
              {editPromotion ?
                <PromotionEdit
                  handleButtonSelect={editType}
                  data = {promotionData}
                  closeEditPromotion={this.handleEditPromotionClose}
                  title={this.showEditPromotionTitle}
                  description={this.showEditPromotionDescription}
                  images={this.showEditPromotionImages}
                  date={this.showEditPromotionDate}
                />
              : editOrderItem ?
                <OrderItemEdit
                  closeEditOrderItem={this.handleEditOrderItemClose}
                  orderItemEditData={orderItemData}
                  title={this.handleEditOrderItemTitle}
                  description={this.handleEditOrderItemDescription}
                  currency={this.handleEditOrderItemCurrency}
                  price={this.handleEditOrderItemPrice}
                  images={this.handleEditOrderItemImages}
                />
                : null}
            </Grid>

            <Grid className="promos-wrapper--preview" item container direction='column' xs={4}>
              {/* <Grid container direction=''> */}
                <Grid className="promos-box--title" container item justify="center">
                  <Typography >Preview</Typography>
                </Grid>
                {openPreview ? 
                  <PreviewPromotion
                    selectedPromo={selectedPromotionId}
                    flag={this.renderOrderItems}
                    title={editPromoTitle}
                    description={editPromoDescription}
                    logo={editPromoLogo}
                    images={editPromoImages}
                    date={editPromoDate}
                    editPromotions={editPromotion}
                    editOrderItem={this.handleEditOrderItemShow}
                    handleOpenPreviewPromotionBackdrop={this.handleOpenPreviewPromotionBackdrop}
                    isExistBackdrop={isExistBackdrop}
                    isEditPromotion={editPromotion}
                  /> 
                  : 
                  openOrderItemPreview ?
                  <PreviewOrderItems
                    selectedOrderItemData={orderItemData}
                    editOrderItem={editOrderItem}
                    title={editOrderItemTitle}
                    description={editOrderItemDescription}
                    currency={editOrderItemCurrency}
                    price={editOrderItemPrice}
                    images={editOrderItemImages}
                    logo={editOrderItemLogo}
                  /> : 
                  <Grid className="promos-box--bordered border-radius shadow" container item>
                  </Grid>
                }
              {/* </Grid> */}
            </Grid>
          </div>
          {/* <ErrorModal /> */}
        </Layout>
      </WrapperContent>
    );
  }
}

Promos.propTypes = {
  getPromotions: PropTypes.func.isRequired,
  getSubPromotions: PropTypes.func.isRequired,
  recreatePromotion: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
  //uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  allPromotions: PropTypes.array,
  subPromotions: PropTypes.array
};

export default connect(({ common, promotions, auth }) => {
  return {
    token: auth.token,
    uid: auth.uid,
    promotions: promotions.promotions,
    isValidToken: auth.isValidToken,
    userType: auth.userType,
    forDelete: promotions.forDelete,
    clipboard: promotions.clipboard,
    activePromotions: promotions.activePromotions,
    allPromotions: promotions.allPromotions,
    subPromotions: promotions.subPromotions,
    expiredPromotions: promotions.expiredPromotions,
    useClipboard: common.useClipboard,
    editPromotion: promotions.editPromotion,
  }
}, (dispatch) => {
  const actions = {
    getPromotions,
    recreatePromotion,
    getSubPromotions,
    connectWeb,
    setFlag,
    setActivePage,
    deletePromotion,
    deleteSubPromotions,
    setSubPromotion
  };
  return bindActionCreators(actions, dispatch);
})(Promos);
