import { withStyles, Button, FormControl } from '@material-ui/core';

export const FormControlCss = withStyles(theme => ({
  root: {
    width: '360px',
    padding: 0,
    // height: '40px'
  },
}))(FormControl);

export const FirstButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#cf5ada'),
    backgroundColor: '#cf5ada',
    '&:hover': {
      backgroundColor: '#cf5ada',
    },
    fontFamily: [
      // 'AvenirLTStd-Roman'
    ],
    fontSize: '25px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    borderRadius: '18px',
    width: '350px',
    height: '60px',
    position: 'relative',
    // ['@media (max-width: 375px)']: {
    //   width: '330px',
    //   marginBottom: '5px'
    // },
    textTransform: 'none',

  },
}))(Button);

export const SecondButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#cf5ada'),
    backgroundColor: '#cf5ada',
    '&:hover': {
      backgroundColor: '#cf5ada',
    },
    fontFamily: [
      // 'AvenirLTStd-Roman'
    ],
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    borderRadius: '12px',
    width: '250px',
    height: '40px',
    // position: 'relative',
    // ['@media (max-width: 375px)']: {
    //   width: '330px',
    //   marginBottom: '5px'
    // },
    textTransform: 'none',

  },
}))(Button);