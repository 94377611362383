import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteSubPromotions, getSubPromotions } from '../../store/promotions/actions';
import './styles.css'

class PreviewOrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
			orderItemData: {},
			id: '',
			logo: '',
			title: '',
			price: '',
			currency: '',
			description: '',
			images: [],
			flag: false
    };
  }

	componentDidMount() {
		const { selectedOrderItemData } = this.props;
		this.setState({
			id: selectedOrderItemData.id,
			title: selectedOrderItemData.title,
			price: selectedOrderItemData.price,
			description: selectedOrderItemData.description,
			logo: selectedOrderItemData.logo_url,
			images: selectedOrderItemData.images,
			currency: selectedOrderItemData.currency
		})
	}

	componentDidUpdate(prevProps) {
		const { selectedOrderItemData } = this.props;
		if (!this.props.editOrderItem) {
			if (selectedOrderItemData.id !== this.state.id) {
				this.setState({
					id: selectedOrderItemData.id,
					title: selectedOrderItemData.title,
					price: selectedOrderItemData.price,
					description: selectedOrderItemData.description,
					logo: selectedOrderItemData.logo_url,
					images: selectedOrderItemData.images,
					currency: selectedOrderItemData.currency,
				})
			}

		} else {
			if (prevProps.title !== this.props.title) {
				this.setState({ title: this.props.title })
			}
			if (prevProps.description !== this.props.description) {
				this.setState({ description: this.props.description })
			}
			if (prevProps.price !== this.props.price) {
				this.setState({ price: this.props.price })
			}
			if (prevProps.currency !== this.props.currency) {
				this.setState({ currency: this.props.currency })
			}
			if (prevProps.images !== this.props.images) {
				this.setState({ images: {...this.state.images, ...this.props.images} })
			}
			if (prevProps.logo !== this.props.logo) {
				this.setState({ logo: this.props.logo })
			}
		}
	}

  render() {
		const { title, description, logo, images, currency, price } = this.state
    return (
			<Grid className="preview-promotion-bordered preview-promotion-bordered--gapped border-radius shadow"  item container direction="column" alignItems="center">
				<Typography className='preview-order-item-header'>Preview of Order Item in list view</Typography>
				<Grid container item xs={12} direction='row' className='preview-order-item-first-list-view'>
					<Grid className="preview-order-item-logo" item container xs={8} alignItems="center">
						<img src={logo} />
						<Typography>{title}</Typography>
					</Grid>
					<Grid item container xs={4} justify="flex-start" alignItems="center" className='textPreview'>
						<Typography>{price} {currency}</Typography>
					</Grid>
				</Grid>
				<Typography className='preview-order-item-header'>Preview of Order Item in detailed view</Typography>
				<Grid className="preview-order-item-second-list-view" item container>
					<Grid container direction='column'>
						<Grid className="preview-order-item-box-first" item xs={12}>
							<Typography className='preview-order-item-second-list-view-title'>{title}</Typography>
						</Grid>
					</Grid>
					<Grid className="preview-order-item-box-second" container item xs={12}>
						<Grid className="preview-order-item-title" item xs={3}>
							<Typography className='preview-order-item-second-list-view-price'>{price} <span>{currency}</span></Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography className='preview-order-item-second-list-view-desc'>{description}</Typography>
							<Grid item container xs={12} justify="center">
								<Grid className="preview-order-item-second-list-small-topic">
									<img className='preview-order-item-photos-img--avatar' src={images ? images.img_1 : null} />
								</Grid>
								<Grid className="preview-order-item-second-list-small-topic preview-order-item-second-list-small-topic--middle">
									<img className='preview-order-item-photos-img--avatar' src={images ? images.img_2 : null} />
								</Grid>
								<Grid className="preview-order-item-second-list-small-topic">
									<img className='preview-order-item-photos-img--avatar' src={images ? images.img_3 : null} />
								</Grid>
							</Grid>
						</Grid>
						<Grid className="preview-order-item-second-list-img" item xs={3}>
							<img src={logo} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
    );
  }
}

PreviewOrderItems.propTypes = {
}

export default connect(() => {
  return {

  }
}, (dispatch) => {
  const actions = { deleteSubPromotions, getSubPromotions };
  return bindActionCreators(actions, dispatch);
})(PreviewOrderItems);
