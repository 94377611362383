import {
	HIDE_ERROR,
  USECLIPBOARD,
  GETSTORECATEGORIES,
  GETMERCHANTCARD2,
  GETMERCHANTMAXDISTANCE,
  UPLOAD_ERROR,
  BEGINUPLOAD,
  ADDMERCHANTMAXDISTANCE,
  ADDMERCHANTCARD,
  GETPAYMENTS,
  VERIFYEMAIL,
  GETEMAILVERIFICATIONSTATUS,
  GETCONTACTSWEB,
  GETAGENTS,
  GETGENERALAGENTS,
  GETTELLERS,
  UPDATEEMAIL,
  GETAUTOREPLYMESSAGE,
  ADDAUTOREPLYMESSAGE,
  LISTMERCHANTCANNEDANSWERS,
  ADDMERCHANTCANNEDANSWER,
  DELETEMERCHANTCANNEDANSWER,
  EDITMERCHANTCANNEDANSWER,
  LISTMERCHANTCANNEDIMAGES,
  ADDMERCHANTCANNEDIMAGE,
  EDITMERCHANTCANNEDIMAGECAPTION,
  DELETEMERCHANTCANNEDIMAGE,
  TOGGLECONTACTBLOCK,
  REGISTERAGENT,
  EDITQRCODE,
  EDITAGENT,
  GETAGENTINFO,
  GETGENERALAGENTINFO,
  SETFLAG,
  USERCHANGEPROFILE,
  REFRESHNAVIGATION
} from './constants';

export function hideError() {
  return {
    type: HIDE_ERROR,
  };
}

export function setUseClipboard(params) {
  return {
    type: USECLIPBOARD,
    params,
  };
}

export function getStoreCategories(params) {
  return {
    type: GETSTORECATEGORIES,
    params,
  };
}

export function getMerchantCard(params) {
  return {
    type: GETMERCHANTCARD2,
    params,
  };
}

export function getMerchantMaxDistance(params) {
  return {
    type: GETMERCHANTMAXDISTANCE,
    params,
  };
}

export function uploadError(params) {
  return {
    type: UPLOAD_ERROR,
    params,
  };
}

export function beginUpload(params) {
  return {
    type: BEGINUPLOAD,
    params
  };
}

export function addMerchantMaxDistance(params) {
  return {
    type: ADDMERCHANTMAXDISTANCE,
    params
  }
}

export function addMerchantCard(params) {
  return {
    type: ADDMERCHANTCARD,
    params,
  };
}

export function getPayments() {
  return {
    type: GETPAYMENTS,
  };
}

export function verifyEmail(params) {
  return {
    type: VERIFYEMAIL,
    params,
  };
}

export function getEmailVerificationStatus() {
  return {
    type: GETEMAILVERIFICATIONSTATUS,
  };
}

export function getContacts() {
  return {
    type: GETCONTACTSWEB,
  };
}

export function getAgents() {
  return {
    type: GETAGENTS
  };
}

export function getGeneralAgents(params) {
  return {
    type: GETGENERALAGENTS,
    params,
  };
}

export function getTellers(params) {
  return {
    type: GETTELLERS,
    params,
  };
}

export function updateEmail(params) {
  return {
    type: UPDATEEMAIL,
    params,
  };
}

export function getAutoReplyMessage(params) {
  return {
    type: GETAUTOREPLYMESSAGE,
    params
  }
}

export function addAutoReplyMessage(params) {
  return {
    type: ADDAUTOREPLYMESSAGE,
    params
  }
}

export function listMerchantCannedAnswers(params) {
  return {
    type: LISTMERCHANTCANNEDANSWERS,
    params
  }
}

export function addMerchantCannedAnswer(params) {
  return {
    type: ADDMERCHANTCANNEDANSWER,
    params
  }
}

export function deleteMerchantCannedAnswer(params) {
  return {
    type: DELETEMERCHANTCANNEDANSWER,
    params
  }
}

export function editMerchantCannedAnswer(params) {
  return {
    type: EDITMERCHANTCANNEDANSWER,
    params
  }
}

export function listMerchantCannedImages(params) {
  return {
    type: LISTMERCHANTCANNEDIMAGES,
    params
  }
}

export function addMerchantCannedImage(params) {
  return {
    type: ADDMERCHANTCANNEDIMAGE,
    params
  }
}

export function editMerchantCannedImageCaption(params) {
  return {
    type: EDITMERCHANTCANNEDIMAGECAPTION,
    params
  }
}

export function deleteMerchantCannedImage(params) {
  return {
    type: DELETEMERCHANTCANNEDIMAGE,
    params
  }
}

export function toggleContactBlock(params) {
  return {
    type: TOGGLECONTACTBLOCK,
    params,
  };
}

export function registerAgent(params) {
  return {
    type: REGISTERAGENT,
    params,
  };
}

export function editQrCode(params) {
  return {
    type: EDITQRCODE,
    params
  };
}

export function getAgentInfo(params) {
  return {
    type: GETAGENTINFO,
    params,
  };
}

export function editAgent(params) {
  return {
    type: EDITAGENT,
    params
  };
}

export function getGeneralAgentInfo(params) {
  return {
    type: GETGENERALAGENTINFO,
    params,
  };
}

export function userChangeProfile() {
  return {
    type: USERCHANGEPROFILE,
  };
}

export function setFlag(params) {
  return {
    type: SETFLAG,
    params,
  };
}

export function refreshNavigation(params) {
  return {
    type: REFRESHNAVIGATION,
    params,
  };
}