import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Dialog, DialogTitle, DialogContent, List, ListItem, Divider, ListItemText } from '@material-ui/core';
import './styles.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class SuspendAgent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agentId: null,
			activeAgentSelected: false,
			suspendAgentId: null,
			suspendAgentSelected: false
		}
	}
	handleSuspendAgent = () => {

	}

	handleActiveAgent = () => {

	}

	handleActiveAgentSelect = (id) => {
		this.setState({
			agentId: id,
			activeAgentSelected: true
		})
	}

	handleSuspendAgentSelect = (id) => {
		this.setState({
			suspendAgentId: id,
			suspendAgentSelected: true
		})
	}

	handleClose = () => {
		this.setState({
			suspendAgentId: null,
			agentId: null,
			activeAgentSelected: false,
			suspendAgentSelected: false
		})
		this.props.handleCloseSuspendAgent()
	}

	render() {
		const { savedAgents } = this.props;
		const { activeAgentSelected, agentId, suspendAgentId, suspendAgentSelected } = this.state;
		const activeSelected = activeAgentSelected ? 'settings-suspend-agent-list-item-text--color' : '';
		const suspendSelected = suspendAgentSelected ? 'settings-suspend-agent-list-item-text--color' : '';
		return (
			<Dialog
				open={this.props.openSuspendAgent}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text settings-dialog-text--suspend-agent'>
					<ArrowBackIcon onClick={this.handleClose} className="order-item-edit-dialog-back-btn" />
						Suspend Agent
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogContent className='settings-suspend-agent-content popup-content-xs'>
					<Grid item container className='settings-suspend-agent-content-grid'>
						<Grid item xs={5} className='settings-suspend-agent-content-grid'>
							<Typography className='settings-suspend-agent-content-header-text'>Active Agents</Typography>
							<DialogContent>
								<List className='settings-suspend-agent-list'>
									{(savedAgents || []).map((data, index) => {
										if (!data.suspended) {
											return(
												<ListItem
													className="settings-suspend-agent-list-item"
													key={index}
												>
													<ListItemText
														className={`settings-suspend-agent-list-item-text ${data.uid === agentId ? activeSelected : ''}`}
														primary={data.name}
														onClick={this.handleActiveAgentSelect.bind(this, data.uid)}
													/>
												</ListItem>
											)
										}
									})}
								</List>
							</DialogContent>
							<Typography className='settings-suspend-agent-content-footer-text'>
								If you want to suspend Agent, choose her/his name from the list and press &quot;Suspend Agent&quot; button
							</Typography>
							<Button
								disabled={!activeAgentSelected}
								variant="contained"
								color="primary"
								className='settings-main-buttons settings-auto-replay-buttons--width settings-suspend-agent-buttons'
								onClick={this.handleSuspendAgent}
							>
								Suspend Agent
							</Button>
						</Grid>
						<Grid className='settings-dialog-blank settings-dialog-blank--half--suspend-agent' container item xs={1}></Grid>
						<Grid item xs={5} className='settings-suspend-agent-content-grid'>
							<Typography className='settings-suspend-agent-content-header-text'>Suspended Agents</Typography>
							<DialogContent>
								<List className='settings-suspend-agent-list'>
									{(savedAgents || []).map((data, index) => {
										if (data.suspended) {
											return(
												<ListItem
													className="settings-suspend-agent-list-item"
													key={index}
												>
													<ListItemText
														className={`settings-suspend-agent-list-item-text ${data.uid === suspendAgentId ? suspendSelected : ''}`}
														primary={data.name}
														onClick={this.handleSuspendAgentSelect.bind(this, data.uid)}
													/>
												</ListItem>
											)
										}
									})}
								</List>
							</DialogContent>
							<Typography className='settings-suspend-agent-content-footer-text'>
								If you want to active Agent, choose her/his name from the list and press &quot;Active Agent&quot; button
							</Typography>
							<Button
								disabled={!suspendAgentSelected}
								variant="contained"
								color="primary"
								className='settings-main-buttons settings-auto-replay-buttons--width settings-suspend-agent-buttons'
								onClick={this.handleActiveAgent}
							>
								Active Agent
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}

SuspendAgent.propTypes = {
  userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.number,
};
 
export default connect(({common, auth}) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
		savedAgents: common.savedAgents,
  }
}, (dispatch) => {
  const actions = {};
  return bindActionCreators(actions, dispatch);
})(SuspendAgent);