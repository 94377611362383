import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Grid, ListItemText, Button, List, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPromotions, getSubPromotions } from '../../store/promotions/actions';
import { connectWeb, setActivePage } from '../../store/auth/actions';
import moment from 'moment';
import './styles.css';

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
			sort: false,
			allPromos: [],
			options: [
				{ id: 0, text: 'Name' },
				{ id: 1, text: 'Date' },
				{ id: 2, text: 'Status' }
			],
			promoGridSelected: false,
			openPreview: false,
			openSubPreview: false,
			selectedOption: 0,
			isVisibleMenu: false
    };
  }

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.allPromotions !== this.props.allPromotions) {
			let newArr = this.props.allPromotions.map((data) => {
				return {
					id: data.id,
					title: data.title,
					date: data.end_time ? moment.unix(data.end_time).format("YY-MM-DD hh:mm") : '',
					status: data.expired ? data.state === 0 ? 'Draft' : 'Expired' : data.state === 0 ? 'Draft' : 'Active'
				}
			})
			this.setState({allPromos: newArr.sort((a, b) => a.title.localeCompare(b.title))})
		}
	}

	handleSort = (id) => {
		if (this.state.selectedOption !== id) {
			this.setState({ selectedOption: id });
		}

		if (id === 0) {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => a.title.localeCompare(b.title)), isVisibleMenu: false})
		} else if (id === 1) {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => b.date.localeCompare(a.date)), isVisibleMenu: false})
		}	else {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => a.status.localeCompare(b.status)), isVisibleMenu: false})
		}
	}
	
	handleOpenPromotion = () => {
		this.props.openPromotion()
	}

	handleCopyPromotion = () => {
		this.props.copyPromotion()
	}

	handleDeletePromotion = () => {
		this.props.deletePromotion()
		this.props.getPromotions([null, 0])
	}

	handleCreatePromotion = (type) => {
		this.props.createNewPromotion(type)
	}
	
	handleSeveralNameOfOptions = (index) => {
		switch (index) {
			case 0:
				return 'Name'
			case 1:
				return 'Date'
			case 2:
				return 'Status'
				
			default:
				return 'Name'
		}
	}

	handleOpenSelectOptionsMenu = () => {
		this.setState({ isVisibleMenu: true });
	}

  render() {
		const { options, selectedOption, isVisibleMenu } = this.state
		const { activePromotions } = this.props
		const selectedHeaderStyle = this.props.flag ? 'promotions-header-new' : '';
		const isVisible = isVisibleMenu ? 'visible' : 'hidden'
		// let btnClass = [
    //   'promotion-main-buttons',
		// 	this.props.flag ? 'promotions-open-button--select' : ''
    // ]
		// btnClass = btnClass.join(' ')
    return (
			<Grid className="promotions-wrapper" item xs={5}>
				<Typography className={`promotions-header ellipsis ${selectedHeaderStyle}`}>Promotions</Typography>
				<Grid item container direction='row' xs={12} 
					className={`promotions-first-row ${ this.props.flag ? 'promotions-first-row--selected' : ''}`}
					onClick={() => this.props.gridSelect(this.props.type)}
				>
					<Grid item container direction='row' xs={12} className={`promotions-list-header ${ this.props.flag ? 'promotions-list-header--selected' : ''}`}>
						<Grid item container xs={6} alignItems="center" className="promotions-list-header-inner">
							<Typography 
								className="promotions-list-header-title ellipsis"
								onClick={() => { this.handleOpenSelectOptionsMenu() }}
								title={ 'Sort by '+ this.handleSeveralNameOfOptions(selectedOption)}
								>
									Sort by {this.handleSeveralNameOfOptions(selectedOption)}
							</Typography>

							<List className={`promotions-select-options promotions-select-options--${isVisible}`}>
								{options.map(opt => {
									return(
										<ListItem 
											className={`promotions-select-option ${ selectedOption === opt.id ? 'promotions-select-option--active' : ''}`} 
											onClick={() => { this.handleSort(opt.id) }} 
											key={opt.id}
											>
												<ListItemText>{`Sort By ${opt.text}`}</ListItemText>
										</ListItem>
									)
								})}	
							</List>	
						</Grid>
						<Grid item xs={3} className='promotions-and-order-item-header-main'>
							<Typography className='promotions-and-order-item-header-main-text ellipsis'>Date</Typography>
						</Grid>
						<Grid item xs={3} className='promotions-and-order-item-header-main'>
							<Typography className='promotions-and-order-item-header-main-text ellipsis'>Status</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} className={`promotions-list-wrapper`}>
						<List className="promotions-list-items">
							{Object.keys(this.state.allPromos).map((key, index) => {
								return (
									<ListItem
										className="promotions-list-item"
										button={this.props.flag ? true : false}
										key={index}
										onClick={this.props.flag ? this.props.editPromoFlag ? null : this.props.handlePromotionSelect.bind(this, this.state.allPromos[key].id) : null}
									>
										<Grid item container direction='row' className={`promotions-list ${this.props.flag ? this.props.selectedPromotionId === this.state.allPromos[key].id ? 'promotions-title--selected' : '' : ''}`}>
											<Grid item container xs={6} justify="flex-start" >
												<ListItemText
													className="promotions-list-title"
													primary={this.state.allPromos[key].title}
												/>
											</Grid>
											<Grid item xs={3} container justify="flex-start">
												<ListItemText
													className="promotions-list-date"
													primary={this.state.allPromos[key].date}
												/>
											</Grid>
												<Grid item xs={3} container justify="flex-start" className='promotions-and-order-item-list-status'>
													<ListItemText
														primary={this.state.allPromos[key].status}
													/>
											</Grid>
										</Grid>
									</ListItem>
								)
							})}
						</List>
					</Grid>
				</Grid>
				<div className="promotions-button-main-div">
					<div className="flex-cell centered promos-buttons">
						<Button
							size="small"
							className="promos-buttons"
							variant="contained"
							color="primary"
							onClick={this.handleOpenPromotion}
							disabled={this.props.openPreview ? false : true }
						>
							Open
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button
							className="promos-buttons"
							size="small"
							variant="contained"
							color="primary"
							disabled={this.props.status !== 'Expired'}
							onClick={this.handleCopyPromotion}
						>
							Copy
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button
							className="promos-buttons"
							size="small"
							variant="contained"
							color="primary"
							onClick={this.handleDeletePromotion}
							disabled={this.props.status === 'Expired' || this.props.status === 'Draft' ? false : true}
						>
							Delete
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button
							className="promos-buttons"
							size="small"
							variant="contained"
							color="primary"
							onClick={this.handleCreatePromotion.bind(this, 'create')}
							disabled={activePromotions.length > 0 ? true : this.props.flagThird ? true : this.props.editPromoFlag ? true : false}
						>
							Create New
						</Button>
					</div>
				</div>
			</Grid>
    );
  }
}

Promotions.propTypes = {
  getPromotions: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
  //uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
	getSubPromotions: PropTypes.func.isRequired,
};

export default connect(({common, promotions, auth}) => {
  return {
    token: auth.token,
    uid: auth.uid,
    promotions: promotions.promotions,
    isValidToken: auth.isValidToken,
    userType: auth.userType,
    forDelete: promotions.forDelete,
    clipboard: promotions.clipboard,
    activePromotions: promotions.activePromotions,
		subPromotions: promotions.subPromotions,
    allPromotions: promotions.allPromotions,
    expiredPromotions: promotions.expiredPromotions,
    useClipboard: common.useClipboard,
    editPromotion: promotions.editPromotion,
		promotionForPublish: promotions.promotionForPublish
  }
}, (dispatch) => {
  const actions = { getPromotions, connectWeb, setActivePage, getSubPromotions };
  return bindActionCreators(actions, dispatch);
})(Promotions);
