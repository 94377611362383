import React, {Component} from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Divider } from '@material-ui/core';
import './styles.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class MemberShipStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Dialog
				open={this.props.openMembership}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text'>
					<ArrowBackIcon onClick={this.props.handleCloseMembership} className="order-item-edit-dialog-back-btn" />
					My Membership Status
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogContent className='setting-membership-dialog-content'>
					<Typography className='settings-dialog-content-header-text'>Your Membership is Active for:</Typography>
					<Grid className='setting-membership-dialog-content-circle'>
						<Typography className='setting-membership-dialog-content-circle-text'>40 Days</Typography>
					</Grid>
					<DialogActions>
						<Button
							variant="outlined"
							className='promotion-main-outlined-buttons promotion-main-outlined-buttons--cancel'
							onClick={this.props.handleMembershipContact}
						>
							Contact
						</Button>
						<Button
							variant="contained"
							color="primary"
							className='promotion-main-buttons promotion-main-buttons-dialog'
							onClick={this.props.handleCloseMembership}
						>
							Ok
						</Button>
					</DialogActions>
					<Typography className='setting-membership-dialog-footer-text'>*Contact Support to Prolong Your Status</Typography>
				</DialogContent>
			</Dialog>
		)
	}
}