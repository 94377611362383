import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Divider, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { verifyEmail, updateEmail, getEmailVerificationStatus } from '../../store/common/actions';
import './styles.css';

class EmailVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			newEmail: '',
			newEmailError: false,
			newEmailErrorMsg: '', 
			emailVerified: false,
			emailCode: '',
			emailCodeError: false,
			emailCodeErrorMsg: ''
		}
	}

	componentDidMount() {
		this.props.getEmailVerificationStatus()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.emailVerificationStatus !== this.props.emailVerificationStatus) {
			this._updateState();
		}
		if (prevProps.verifyEmailStatus && !this.props.verifyEmailStatus) {
			this.setState({
				emailCodeError: true,
				emailCodeErrorMsg: 'Invalid verification code'
			})
		} else if (prevProps.verifyEmailStatus && this.props.verifyEmailStatus && this.state.emailCode) {
			this.setState({
				emailCodeError: true,
				emailCodeErrorMsg: 'Successful verification',
				emailCode: '',
			})
		}
	}

	_updateState = (data) => {
    const d = data ? data.emailVerificationStatus : this.props.emailVerificationStatus
    if (d !== null) {
      const status = d;
      let email = '';
      if (status[1] !== null) {
        email = status[1];
			}

			const emailVerified = status[2];
      this.setState({
        email, // na startu '' ako nema nista iz baze
        newEmail: this.state.newEmail !== '' ? this.state.newEmail : email, // na startu '' ako nema nista iz baze
        emailVerified
      });
    }
  };

	handleEmailChange = (e) => {
		this.setState({
			newEmail: e.target.value,
			newEmailError: false
		});
	}

	handleUpdateEmail = () => {
		const { newEmail, email } = this.state;

    if (!this.validateEmail(newEmail)) {
      this.setState({ newEmailError: true, newEmailErrorMsg: 'Please enter valid Email' });
      return;
    }

    let data = {
      email: this.state.newEmail,
    };
		if (newEmail !== email) {
			this.props.updateEmail([data]);
			this.setState({emailVerified: false, newEmailError: true, newEmailErrorMsg: 'Verification Code sent'})
		} else {
			this.setState({ newEmailError: true, newEmailErrorMsg: 'This email already exist for this account' });
		}
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

	handleVerificationCodeChange = (e) => {
		this.setState({
			emailCode: e.target.value,
			emailCodeError: false,
			emailCodeErrorMsg: ''
		})
	}

  handleVerifyEmail = () => {
    const { emailCode } = this.state;
		
    const data = {
      email_code: emailCode,
    };
    this.props.verifyEmail([data]);
  };

	render() {
		const { emailCode, emailCodeError, emailCodeErrorMsg, newEmail, emailVerified, newEmailErrorMsg, newEmailError } = this.state
		return (
			<Dialog
				open={this.props.openEmailVerification}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text'>
					<ArrowBackIcon onClick={this.props.handleCloseEmailVerification} className="order-item-edit-dialog-back-btn" />
						Email Verification
					<Divider className='settings-dialog-title-divider'/>
					<Typography className="settings-email-verification-alert">{emailVerified ? 'Email is verified' : 'Email is not verified'}</Typography>
				</DialogTitle>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogContent className='settings-email-verification-content-main popup-content-xs'>
					<Typography className='settings-dialog-content-header-text'>Email</Typography>
					<TextField
						className="settings-email-verification-inputs"
						fullWidth
						placeholder="Enter your Email"
						value={newEmail !== '' ? newEmail : ''}
						onChange={this.handleEmailChange}
						variant="filled"
						error={newEmailError}
						helperText={newEmailError ? newEmailErrorMsg : ''}
					/>
					<DialogActions>
						<Button
							disabled={newEmail !== '' ? false: true}
							variant="contained"
							color="primary"
							className='settings-main-buttons setting-email-verification-buttons--width'
							onClick={this.handleUpdateEmail}
						>
							Save & Send verification Email
						</Button>
					</DialogActions>
				</DialogContent>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogContent className='settings-email-verification-content-main popup-content-xs'>
					<Typography className='settings-dialog-content-header-text'>Verification Code</Typography>
					<TextField
						className="settings-email-verification-inputs"
						fullWidth
						variant="filled"
						placeholder="Enter your Verification code"
						value={emailCode !== '' ? emailCode : ''}
						onChange={this.handleVerificationCodeChange}
						error={emailCodeError}
						helperText={emailCodeError ? emailCodeErrorMsg : ''}
					/>
					<DialogActions>
						<Button
							disabled={emailCode !== '' ? emailVerified ? true : false : true}
							variant="contained"
							color="primary"
							className='settings-main-buttons setting-email-verification-buttons--width'
							onClick={this.handleVerifyEmail}
						>
							Verify
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		)
	}
}

EmailVerification.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ common, auth }) => {
  return {
		uid: auth.uid,
    token: auth.token,
    userType: auth.userType,
		merchantInfo: common.merchantInfo,
		emailVerificationStatus: common.emailVerificationStatus,
		isAuthorized: auth.isAuthorized,
		verifyEmailStatus: common.verifyEmailStatus
  }
}, (dispatch) => {
  const actions = {verifyEmail, updateEmail, getEmailVerificationStatus};
  return bindActionCreators(actions, dispatch);
})(EmailVerification);