import navConfig from '../navConfig';
import { sendMessage } from '../store/comm/sender';
import moment from 'moment';
import _ from 'lodash';
import beep from '../assets/sound/beep2.mp3';

const chunkSize = 2;

export const getDefaultRoute = (protectedRoute) => {
	//1st iteratioen: get default protected/unprotected route
	let defaultRoute = navConfig.routes.find(item => isDefined(item.default) && item.default && (protectedRoute ? item.protected === true : item.protected !== true))
	//2nd iteration: none of routes have 'default' property. Then find first protected/unprotecded route
	if (!defaultRoute){
		defaultRoute = navConfig.routes.find(item => protectedRoute ? item.protected === true : item.protected !== true)
		if (isDefined(defaultRoute)) {
			return defaultRoute
		}
	}
	//3rd iteration: fallback: pick first route
	return navConfig.routes[0]
}

export const isDefined = item => item !== undefined && item !== null

export function pairMessages(message) {
  message = JSON.parse(message);
  let storedMessages = JSON.parse(localStorage.getItem('messages'));
  storedMessages = storedMessages.map(JSON.parse);
  const messagePaired = storedMessages.find(obj => {
    return obj.seq == message.seq;
  });
  message.method = messagePaired.method.toUpperCase() + message.method.toUpperCase();
	message.request = messagePaired;
  return message;
}

export function fixMerchantCardResult(merchantCardResult) {
  if ({}.hasOwnProperty.call(merchantCardResult, 'logo')) {
    let merchantLogo = merchantCardResult.logo;
    if (merchantCardResult.logo) {
      merchantCardResult.logo = merchantLogo.replace("http:", "");
    }
  }
  if ({}.hasOwnProperty.call(merchantCardResult, 'covers')){
    let merchantCovers = merchantCardResult.covers;
    if ({}.hasOwnProperty.call(merchantCovers, 'center')) {
      let merchantCoversCenter = merchantCovers.center;
      merchantCovers.center = merchantCoversCenter.replace("http:", "");
    }
    if ({}.hasOwnProperty.call(merchantCovers,'left')) {
      let merchantCoversLeft = merchantCovers.left;
      merchantCovers.left = merchantCoversLeft.replace("http:", "");
    }
    if ({}.hasOwnProperty.call(merchantCovers,'right')) {
      let merchantCoversRight = merchantCovers.right;
      merchantCovers.right = merchantCoversRight.replace("http:", "");
    }
    merchantCardResult.covers = merchantCovers;
  }
  return merchantCardResult;
}

export const checkGeolocationAvailability = function(){
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

export function isReadonly() {
  if (localStorage.getItem('tokener') && localStorage.getItem('tokener').charAt(0) === '1') return true
  else return false
}

export function getRealMimeType(result){
  const arr = (new Uint8Array(result)).subarray(0, 4);
  let header = '';
  let realMimeType;

  for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
  }

  switch (header) {
    case "89504e47":
        realMimeType = "image/png";
        break;
    case "47494638":
        realMimeType = "image/gif";
        break;
    case "ffd8ffDB":
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
        realMimeType = "image/jpeg";
        break;
    default:
        realMimeType = "unknown";
        break;
  }

  return realMimeType;
}

export function checkUrl(path) {
   if (path === '' || path === null) return true;

   // With https:  let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
   let regexp = /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ //eslint-disable-line
   return regexp.test(path);
}


export function str2ab(str) {
  const buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  const bufView = new Uint8Array(buf);
  for (let i=0, strLen=str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function isDateBefore (date) {
  if (!date) return false;

  const offset = "+0";  
  const initDate = new Date(date*1000);
  const localOffset = initDate.getTimezoneOffset() * 60000;
  let utc = initDate.getTime();
  utc += localOffset;
  const serverDateTime = new Date(utc + (3600000 * offset));
  const localDateTime = new Date();
  if (serverDateTime < localDateTime) {
    return true;
  }
  return false;
}

export const getGeneralAgentChunk = (from = 0) => {
  sendMessage('getGeneralAgentMerchants', [from, chunkSize]);
};

export function saveContactsInfo(newContactsArr, merchantUid, userType, callback) {
	const lsItem = 'contactsInfo';
	let resIndex;
	let data = getLocalStorageData(lsItem, userType);

  //update existing contacts
	if (newContactsArr.length > 0) {
		newContactsArr.forEach((item, index) => {
			if (item.last_chat_date_time) {
				item.lastMessageTime = item.last_chat_date_time;
				// just to check
				const lastDay = moment(item.lastMessageTime).format('YYYY-MM-DD');
				const dayFound = item.chat_dates.find(item => {
					return String(item) === String(lastDay);
				});
				if (!dayFound) item.chat_dates.push(lastDay);
			} else {
				item.lastMessageTime = moment().subtract(12, 'months');
			}
			delete item.last_chat_date_time;

			if (item.unread_msg_count) {
				item.count = item.unread_msg_count;
			} else {
				item.count = 0;
			}
			delete item.unread_msg_count;
			if (userType === 4) {
				resIndex = -1;
				Object.keys(data).forEach((key, index) => {
					if (data[key].phone_data && data[key].phone_data[0].user_id === item.phone_data[0].user_id) {
						resIndex = index;
					}
				})
			} else {
				resIndex = data.findIndex(itemIn => {
					return (itemIn.phone_data[0].user_id === item.phone_data[0].user_id);
				});
			}

			if (resIndex !== -1) {
				const oldData = Object.assign({}, data[resIndex]);
				const newData = Object.assign({}, newContactsArr[index]);
				if (newData.phone_data[0].profile_data.display_name === null && newData.phone_data[0].profile_data.avatar === null) {
					newData.phone_data[0].profile_data = Object.assign({}, oldData.phone_data[0].profile_data);
				}
				data[resIndex] = Object.assign({}, newContactsArr[index]);
				//update userData blocked status
				updateBlocked(item.phone_data[0].user_id, item.blocked);
				//update prevChatDate
				if (item.previous_chat_date) {
					updatePrevChatDate(item.phone_data[0].user_id, item.previous_chat_date);
				}
				updateUnreadCount2(item.phone_data[0].user_id, item.count);
				updateChatDates(item.phone_data[0].user_id, item.chat_dates);
			} else {
				if (typeof merchantUid === 'number') {
					item.merchantUid = merchantUid;
				}
				if (userType === 4) {
					if (_.isEmpty(data) || (!_.isEmpty(data) && !data[merchantUid])) {
						data[merchantUid] = [];
					}
					data[merchantUid].push(item);
				} else {
					data.push(item);
				}

				// if not in contacts add to userData as well
				let usersData = getLocalStorageData('usersData', userType);
				let resIndex2 = null;
				if (typeof merchantUid === 'number') {
					if (!_.isEmpty(usersData)) {
						if (!usersData[merchantUid]) {
							usersData[merchantUid] = []
						}
						resIndex2 = usersData[merchantUid].findIndex(item2 => {
							return (item2.uid === item.phone_data[0].user_id);
						});
					} else {
						resIndex2 = -1;
						usersData[merchantUid] = []
					}
				} else {
					resIndex2 = usersData.findIndex(item2 => {
						return (item2.uid === item.phone_data[0].user_id);
					});
				}

				if (resIndex2 === -1) {
					const element = {
						uid: item.phone_data[0].user_id,
						blocked: item.blocked ? item.blocked : false,
						favorite: item.favorite ? item.favorite : false,
						count: item.count,
						//lastSeen: null,
						chat_date_index: 0,
						chat_dates: item.chat_dates ? item.chat_dates.sort().reverse() : [],
						merchantUid: typeof merchantUid === 'number' ? merchantUid : null
					};

					if (typeof merchantUid === 'number') {
						usersData[merchantUid].push(element);
					} else {
						usersData.push(element);
					}
					localStorage.setItem('usersData', JSON.stringify(usersData));
				}
			}
		});
	}
  setLocalStorageData(lsItem, data);

  if (callback) callback();
}


function getLocalStorageData(item, userType){
	let ls = localStorage.getItem(item);
	if (typeof userType === 'number') {
		return ls !== null ? JSON.parse(ls) : {};
	} else {
		return ls !== null ? JSON.parse(ls) : [];
	}
}

function updateBlocked(uid, blocked){
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const resIndex = users.findIndex(function(item){
    return (item.uid === uid);
  });
  if (resIndex !== -1){
    users[resIndex].blocked = blocked;
  }

  localStorage.setItem('usersData', JSON.stringify(users));
}

function updatePrevChatDate(uid, date) {
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const resIndex = users.findIndex(function(item) {
    return (item.uid === uid);
  });
  if (resIndex !== -1){
    users[resIndex].prevChatDate = date;
  }

  localStorage.setItem('usersData', JSON.stringify(users));
}

function updateUnreadCount2(uid, date) {
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const resIndex = users.findIndex(function(item) {
    return (item.uid === uid);
  });

  if (resIndex !== -1 && date) {
    users[resIndex].count = date;
    localStorage.setItem('usersData', JSON.stringify(users));
  }
}

function updateChatDates(uid, date) {
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const resIndex = users.findIndex(function(item) {
    return (item.uid === uid);
  });

  if (resIndex !== -1 && date) {
    users[resIndex].chat_dates = date.sort().reverse();
    localStorage.setItem('usersData', JSON.stringify(users));
  }
}

function setLocalStorageData(item, arr){
  localStorage.setItem(item, JSON.stringify(arr));
}

export function getUserBlockStatus(uid){
  const lsItem = 'usersData';
  const data = getLocalStorageData(lsItem);
  let resIndex = data.findIndex(function(item){
    return (item.uid === uid);
  });
  
  if(resIndex !== -1){
    return data[resIndex].blocked;
  } 
  return false;
}

export function blockUser(uid, value) {
  const lsItem = 'usersData';
  const data = getLocalStorageData(lsItem);
  const resIndex = data.findIndex(item => {
    return (item.uid === uid);
  });

  if (resIndex !== -1) {
    data[resIndex].blocked = value;
  } else {
    return;
  }

  setLocalStorageData(lsItem, data);
}

export function getProfileData(uid, getEntireObj) {
  const lsItem = 'contactsInfo';
  const data = getLocalStorageData(lsItem);

  const res = data.find(item => {
    return item !== null && item.phone_data[0].user_id === uid;
  });
  return res !== undefined ? getEntireObj !== undefined ? res : res.phone_data[0].profile_data : {};
}

export function resetUnreadCounter(uid) {
  const ls = localStorage.getItem('usersData');
  const unreadCount = ls !== null ? JSON.parse(ls) : [];
  const resIndex = unreadCount.findIndex(item => {
    return (item.uid === uid);
  });
  if (resIndex !== -1) {
    unreadCount[resIndex].count = 0;
    localStorage.setItem('usersData', JSON.stringify(unreadCount));
  }
}

export function getChatHistoryDate(uid, firstLoad) {
  const lsItem = 'usersData';
  const ls = localStorage.getItem(lsItem);
  const data = ls !== null ? JSON.parse(ls) : [];
  const resIndex = data.findIndex(item => {
    return (item.uid === uid);
  });

  if (resIndex !== -1 && data[resIndex].chat_date_index !== undefined && data[resIndex].chat_dates !== undefined && data[resIndex].chat_dates.length !== 0){
    let chat_index = data[resIndex].chat_date_index;
    // if firstLoad that is when contact is clicked and if there are already loaded history data don't call hostiry
    if (firstLoad === true && chat_index > 0) {
      return false;
    }
    let chat_date = data[resIndex].chat_dates[chat_index];
    if(chat_date) data[resIndex].chat_date_index = chat_index+1;
    setLocalStorageData(lsItem, data);
    return chat_date;
  } else {
    return false;
  }
}

export function saveHistoryMessages(messages) {
  messages.forEach(messageItem => {
    const msgData = {
      'msg_type': messageItem.msg_data.msg_type,
      'text': messageItem.msg_data.text,
      'attachment': messageItem.msg_data.attachment || null,
      'location': messageItem.msg_data.location || null,
      'msg_id': messageItem.id,
    };

    const message = [messageItem.sender, moment.utc(messageItem.msg_time), msgData, messageItem.web_state];
    saveHistoryMessage(message, messageItem.recipient);
  });
}

export function saveHistoryMessage(message, recipient) {
  const myId = localStorage.getItem('uid');

  if (String(message[0]) === myId) {
    message[0] = recipient;
    message[4] = true;
  }

  if (getUserBlockStatus(message[0])) {
    //do nothing if user was blocked
    return;
  }

  switch (message[3]) {
    case 1:
      message[2]['view_status'] = 'delivered';
      break;
    case 2:
      message[2]['view_status'] = 'seen';
      break;
    default:
      message[2]['view_status'] = 'sent';
      break;
  }

  let messages = localStorage.getItem('chatMessages');
  messages = messages !== null ? JSON.parse(messages) : [];

  const element = findMessage(message[0], messages);

  const found = element.element.find(item => {
    return item[2].msg_id === message[2].msg_id;
  });

  if (!found) {
    addToArray(element, message, messages, true);
  }
  localStorage.setItem('chatMessages', JSON.stringify(messages));
}

export function findMessage(uid, messages) {
  const resIndex = messages.findIndex(function(message){
    return (message !== undefined && message !== null && (message[0] !== undefined && message[0] !== null) && message[0][0] === uid);
  });
  
  let obj = {
    index: resIndex,
    element: resIndex !== -1 ? messages[resIndex] : [],
  };
  return obj;
}

function addToArray(foundItem, newMessage, allMessages, updateCount) {
  if (newMessage[4] === undefined && !updateCount) updateUnreadCount(newMessage[0]);
  if (foundItem.index === -1){
    allMessages.unshift([newMessage]);
  } else {
    allMessages[foundItem.index].unshift(newMessage);
    allMessages = move(foundItem.index, 0, allMessages);
  }
}

function updateUnreadCount(uid){
  let ls = localStorage.getItem('usersData');
  let unreadCount = ls !== null ? JSON.parse(ls) : [];
  let resIndex = unreadCount.findIndex(function(item){
    return (item.uid === uid);
  });

  if (resIndex !== -1){
    unreadCount[resIndex].count++;
  } else {
    const newObj = createEmptyObj(uid);
    newObj.count = 1;
    unreadCount.push(newObj);
  }

  localStorage.setItem('usersData', JSON.stringify(unreadCount));
}

function move(from,to,array){
  let element = array[from];
  array.splice(from, 1);
  array.splice(to, 0, element);
  return array;
}

function createEmptyObj(uid){
  return {
    uid: uid,
    count: 0,
    blocked: false,
    favorite: false,
    //lastSeen: null,
  };
}

export function setLastUserMessage(message) {
  let contacts = localStorage.getItem('contactsInfo');
  contacts = contacts === null ? [] : JSON.parse(contacts);

  const contact = contacts.find(item => {
    if (String(item.phone_data[0].user_id) === String(message[0])) {
      return true;
    }
  });

  if (contact && (contact.lastMessageTime === undefined || moment(message[1]) > moment(contact.lastMessageTime))) {
    contact.lastMessage = message[2];
    contact.lastMessageTime = moment(message[1]);
  }

  localStorage.setItem('contactsInfo', JSON.stringify(contacts));
}

export function setMessageState(message) {
  const messageItemArr = findOneMessage(message[0]);
  const messageItem = messageItemArr[0];
  const messages = messageItemArr[1];

  if (messageItem) {
    if (message[1] === 1) {
      messageItem[2]['view_status'] = 'delivered';
    } else {
      messageItem[2]['view_status'] = 'seen';
    }
  }

  localStorage.setItem('chatMessages', JSON.stringify(messages));
  return messages;
}

function findOneMessage(searchId) {
  let messages = localStorage.getItem('chatMessages');
  messages = messages !== null ? JSON.parse(messages) : [];

  let returnItem = false;

  messages.forEach(userMessageItems => {
    userMessageItems.forEach(messageItem => {
      if (searchId !== undefined && String(messageItem[2].msg_id) === String(searchId)) {
        returnItem = messageItem;
        return true;
      }
    });
  });

  return [returnItem, messages];
}

export function seeMessages() {
  if(!localStorage.getItem('tokener') || localStorage.getItem('tokener').charAt(1) === '1') {
    return true
  } else {
    return false
  }
}

export function getContactId(uid) {
  const contactsInfo = getLocalStorageData('contactsInfo');
  const contact = contactsInfo.filter(item => {
    return item.phone_data[0].user_id === uid;
  });

  return (contact !== undefined) ? contact[0].contact_id : false;
}

export function setLastSeen(uid, value) {
  const lsItem = 'usersData';
  const ls = localStorage.getItem(lsItem);
  const data = ls !== null ? JSON.parse(ls) : [];
  if (data.length === 0) {
    //create new user obj
    data.push(createEmptyObj(uid));
  }

  const resIndex = data.findIndex(item => {
    return (item.uid === uid);
  });

  if (resIndex !== -1) {
    data[resIndex].lastSeen = value;
  } else {
    return;
  }
  setLocalStorageData(lsItem, data);
}

export function getUserData(uid) {
  const ls = localStorage.getItem('usersData');
  const data = ls !== null ? JSON.parse(ls) : [];
  const resIndex = data.findIndex(item => {
    return (item.uid === uid);
  });
  return resIndex !== -1 ? data[resIndex] : {};
}

export const displayOpenedChat = (uid, openedChats) => {
  const oc = getOpenedChat(uid, openedChats)
  if (parseInt(oc) === uid && uid !== 0) {
    return 'Opened chat'
  } else {
    return 'Closed chat'
  }  
}

export function getOpenedChat(uid, openedChats) {
  if (openedChats === undefined || openedChats === null) {
    openedChats = []
  }
  const resIndex = openedChats.findIndex(item => {
    return (item !== undefined ? parseInt(item.user) === uid : 0);
  });
  return (resIndex !== -1 && openedChats[resIndex].user !== undefined) ? openedChats[resIndex].user : 0;
}

export function saveMessage(message) {
  if (getUserBlockStatus(message[0])) {
    //do nothing if user was blocked
    return;
  }

  let messages = localStorage.getItem('chatMessages');
  messages = messages !== null ? JSON.parse(messages) : [];

  if (message[4] && message[4] !== true) {
    message[0] = message[4];
    message[4] = true;
  }
  const element = findMessage(message[0], messages);

  const found = element.element.find(item => {
    return item[2].msg_id === message[2].msg_id;
  });

  if (!found) {
    if (message[4]) {
      message[2]['view_status'] = 'sent';
      localStorage.setItem('lastMessageId', message[1]);
    }
    addToArray(element, message, messages);
    if (message[4] !== true) {
      const audio = new Audio(beep);
      audio.play();
    }
  }
  localStorage.setItem('chatMessages', JSON.stringify(messages));
}

export function getUnreadMessagesCount(uid) {
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const resIndex = users.findIndex(item => {
    return (item.uid === uid);
  });
  if (resIndex !== -1) {
    return users[resIndex].count;
  } else {
    return 0;
  }
}

export const assignedByAgent = (uid, openedChats, agentId) => {
  const oc = getAssignedChat(uid, openedChats)
  if (openedChats === undefined || openedChats === null) {
    openedChats = []
  }
  if (parseInt(oc.user) === uid && uid !== 0) {
    return ['Assigned', oc.agent]
  } else {
    return 'Unassigned'
  }
}

export function getAssignedChat(uid, openedChats) {
  if (openedChats === undefined || openedChats === null) {
    openedChats = []
  }

  const resIndex = openedChats.findIndex(item => {
    return (item !== undefined && parseInt(item.user) === uid ? item : 0);
  });
  return (resIndex !== -1 && openedChats[resIndex].assigned) ? openedChats[resIndex] : 0;
}

function deleteUserHistoryMessages(uid, date) {
  let chatMessages = localStorage.getItem('chatMessages');
  chatMessages = chatMessages !== null ? JSON.parse(chatMessages) : [];

  const resIndex = chatMessages.findIndex(item => {
    return (item[0] && item[0][0] === uid);
  });

  let newMessages = [];
  if (resIndex !== -1) {
    newMessages = chatMessages[resIndex].filter(message => {
      return moment(message[1]).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD');
    });
    chatMessages[resIndex] = newMessages;
    localStorage.setItem('chatMessages', JSON.stringify(chatMessages));
  }

  return true;
}

export function clearChatHistory() {
  let contactsInfo = localStorage.getItem('contactsInfo');
  contactsInfo = contactsInfo !== null ? JSON.parse(contactsInfo) : [];

  contactsInfo.forEach(contact => {
    const uid = contact.phone_data[0].user_id;

    let usersData = localStorage.getItem('usersData');
    usersData = usersData !== null ? JSON.parse(usersData) : [];

    const resIndex = usersData.findIndex(item => {
      return (item.uid === uid);
    });

    const userData = resIndex !== -1 ? usersData[resIndex] : {};

    if (userData.chat_date_index && userData.chat_date_index > 0) {
      usersData[resIndex].chat_date_index = 0;
      deleteUserHistoryMessages(uid, userData.chat_dates[0]);
    }

    localStorage.setItem('usersData', JSON.stringify(usersData));
  });

  return true;
}

export function setMessageRead(messageId) {
  let messages = localStorage.getItem('chatMessages');
  messages = messages !== null ? JSON.parse(messages) : [];

  messages[0] = messages[0].map((messageItem) => {
    if (messageItem[2].msg_id === messageId && (!messageItem[2].read || !messageItem[2].view_status)) {
      messageItem[2].read = true;
      messageItem[2].view_status = "seen";
    }
    return messageItem;
  });

  localStorage.setItem('chatMessages', JSON.stringify(messages));
}

export function setMessageSent(message) {
  const realId = message;
  const tempId = localStorage.getItem('lastMessageId');

  const messageItemArr = findOneMessage(tempId);
  const messageItem = messageItemArr[0];
  const messages = messageItemArr[1];

  if (messageItem) {
    messageItem[2].msg_id = realId;
  }

  localStorage.setItem('chatMessages', JSON.stringify(messages));
}

export function hasChatHistoryDate(uid) {
  const lsItem = 'usersData';
  const ls = localStorage.getItem(lsItem);
  const data = ls !== null ? JSON.parse(ls) : [];
  const resIndex = data.findIndex(item => {
    return (item.uid === uid);
  });

  if (resIndex !== -1 && data[resIndex].chat_date_index !== undefined) {
    const chat_index = data[resIndex].chat_date_index;
    const chat_date = data[resIndex].chat_dates[chat_index];

    return chat_date ? true : false;
  } else {
    return false;
  }
}

export function getUnreadMessagesUsersCount(openedChats) {
  const ls = localStorage.getItem('usersData');
  const users = ls !== null ? JSON.parse(ls) : [];
  const unreadUsers = [];
  let sum = 0;
  users.forEach(item => {
    let assigned = assignedByAgent(item.uid, openedChats, parseInt(localStorage.getItem('uid')));
    if (assigned !== 'Unassigned' && assigned.length > 0 && assigned[1] ===  parseInt(localStorage.getItem('uid'))) {
      if (!unreadUsers[item.uid] && item.count > 0) {
        sum = sum + 1;
      } else {
        unreadUsers[item.uid] = 1;
      }
    } else if (assigned === 'Unassigned') {
      if (!unreadUsers[item.uid] && item.count > 0) {
        sum = sum + 1;
      } else {
        unreadUsers[item.uid] = 1;
      }
    }
  });
  return sum;
}