import React from 'react';
import { Grid, Typography} from '@material-ui/core';
import logo from './images/logo.svg';
import sonar from './images/sonar.png';
import oval from './images/oval.svg';
import BackgroundImage from './images/background.png';
import WrapperContent from '../../components/WrapperContent';
import Layout from '../../components/Layout';
import './styles.css';


export default class Spinner extends React.Component {
  
  componentDidMount(){
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundImage = `url(${BackgroundImage})`;
    body.style.backgroundSize = "100%";

  }

  componentWillUnmount(){
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundImage = "";
    body.style.backgroundSize = "";
  }


  render() {
    return (
      <WrapperContent>
        <Layout page="load">
          <Grid className="loader">
            <img className="loader-logo" src={logo} alt="logo"/>
            <img className="loader-oval" src={oval} alt="oval"/>
            <img className="loader-sonar" src={sonar} alt="sonar"/>
            <Grid className="loader-text">
              <Typography>Biiz</Typography>
              <Typography>by Piip</Typography>
            </Grid>
          </Grid>
        </Layout>
      </WrapperContent>
    );
  }
}

