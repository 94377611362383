import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { hideError } from '../../store/common/actions';
import { hideErrorAuth } from '../../store/auth/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DialogActions, DialogTitle, Button, DialogContent, DialogContentText, Dialog, Grid } from '@material-ui/core';

class ErrorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsgAuth: "asdsad",
			errorMsg: "asdasdasdsdad"
		}
	}

	// componentDidUpdate(){
  //   if (this.props.errorMsg !== undefined) this.setState({errorMsg: this.props.errorMsg});
  //   if (this.props.errorMsgAuth !== undefined) this.setState({errorMsgAuth: this.props.errorMsgAuth});
  // }

  handleClose = () => {
    this.props.hideError();
    this.props.hideErrorAuth();
  };
	
	render() {

		let err = (this.state.errorMsg !== undefined) ? this.state.errorMsg : "";
    let errAuth = (this.state.errorMsgAuth !== undefined) ? this.state.errorMsgAuth : "";

		return (
			<Grid>
				<Dialog
					style={{textAlign: 'center'}}
          open={((this.props.errorMsg && this.props.errorMsg !== '') || (this.props.errorMsgAuth && this.props.errorMsgAuth !== '')) ? true : false}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"-- ERROR --"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<strong>{err} {errAuth}</strong>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		)
	}
}

ErrorModal.propTypes = {
  hideError: PropTypes.func,
  hideErrorAuth: PropTypes.func,
  errorMsg: PropTypes.string,
  errorMsgAuth: PropTypes.string
};

export default connect(({common, auth}) => {
  return {
    errorMsg: (common.errorMsg !== undefined) ? common.errorMsg : "",
    errorMsgAuth: (auth.errorMsg !== undefined) ? auth.errorMsg : "",
  }
}, (dispatch) => {
	const actions = { hideError, hideErrorAuth};
	return bindActionCreators(actions, dispatch)
})(ErrorModal);