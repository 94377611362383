import React, { Component, Fragment } from 'react';
import GoogleMapReact from 'google-map-react';
import SearchBox from './SearchBox';
import Marker from './Marker';

export default class Map extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      center: {lat: this.props.location.latitude, lng: this.props.location.longitude},
      zoom: 15,
      minZoom: 2,
      selectedCenter: {lat: this.props.location.latitude, lng: this.props.location.longitude},
      googleInit: false,
			maps: null,
			map: null,
			radius: 0,
      places: [],
      mapApiLoaded: false,
      circle: null
    };
  }
  
  
  componentDidUpdate(prevProps){
    const { circle } = this.state;
    if (prevProps.location.latitude !== this.props.location.latitude || prevProps.location.longitude !== this.props.location.longitude) {
      if (circle && !this.props.save) {
        circle.setCenter({lat: this.props.location.latitude, lng: this.props.location.longitude})
      }
      if (this.props.cancel) {
        this.setState({
          selectedCenter: {lat: this.props.location.latitude, lng: this.props.location.longitude}
        })
      }
    }
		if (prevProps.radius !== this.props.radius) {
      if (circle) {
        circle.setRadius(this.props.radius * 1000)
      }
			this.setState({radius: this.props.radius * 1000, circle})
		}
  }

  addPlace = (place) => {
    this.setState({ places: place });
  };

  handleGoogleMapApi(google) {
    this.props.onUpdateGoogleObject(google);
    let circle = new google.maps.Circle({
      zIndex: 1,
      strokeColor: 'green',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#7BEA7F',
      fillOpacity: 0.3,
      map: google.map,
      center: this.state.center,
      radius: this.state.radius
    })
    this.setState({
      circle,
    })
    google.map.addListener('click', function(e) {
      circle.setCenter(e.latLng)
    });
    
		this.setState({map: google.map, maps: google.maps, mapApiLoaded: true,})
  }

  _onClick = ({x, y, lat, lng, event}) => {

    const that = this;
    Promise.resolve(that._setLocation('selected', lat, lng, undefined)) // dispatch
    .then(function (response) {
      let google = that.props.googleObject;
      let geocoder = new google.maps.Geocoder;

      geocoder.geocode({'location': that.state.selectedCenter}, function(results, status) {
        that._setLocation('selected', lat, lng, results);
      });
      return response;
    });
  };

  _setLocation = (obj, lat, lng, address) => {
    if(obj === "selected"){
      if(lat && lng) {
        this.setState({
          selectedCenter: {
            lat: lat,
            lng: lng
          }
        });
      }
      let addressTemp = address !== undefined && address.length > 0 ? address[0] : undefined;
      this.props.onLocationChanged([
      {
        "longitude": lng,
        "latitude" : lat,
      },
      addressTemp
      ]);
    }
  };

  onChange = () => {

  }

  createMapOptions = () => {
    return {
      fullscreenControl: false,
      zoomControl: false
    }
  }

  render() {
    
    return (
      <Fragment>
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMapReact
            onClick={this._onClick}
            onChange={this.onChange}
            center={this.state.center}
            zoom={this.state.zoom}
            options={this.createMapOptions}
            bootstrapURLKeys={{
              key: process.env.GOOGLE_API_KEY,
              libraries: 'geometry,places',
            }}
            onGoogleApiLoaded={this.handleGoogleMapApi.bind(this)}
            yesIWantToUseGoogleMapApiInternals
          >
            {this.state.mapApiLoaded && <SearchBox map={this.state.map} mapApi={this.state.maps} addplace={this.addPlace} />}
            <Marker
              draggable={true}
              lat={this.state.selectedCenter.lat}
              lng={this.state.selectedCenter.lng}
              onclick={this._onClick}
            />
          </GoogleMapReact>
        </div>
      </Fragment>
    );
  }
}