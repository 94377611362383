import { DialogTitle, withStyles } from '@material-ui/core';

export const ColorDialog = withStyles(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--title-strong-color)',
    display: 'flex',
    fontSize: '20px',
    borderBottom: 'var(--border)', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    paddingBottom: '0px'
  },
}))(DialogTitle);

export const StyledDialog = withStyles({
  root: {
    fontSize: '25px', 
    color: 'var(--primary-color)', 
    paddingLeft: 0
  }
})(DialogTitle)