export const REGISTERWEB = 'REGISTERWEB'
export const REGISTERWEB_RESULT = 'REGISTERWEB_RESULT'
export const REGISTERWEB_ERROR = 'REGISTERWEB_ERROR'
export const CONNECTWEB = 'CONNECTWEB'
export const CONNECTWEB_RESULT = 'CONNECTWEB_RESULT'
export const CONNECTWEB_ERROR = 'CONNECTWEB_ERROR'
export const CONNECTMOBILE = 'CONNECTMOBILE'
export const CONNECTMOBILE_RESULT = 'CONNECTMOBILE_RESULT'
export const CONNECTMOBILE_ERROR = 'CONNECTMOBILE_ERROR'
export const CONNECT_RESULT = 'CONNECT_RESULT'
export const CONNECT_ERROR = 'CONNECT_ERROR'
export const SETAUTHORIZATION = 'SETAUTHORIZATION'
export const SETPREVIOUSPATH = 'SETPREVIOUSPATH'
export const SETACTIVEPAGE = 'SETACTIVEPAGE'
export const VERIFYWEB = 'VERIFYWEB'
export const VERIFYWEB_RESULT = 'VERIFYWEB_RESULT'
export const VERIFYWEB_ERROR = 'VERIFYWEB_ERROR'
export const RESETATTACHMENT = 'RESETATTACHMENT'
export const HIDE_ERROR_AUTH = 'HIDE_ERROR_AUTH'
export const VERIFYADMIN = 'VERIFYADMIN'
export const LOGINAGENT = 'LOGINAGENT'
export const UPLOADCOMPLETE = 'UPLOADCOMPLETE'
export const HIDENOTEMESSAGE = 'HIDENOTEMESSAGE'
export const LOADER = 'LOADER'
