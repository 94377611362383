import React from 'react';
// import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../../store/index';
import Layout from '../../components/Layout';
import Logo from './images/logo.svg';
import { Typography } from '@material-ui/core';
import User from '@material-ui/icons/PersonOutlineOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import WrapperContent from '../../components/WrapperContent';
import './styles.css';
import { getMerchantCard } from '../../store/common/actions';
import { getUnreadMessagesUsersCount } from '../../lib/utils';

class Dashboard extends React.Component {

	componentDidMount() {
		this.props.getMerchantCard()
	}

	handleCircle = (path) => {
		history.push(path)
	}

	render() {
		const { merchantInfo } = this.props;
		const count = getUnreadMessagesUsersCount(this.props.openedChats);
    let cnt = 0;
    if (count !== 0) {
      cnt = count;
    }
		return (
			<WrapperContent header>
				<Layout>
					<div className="main main--dashboard">
						<div className="dash-menu">
							<div className="cutout">
								<div className="circle-logo">
								<img src={ Logo } alt="circleLogo"/>
								</div>
							</div>

							<div className="quarter quarter1" onClick={this.handleCircle.bind(this, '/biizCard')}>
								<Typography>My Biiz <br /> Card</Typography>
							</div>
							<div className="desc desc--bizz">
								<div className="icons">
									<User />
									<MenuOutlinedIcon />
								</div>
								<Typography>Edit your Biiz <br /> Card settings</Typography>
							</div>
							<div className="quarter quarter2" onClick={this.handleCircle.bind(this, '/promos')}>
								{/* <Typography>{merchantInfo ? merchantInfo.enable_sub_promos ? 'Promos & \n Order Items' : 'Promos' : 'Promos'}</Typography> */}
								<p>{merchantInfo ? merchantInfo.enable_sub_promos ? 'Promos &' : 'Promos' : 'Promos'}</p>
								<p>{merchantInfo ? merchantInfo.enable_sub_promos ? 'Order Items' : '' : ''}</p>
							</div>
							<div className="desc desc--promos">
								<div className="icons">
									<InsertChartOutlinedOutlinedIcon />
								</div>
								<Typography>Promote Your <br /> Bussiness</Typography>
							</div>
							<div className="quarter quarter3" onClick={this.handleCircle.bind(this, '/settings')}>
								<Typography>Settings</Typography>
							</div>
							<div className="desc desc--settings">
								<div className="icons">
									<SettingsOutlinedIcon />
								</div>
								<Typography>Adjust All <br /> Settings in detail</Typography>
							</div>
							<div className="quarter quarter4" onClick={this.handleCircle.bind(this, '/messages')}>
								<Typography>Messages</Typography>
							</div>
							<div className="desc desc--messages">
								<div className="icons">
									<ChatOutlinedIcon />
								</div>
								<Typography>Contact Your <br /> Customer</Typography>
							</div>
							<div className="notification-circle">
								<span>{cnt}</span>
							</div>
						</div>
					</div>
				</Layout>
			</WrapperContent>
		)
	}
}

Dashboard.propTypes = {
};

export default connect(({messages}) => {
  return {
    openedChats: messages.openedChats,
  }
}, (dispatch) => {
  const actions = {getMerchantCard};
  return bindActionCreators(actions, dispatch);
})(Dashboard);
