import {
	HIDE_ERROR,
	USECLIPBOARD,
	GETSTORECATEGORIES,
	GETSTORECATEGORIES_RESULT,
	GETSTORECATEGORIES_ERROR,
	GETMERCHANTCARD2,
	GETMERCHANTCARD2_RESULT,
	GETMERCHANTCARD2_ERROR,
	GETMERCHANTMAXDISTANCE,
	GETMERCHANTMAXDISTANCE_RESULT,
	GETMERCHANTMAXDISTANCE_ERROR,
	UPLOAD_ERROR,
	BEGINUPLOAD,
	BEGINUPLOAD_RESULT,
	BEGINUPLOAD_ERROR,
	ADDMERCHANTMAXDISTANCE,
	ADDMERCHANTMAXDISTANCE_RESULT,
	ADDMERCHANTMAXDISTANCE_ERROR,
	ADDMERCHANTCARD,
	ADDMERCHANTCARD_RESULT,
	ADDMERCHANTCARD_ERROR,
  GETPAYMENTS,
  GETPAYMENTS_RESULT,
  GETPAYMENTS_ERROR,
  VERIFYEMAIL,
  VERIFYEMAIL_RESULT,
  VERIFYEMAIL_ERROR,
  GETEMAILVERIFICATIONSTATUS,
  GETEMAILVERIFICATIONSTATUS_RESULT,
  GETEMAILVERIFICATIONSTATUS_ERROR,
  GETCONTACTSWEB,
  GETCONTACTSWEB_RESULT,
  GETCONTACTSWEB_ERROR,
  GETAGENTS,
  GETAGENTS_RESULT,
  GETAGENTS_ERROR,
  GETGENERALAGENTS,
  GETGENERALAGENTS_RESULT,
  GETGENERALAGENTS_ERROR,
  GETTELLERS,
  GETTELLERS_RESULT,
  GETTELLERS_ERROR,
  UPDATEEMAIL,
  UPDATEEMAIL_RESULT,
  UPDATEEMAIL_ERROR,
  GETAUTOREPLYMESSAGE,
  GETAUTOREPLYMESSAGE_RESULT,
  GETAUTOREPLYMESSAGE_ERROR,
  ADDAUTOREPLYMESSAGE,
  ADDAUTOREPLYMESSAGE_RESULT,
  ADDAUTOREPLYMESSAGE_ERROR,
  LISTMERCHANTCANNEDIMAGES,
  LISTMERCHANTCANNEDIMAGES_RESULT,
  LISTMERCHANTCANNEDIMAGES_ERROR,
  ADDMERCHANTCANNEDIMAGE,
  ADDMERCHANTCANNEDIMAGE_RESULT,
  ADDMERCHANTCANNEDIMAGE_ERROR,
  EDITMERCHANTCANNEDIMAGECAPTION,
  EDITMERCHANTCANNEDIMAGECAPTION_RESULT,
  EDITMERCHANTCANNEDIMAGECAPTION_ERROR,
  DELETEMERCHANTCANNEDIMAGE,
  DELETEMERCHANTCANNEDIMAGE_RESULT,
  DELETEMERCHANTCANNEDIMAGE_ERROR,
  LISTMERCHANTCANNEDANSWERS,
  LISTMERCHANTCANNEDANSWERS_RESULT,
  LISTMERCHANTCANNEDANSWERS_ERROR,
  ADDMERCHANTCANNEDANSWER,
  ADDMERCHANTCANNEDANSWER_RESULT,
  ADDMERCHANTCANNEDANSWER_ERROR,
  DELETEMERCHANTCANNEDANSWER,
  DELETEMERCHANTCANNEDANSWER_RESULT,
  DELETEMERCHANTCANNEDANSWER_ERROR,
  EDITMERCHANTCANNEDANSWER,
  EDITMERCHANTCANNEDANSWER_RESULT,
  EDITMERCHANTCANNEDANSWER_ERROR,
  TOGGLECONTACTBLOCK,
  TOGGLECONTACTBLOCK_RESULT,
  TOGGLECONTACTBLOCK_ERROR,
  REGISTERAGENT,
  REGISTERAGENT_RESULT,
  REGISTERAGENT_ERROR,
  EDITQRCODE,
  EDITQRCODE_RESULT,
  EDITQRCODE_ERROR,
  EDITAGENT,
  EDITAGENT_RESULT,
  EDITAGENT_ERROR,
  GETAGENTINFO,
  GETAGENTINFO_RESULT,
  GETAGENTINFO_ERROR,
  GETGENERALAGENTINFO,
  GETGENERALAGENTINFO_RESULT,
  GETGENERALAGENTINFO_ERROR,
  USERCHANGEPROFILE,
  USERCHANGEPROFILE_RESULT,
  USERCHANGEPROFILE_ERROR,
  SETFLAG,
  REFRESHNAVIGATION
} from './constants';
import { sendMessage, startUpload } from '../comm/sender';
import { fixMerchantCardResult, str2ab, saveContactsInfo } from '../../lib/utils';
import errorsLib from '../../lib/errorsLib';
import moment from 'moment';

let initialState = {
	errorMsg: '',
	useClipboard: false,
	merchantInfo: null,
	merchantCategories: {},
	maxDistance: null,
	spinner: false,
  emailVerificationStatus: {},
  autoReply: [],
  cannedAnswers: [],
  cannedImages: [],
  agentInfo: [],
  generalAgentInfo: [],
  userChangeProfile: false,
  contactsInfo: []
}


export default (state = initialState, action) => {
	switch (action.type) {
		case HIDE_ERROR:
      return { ...state, errorMsg: '' };
		case USECLIPBOARD:
      return { ...state, useClipboard: action.params };
    case GETSTORECATEGORIES:
      sendMessage('getStoreCategories', ['english']);
			return {...state}
    case GETSTORECATEGORIES_RESULT:
      _log(action, state);
      return Object.assign({}, state, {
        merchantCategories: action.params.params[0]
      });
    case GETSTORECATEGORIES_ERROR:
      return{...state}
		case GETMERCHANTCARD2:
      if (typeof action.params === 'number') {
        sendMessage('getMerchantCard2', [action.params]);
      } else {
        sendMessage('getMerchantCard2', []);
      }
      return{...state}
    case GETMERCHANTCARD2_RESULT: {
      _log(action, state);
			let merchantCardResult = null;
			if (action.params) {
				merchantCardResult = action.params.params[0];
				merchantCardResult = fixMerchantCardResult(merchantCardResult);
				return Object.assign({}, state, {
					merchantInfo: merchantCardResult
				});
			} else {
				return { ...state }
			}
    }
    case GETMERCHANTCARD2_ERROR:
			return { ...state }
    case GETMERCHANTMAXDISTANCE:
      sendMessage('getMerchantMaxDistance');
			return { ...state }
    case GETMERCHANTMAXDISTANCE_RESULT: {
			let maxDistance = null
      _log(action, state);
			if (action.params) {
				maxDistance = action.params.params[0][0].max_distance;
				if (maxDistance) {
					maxDistance.toString();
				}
				return { ...state, maxDistance: maxDistance };
			} else {
				return { ...state }
			}
    }
    case GETMERCHANTMAXDISTANCE_ERROR:
      break;
    case UPLOAD_ERROR: {
      let upload_error = errorsLib('upload_default', []);
      switch (action.params[0]) {
        case 0:
          upload_error = errorsLib('upload_prevent', [action.params[1]]);
          break;
        case 1:
          upload_error = errorsLib('upload_default');
          break;
      }
      return { ...state, errorMsg: upload_error };
    }
    case BEGINUPLOAD:
      sendMessage('beginUpload', action.params);
      return{...state}
		case BEGINUPLOAD_RESULT: {
      let array_buffer = str2ab(localStorage.getItem('fileToUpload'));

      if (array_buffer) {
        startUpload(array_buffer);
      }
      return {...state, spinner:false}
    }
    case BEGINUPLOAD_ERROR:
      return {...state, spinner:false}
    case ADDMERCHANTMAXDISTANCE:
      sendMessage('setMerchantMaxDistance', action.params);
			return {...state}
    case ADDMERCHANTMAXDISTANCE_RESULT:
      _log(action, state);
      break;
    case ADDMERCHANTMAXDISTANCE_ERROR:
      break;
    case ADDMERCHANTCARD:
      sendMessage('addMerchantCard', action.params);
      return {...state}
    case ADDMERCHANTCARD_RESULT:
      localStorage.setItem('merchantCard', true);
      return {...state}
    case ADDMERCHANTCARD_ERROR:
      return null
    case GETPAYMENTS:
      sendMessage('getPayments', []);
      return{...state}
    case GETPAYMENTS_RESULT:
      if(action.params) {
        return { ...state, merchantPayment: action.params.params };
      }
      break;
    case GETPAYMENTS_ERROR:
      return{...state}
    case UPDATEEMAIL:
      sendMessage('updateEmail', action.params);
      return{...state}
    case UPDATEEMAIL_RESULT:
      return{...state}
    case UPDATEEMAIL_ERROR: {
      let verify_email = errorsLib('verify_email');
      return { ...state, errorMsg: verify_email };
    }
    case VERIFYEMAIL:
      sendMessage('verifyEmail', action.params);
      return { ...state, verifyEmailStatus: true };
    case VERIFYEMAIL_RESULT:
      if (action.params.params[1] === undefined || action.params.params[1] === 0) {
        return { ...state, verifyEmailStatus: false };
      }
      sendMessage('getEmailVerificationStatus', []);
      return{...state}
    case VERIFYEMAIL_ERROR: {
      const update_email = errorsLib('update_email');
      return { ...state, errorMsg: update_email };
    }
    case GETEMAILVERIFICATIONSTATUS:
      sendMessage('getEmailVerificationStatus', []);
      return{...state}
    case GETEMAILVERIFICATIONSTATUS_RESULT: {
      _log(action, state);
      const emailVerificationStatusResult = action.params.params;
      return Object.assign({}, state, {
        emailVerificationStatus: emailVerificationStatusResult
      });
    }
    case GETEMAILVERIFICATIONSTATUS_ERROR:
      return { ...state };
		case GETCONTACTSWEB:
      sendMessage('getContactsWeb', []);
      return{...state}
		case GETCONTACTSWEB_RESULT: {
      //save to localStorage
      const contacts = action.params.params[0];
      saveContactsInfo(contacts)
      return { ...state, contactsInfo: action.params.params[0] };
      
      // return{...state, contactsInfo: action.params.params[0]}
    }
    case GETCONTACTSWEB_ERROR:
      return{...state}
    case GETAGENTS:
      sendMessage('getAgents');
      return{...state}
    case GETAGENTS_RESULT:
      return {...state, savedAgents: action.params.params[0]}
    case GETAGENTS_ERROR:
      return{...state}
    case GETGENERALAGENTS:
      sendMessage('getGeneralAgents', action.params);
      break;
    case GETGENERALAGENTS_RESULT:
      return {...state, savedGeneralAgents: action.params.params[0]}
    case GETGENERALAGENTS_ERROR:
      break;
		case GETTELLERS:
      sendMessage('getTellers', action.params);
      break;
		case GETTELLERS_RESULT:
      return {...state, savedTellers: action.params.params[0]}
    case GETTELLERS_ERROR:
			break;
    case GETAUTOREPLYMESSAGE:
      sendMessage('getAutoReplyMessage', [action.params])
      return{...state}
    case GETAUTOREPLYMESSAGE_RESULT:
      return {...state, autoReply: action.params.params[0]}
    case GETAUTOREPLYMESSAGE_ERROR:
      return{...state}
    case ADDAUTOREPLYMESSAGE:
      sendMessage('addAutoReplyMessage', [action.params])
      return{...state}
    case ADDAUTOREPLYMESSAGE_RESULT:
      sendMessage('getAutoReplyMessage', [action.params])
      return{...state}
    case ADDAUTOREPLYMESSAGE_ERROR:
      return{...state}
		case LISTMERCHANTCANNEDIMAGES: {
			const cannedImagesParams = action.params;
			if (typeof cannedImagesParams === 'number') {
				sendMessage('listMerchantCannedImages', [action.params])
			} else {
				sendMessage('listMerchantCannedImages')
			}
      return{...state}
    }
    case LISTMERCHANTCANNEDIMAGES_RESULT:
      return {...state, cannedImages: action.params.params[0]}
    case LISTMERCHANTCANNEDIMAGES_ERROR:
      return{...state}
    case ADDMERCHANTCANNEDIMAGE:
      sendMessage('addMerchantCannedImage', [action.params])
      return{...state}
    case ADDMERCHANTCANNEDIMAGE_RESULT:
      sendMessage('listMerchantCannedImages')
      return{...state}
    case ADDMERCHANTCANNEDIMAGE_ERROR:
      return{...state}
    case EDITMERCHANTCANNEDIMAGECAPTION:
      sendMessage('editMerchantCannedImageCaption', [action.params])
      return{...state}
    case EDITMERCHANTCANNEDIMAGECAPTION_RESULT:
      sendMessage('listMerchantCannedImages')
      return{...state}
    case EDITMERCHANTCANNEDIMAGECAPTION_ERROR:
      return{...state}
    case DELETEMERCHANTCANNEDIMAGE:
      sendMessage('deleteMerchantCannedImage', [action.params])
      return{...state}
    case DELETEMERCHANTCANNEDIMAGE_RESULT:
      sendMessage('listMerchantCannedImages')
      return{...state}
    case DELETEMERCHANTCANNEDIMAGE_ERROR:
      return{...state}
		case LISTMERCHANTCANNEDANSWERS: {
			const cannedAnswerParams = action.params;
			if (typeof cannedAnswerParams === 'number') {
				sendMessage('listMerchantCannedAnswers', [action.params])
			} else {
				sendMessage('listMerchantCannedAnswers')
			}
      return{...state}
    }
		case LISTMERCHANTCANNEDANSWERS_RESULT:
      return {...state, cannedAnswers: action.params.params[0]}
    case LISTMERCHANTCANNEDANSWERS_ERROR:
      return{...state}
    case ADDMERCHANTCANNEDANSWER:
      sendMessage('addMerchantCannedAnswer', [action.params])
      return{...state}
    case ADDMERCHANTCANNEDANSWER_RESULT:
      sendMessage('listMerchantCannedAnswers')
      return{...state}
    case ADDMERCHANTCANNEDANSWER_ERROR:
      return{...state}
    case DELETEMERCHANTCANNEDANSWER:
      sendMessage('deleteMerchantCannedAnswer', [action.params])
      return{...state}
    case DELETEMERCHANTCANNEDANSWER_RESULT:
      sendMessage('listMerchantCannedAnswers')
      return{...state}
    case DELETEMERCHANTCANNEDANSWER_ERROR:
      return{...state}
    case EDITMERCHANTCANNEDANSWER:
      sendMessage('editMerchantCannedAnswer', [action.params])
      return{...state}
    case EDITMERCHANTCANNEDANSWER_RESULT:
      sendMessage('listMerchantCannedAnswers')
      return{...state}
    case EDITMERCHANTCANNEDANSWER_ERROR:
      return{...state}
    case TOGGLECONTACTBLOCK:
      sendMessage('toggleContactBlock', action.params);
      return{...state}
    case TOGGLECONTACTBLOCK_RESULT:
      return{...state}
    case TOGGLECONTACTBLOCK_ERROR:
      return{...state}
    case REGISTERAGENT:
      sendMessage('registerAgent', action.params);
      return{...state}
    case REGISTERAGENT_RESULT:
      return{...state}
    case REGISTERAGENT_ERROR:
      return{...state}
		case EDITQRCODE:
      sendMessage('editQrCode', action.params)
      return{...state}
		case EDITQRCODE_RESULT:
      if (state.merchantInfo.uid === 0) {
        sendMessage('getGeneralAgents', [null]);
      }
      sendMessage('getAgents')
      return{...state}
    case EDITQRCODE_ERROR:
      if (state.merchantInfo.uid === 0) {
        sendMessage('getGeneralAgents', [null]);
      }
      sendMessage('getAgents')
      return{...state}
		case EDITAGENT:
      sendMessage('editAgent', action.params)
      return{...state}
    case EDITAGENT_RESULT:
      sendMessage('getAgents')
      return{...state}
    case EDITAGENT_ERROR:
      return{...state}
		case GETAGENTINFO:
      sendMessage('getAgentInfo', action.params)
      return { ...state }
		case GETAGENTINFO_RESULT:
			return { ...state, agentInfo:  action.params.params[0][0] };
    case GETAGENTINFO_ERROR:
			return { ...state }
		case GETGENERALAGENTINFO:
      sendMessage('getGeneralAgentInfo', action.params)
			return { ...state }
		case GETGENERALAGENTINFO_RESULT:
			return { ...state, generalAgentInfo:  action.params.params[0][0] };
    case GETGENERALAGENTINFO_ERROR:
			return { ...state }
    case USERCHANGEPROFILE:
      return { ...state, userChangeProfile: action.params };
    case USERCHANGEPROFILE_RESULT:
			return { ...state }
    case USERCHANGEPROFILE_ERROR:
			return { ...state }
		case SETFLAG: {
      _log(action, state);
      return { ...state, [action.params.key]: action.params.value };
    }
    case REFRESHNAVIGATION:
      return { ...state, refreshNavigation: moment() };
		default: 
			return { ...state }
	}
}

function _log(action, state) {
  if (!process.env.debug) return;
  let css = 'color: #DBC144';
  if (action.type.indexOf('_RESULT') >= 0) {
    css = 'color: green';
  } else if (action.type.indexOf('_ERROR') >= 0) {
    css = 'color: red';
  }
  console.log('>>>>>>> %c' + action.type + '  ---', css);
  console.log('params: ', action.params);
  console.log('state: ', state);
  console.log('------------');
}