import { 
  REGISTERWEB,
  REGISTERWEB_RESULT,
  REGISTERWEB_ERROR,
  VERIFYWEB,
  VERIFYWEB_RESULT,
  VERIFYWEB_ERROR,
  VERIFYADMIN,
  CONNECTWEB,
  CONNECTWEB_RESULT,
  CONNECTWEB_ERROR,
  LOGINAGENT,
  HIDE_ERROR_AUTH,
  SETPREVIOUSPATH,
  SETACTIVEPAGE,
  SETAUTHORIZATION,
  CONNECT_RESULT,
  CONNECT_ERROR,
  UPLOADCOMPLETE,
  LOADER,
  RESETATTACHMENT
} from './constants';
import { sendMessage } from '../comm/sender';
import errorsLib from '../../lib/errorsLib';
import { getGeneralAgentChunk } from '../../lib/utils';

let token = '';
let uid = '';
let userType = parseInt(process.env.USER_TYPE);
let appVersion = parseInt(process.env.APP_VERSION);
if (localStorage.getItem('token')) token = localStorage.getItem('token');
if (localStorage.getItem('uid')) uid = parseInt(localStorage.getItem('uid'));
if (localStorage.getItem('userType')) userType = parseInt(localStorage.getItem('userType'))

let initialState = {
    token: token,
    uid: uid,
		userType: userType,
    appVersion: appVersion,
    isRegistered: false,
    isAuthorized: false,
    isValidToken: true,
    previousPath: '',
    activePage: 'promos',
    phoneNumber: '',
    codeConfirmationStarted: false,
    codeConfirmed: false,
    code: '',
    valid_until: '',
    mobile_token: '',
    gcm_token: '',
    attachment: null,
    imageMessage: false,
    openSnackbar: false,
    messageSnackbar: '',
		spinner: false,
		merchantUidTyping: null,
		registerError: '',
    errorMsg: '',
    initLoading: true,
}


export default (state = initialState, action) => {
	switch (action.type) {
    case SETAUTHORIZATION:
      return { ...state, isAuthorized: action.params };
		case REGISTERWEB:
      sendMessage('registerWeb', action.params);
      return { ...state, phoneNumber: action.params[0], codeConfirmationStarted: false, registerError: '' };
    case REGISTERWEB_RESULT:
      if (action.params.params[0] !== null) {
        return Object.assign({}, state, {
          uid: parseInt(action.params.params[0]),
          codeConfirmationStarted: true,
          registerError: ''
        });
      } else {
        let register_error = errorsLib('register_default');
        return Object.assign({}, state, {
          registerError: register_error
        });
      }
    case REGISTERWEB_ERROR:
      if (action.params.params[0] === 6) {
        return { ...state, errorMsg: action.params.params[1] };
      }
      break;
    case VERIFYWEB:
      sendMessage('verifyWeb', action.params);
      return { ...state, uid: action.params[0], code: action.params[1], verifyError: '' };
    case VERIFYWEB_RESULT:
      if (action.params.params[1] === 1) {
        localStorage.setItem('uid', parseInt(action.params.params[0]));
        localStorage.setItem('token', action.params.params[2]);
        return Object.assign({}, state, {
          isRegistered: true,
          token: action.params.params[2],
          verifyError: ''
        });
      } else {
        const verify_error = errorsLib('verify_default');
        return { ...state, verifyError: verify_error };
      }
    case VERIFYWEB_ERROR:
      if (action.params.params[0] === 6) {
        return { ...state, errorMsg: action.params.params[1] };
      }
      break;
    case CONNECTWEB:
      if (!state.isAuthorized && state.userType === 2)	{// old !state.isRegistered || !state.isAuthorized
				sendMessage('connectWeb', action.params);
      }
      return { ...state }
    case CONNECTWEB_RESULT:
			//sendMessage('startPresence', []);
      if (!state.isAuthorized) {
        //sendMessage('clientReady', []);
        sendMessage('getSubPromotions', [null]);
        sendMessage('getMerchantCard2', []);
        sendMessage('getContactsWeb', []);
        sendMessage('getPromotions', [null, 0]);
        sendMessage('getPromotions', [null, 2]);
        sendMessage('getAgents');
        sendMessage('listMerchantCannedImages');
        sendMessage('listMerchantCannedAnswers');
        sendMessage('getOpenedChats');
        sendMessage('getStoreCategories', ['english']);
			}
			if (!state.isAuthorized && state.uid === 0) {
				sendMessage('getGeneralAgents', [null]);
				sendMessage('getTellers', [null]);
			}
      return Object.assign({}, state, {
        isAuthorized: true,
        isValidToken: true,
        initLoading: false
      });
    case CONNECTWEB_ERROR: {
      const obj = {initLoading: false};
      if (action.params.params[0] === 6) {
        return { ...state, errorMsg: action.params.params[1], ...obj };
      } else if (action.params.params[0] === 8 || action.params.params[0] === 7) {
        return Object.assign({}, state, {
          isValidToken: false,
        }, obj);
        //return {...state, errorMsg: action.params.params[1]};
      } else {
        return { ...state, ...obj }
      }
    }
    case CONNECT_RESULT:
      if (!state.isAuthorized && action.params.request.params[3] !== 4) {
        //sendMessage('clientReady', []);
        sendMessage('getSubPromotions', [null]);
        sendMessage('getMerchantCard2', []);
        sendMessage('getContactsWeb', []);
        sendMessage('getPromotions', [null, 2]);
        sendMessage('getAgents');
        sendMessage('getOpenedChats');
      } else if (!state.isAuthorized && action.params.request.params[3] === 4) {
				getGeneralAgentChunk();
				sendMessage('getOpenedChats');
			}
      return Object.assign({}, state, {
        userType: action.params.request.params[3],
        isAuthorized: true,
        isValidToken: true,
        uid: parseInt(localStorage.getItem('uid'))
      });
    case CONNECT_ERROR:
      if (action.params.params[0] === 6) {
        return { ...state, errorMsg: action.params.params[1] };
      } else if (action.params.params[0] === 8 || action.params.params[0] === 7) {
        return Object.assign({}, state, {
          isValidToken: false,
          errorMsg: action.params.params[1]
        });
      }
      return{...state}
    case LOGINAGENT:
      sendMessage('connect', action.params);
      return{...state}
    case VERIFYADMIN:
      return Object.assign({}, state, {
        isRegistered: true,
        token: action.params[0],
        uid: action.params[1],
        verifyError: ''
      });
    case HIDE_ERROR_AUTH:
      return { ...state, errorMsg: '' };
    case SETACTIVEPAGE:
      if (action.params !== 'register') {
        return { ...state, activePage: action.params };
      } else {
        return { ...state, activePage: action.params, isAuthorized: false };
      }
    case SETPREVIOUSPATH:
      return { ...state, previousPath: action.params };
    case UPLOADCOMPLETE:
      sendMessage('getSubPromotions', [null]);
      return { ...state, attachment: action.params[0] }
    case LOADER:
      return { ...state, initLoading: action.params }
    case RESETATTACHMENT:
      return { ...state, attachment: null, imageMessage: false };


		default: 
		return state
	}
}