import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	Grid,
	Paper,
	Tabs,
	Tab,
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	Divider,
	List,
	ListItem,
	CardActions,
	Card,
	CardMedia,
	TextField
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, Delete as DeleteIcon, Edit as EditIcon }  from '@material-ui/icons/';
import Dropzone from 'react-dropzone';
import Cropper from 'react-image-crop';
import { getRealMimeType, isReadonly, str2ab }from '../../lib/utils';
import './styles.css';
import {
	listMerchantCannedAnswers,
	listMerchantCannedImages,
	addMerchantCannedAnswer,
	deleteMerchantCannedAnswer,
	editMerchantCannedAnswer,
	uploadError,
	beginUpload,
	deleteMerchantCannedImage,
	editMerchantCannedImageCaption
} from '../../store/common/actions';

const maxLetters = 4096;
const maxEditLetters = 4096;
const maxCaptionLetters = 4096;

class ManageTemplates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabsValue: 0,
			cannedAnswers: [],
			cannedAnswerEdit: false,
			cannedAnswerEditData: null,
			cannedDialogShow: false,
			newCannedAnswer: '',
			cannedImages: [],
			deleteImage: '',
			cannedImagesShow: false,
			cannedAnswerError: false,
			cannedAnswerErrorMsg: '',
			lettersCounter: maxLetters,
      lettersEditCounter: maxEditLetters,
      lettersCaptionCounter: maxCaptionLetters,
			deleteId: null,
			openDelete: false,
			crop: {
				unit: '%',
				x: 10,
				y: 30,
				width: 100,
				height: 100,
			},
			openCrop: false,
			storeImage: '',
			cropedRes: {},
		}
	}

	componentDidMount() {
		if (this.props.userType !== 4) {
			this.props.listMerchantCannedAnswers()
			this.props.listMerchantCannedImages()
		}
  }

	componentDidUpdate(prevProps) {
		if (prevProps.cannedImages !== this.props.cannedImages) {
			this.setState({
				cannedImages: this.props.cannedImages
			})
		}
		if (prevProps.cannedAnswers !== this.props.cannedAnswers) {
			this.setState({
				cannedAnswers: this.props.cannedAnswers
			})
		}
	}

	handleChangeTab = (e, newValue) => {
		this.setState({
			tabsValue: newValue
		})
	}

	handleOpenCannedAnswer = () => {
		this.setState({
			cannedDialogShow: true
		})
	}

	handleCloseCannedAnswer = () => {
		this.setState({
			cannedDialogShow: false,
			newCannedAnswer: '',
			lettersCounter: maxLetters,
			cannedAnswerError: false,
			cannedAnswerErrorMsg: '',
			cannedAnswerEditData: null,
			cannedAnswerEdit: false
		})
	}

	handleAddNewCannedAnswer = (e) => {
		let letters = maxLetters - e.target.value.length;
		if (letters === 0) {
			this.setState({
				cannedAnswerError: true,
				cannedAnswerErrorMsg: 'Max length reached',
			})
			return null
		}

		this.setState({
			newCannedAnswer: e.target.value,
			lettersCounter: letters,
			cannedAnswerError: false, cannedAnswerErrorMsg: '',
			// cannedAnswerEdit: true
		})
	}

	handleSaveCannedAnswer = () => {
		const { tabsValue, cannedAnswerEdit, cannedAnswerEditData, newCannedAnswer } = this.state;
		if (tabsValue === 0)	{
			if (cannedAnswerEdit) {
				this.props.editMerchantCannedAnswer([cannedAnswerEditData.id,	newCannedAnswer]);
			} else {
				this.props.addMerchantCannedAnswer([newCannedAnswer]);
			}
			this.props.listMerchantCannedAnswers()
		} else {
			this.props.editMerchantCannedImageCaption([cannedAnswerEditData.id, newCannedAnswer]);
			this.props.listMerchantCannedImages()
		}

		this.setState({
			cannedAnswerError: false,
			cannedAnswerErrorMsg: '',
			lettersCounter: maxLetters,
			cannedDialogShow: false,
			newCannedAnswer: '',
			cannedAnswerEdit: false,
			cannedAnswerEditData: null,
		})
	}

	handleOpenEditCanned = (data) => {
		const { tabsValue } = this.state;
		if (tabsValue === 0) {
			let letters = maxLetters - data.answer.length;
			this.setState({
				cannedAnswerEditData: data,
				cannedAnswerEdit: true,
				newCannedAnswer: data.answer,
				cannedDialogShow: true,
				lettersCounter: letters
			})
		} else {
			let letters = maxLetters;
			if (data.caption !== null) {
				letters = maxLetters - data.caption.length;
				this.setState({cannedAnswerEdit: true})
			}
			this.setState({
				cannedAnswerEditData: data,
				newCannedAnswer: data.caption !== null ? data.caption : '',
				cannedDialogShow: true,
				lettersCounter: letters
			})
		}
	}

	handleDeleteCanned = () => {
		const { tabsValue, deleteId, deleteImage} = this.state;
		if (tabsValue === 0) {
			this.props.deleteMerchantCannedAnswer(deleteId)
			this.props.listMerchantCannedAnswers()
		} else {
			this.props.deleteMerchantCannedImage([deleteId, deleteImage])
			this.setState({onTrashClick: false})
			this.props.listMerchantCannedImages()
		}

		this.setState({
			deleteId: null,
			openDelete: false,
			deleteImage: ''
		})
	}

	handleOpenDelete = (value, image) =>	{
		this.setState({
			openDelete: true,
			deleteId: value,
			deleteImage: image
		})
	}

	handleCloseDelete = () =>	{
		this.setState({
			openDelete: false,
			deleteId: null
		})
	}

	onUploadDrop = (acceptedFiles) => {
		const self = this;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
			const preview = URL.createObjectURL(file)
      const fr = new FileReader();
      fr.readAsArrayBuffer(file);

      fr.addEventListener('loadend', () => {
        if (getRealMimeType(fr.result) === 'unknown') {
          self.props.uploadError([0, 'Please upload image file']);
        } else {
          self.setState({ openCrop: true });
          const obj = {photo: preview};
          self.setState({ storeImage: obj });
					self.setState({crop: {
						...self.state.crop,
						width: 100,
						height: 100
					}});
        }
      });
    }
	}

  handleClosePhotoSelection = () => {
		this.setState({
			openCrop: false,
			storeImage: { photo: null }
		});
	}

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

	handleImageLoaded = (image) => {
		this.imageRef = image
	}

	handleImageCropComplete = (crop) => {
		this.setState({cropedRes: crop})
	}

	handleCloseCannedImage = () => {
		this.setState({
			cannedImagesShow: false
		})
	}

  handleCrop = () => {
		if (this.imageRef && this.state.crop) {
			const { crop } = this.state
			const canvas = document.createElement("canvas");
			const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
			const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(
					this.imageRef,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width,
					crop.height
			)

			const base64Image = canvas.toDataURL('image/png');
			let dataArr = base64Image.split('base64,');
			let fileSize = str2ab(atob(dataArr[1])).byteLength;

			localStorage.setItem('fileToUpload', atob(dataArr[1]));
			this.props.beginUpload([11, fileSize]);

			this.setState({
				openCrop: false,
				storeImage: { photo: null }
			});
			this.props.listMerchantCannedImages()
		}
	}

	render() {
		const { 
			cannedAnswers,
			tabsValue,
			cannedImages,
			newCannedAnswer,
			cannedDialogShow,
			cannedAnswerError,
			cannedAnswerErrorMsg,
			cannedAnswerEdit,
			lettersCounter,
			openDelete,
			openCrop,
			storeImage,
			crop
		} = this.state;
		const data = tabsValue === 0 ? cannedAnswers : cannedImages;
		return (
			<Fragment>
				<Dialog
					open={this.props.openManageTemplates}
					className='settings-dialog'
				>
					<DialogTitle className='settings-manage-templates-dialog-text'>
						<ArrowBackIcon onClick={this.props.handleCloseManageTemplates} className="order-item-edit-dialog-back-btn" />
						Manage templates
						<Divider className='settings-dialog-title-divider'/>
					</DialogTitle>
					<Paper square className='settings-manage-templates-paper'>
						<Tabs
							className='settings-manage-templates-tabs'
							centered
							indicatorColor="primary"
							textColor="primary"
							value={tabsValue}
							onChange={this.handleChangeTab}
							variant="fullWidth"
						>
							<Tab
								className='settings-manage-templates-tab'
								label="Canned Messages"
							/>
							<Tab
								className='settings-manage-templates-tab'
								label="Canned Images"
							/>
						</Tabs>
					</Paper>
					<DialogContent className='settings-manage-templates-content'>
						<Typography className='settings-manage-templates-content-header-text'>{tabsValue === 0 ? cannedAnswers.length > 0 ? 'Predefined answers' : 'No predefined answers' : cannedImages.length > 0 ? 'Predefined images' : 'No predefined images'}</Typography>
						<Divider className='settings-dialog-title-divider settings-dialog-title-divider--width'/>
						<List className='settings-manage-templates-list-main'>
							{data.length > 0 ? data.map((item, i) => {
								return (
									<Grid item container xs={12} key={i} className='settings-manage-templates-list-item-main'>
										
											{tabsValue === 0 ?
												<ListItem className='settings-manage-templates-list-item' disableGutters divider>
													<Typography className='settings-manage-templates-list-item-text'>{item.answer}</Typography>
													<CardActions className='settings-manage-templates-list-item-buttons'>
														<IconButton onClick={this.handleOpenEditCanned.bind(this, item)} className='settings-manage-templates-small-buttons' edge="end" aria-label="edit">
															<EditIcon/>
														</IconButton>
														<IconButton onClick={this.handleOpenDelete.bind(this, item.id)} className='settings-manage-templates-small-buttons' edge="end" aria-label="delete">
															<DeleteIcon/>
														</IconButton>
													</CardActions>
												</ListItem>
											:
												<ListItem className='settings-manage-templates-list-item settings-manage-templates-list-item--padding' disableGutters divider>
													<Card>
														<CardMedia
															className='settings-manage-templates-card-media'
															image={item.image}
															// title="Contemplative Reptile"
														/>
													</Card>
													<Typography className='settings-manage-templates-card-text'>{item.caption}</Typography>
													<CardActions className='settings-manage-templates-list-item-buttons'>
														<IconButton	onClick={this.handleOpenEditCanned.bind(this, item)}	className='settings-manage-templates-small-buttons' edge="end" aria-label="edit">
															<EditIcon/>
														</IconButton>
														<IconButton onClick={this.handleOpenDelete.bind(this, item.id, item.image)} className='settings-manage-templates-small-buttons' edge="end" aria-label="delete">
															<DeleteIcon/>
														</IconButton>
													</CardActions>
												</ListItem>
											}
									</Grid>
								)
							}) : null}
						</List>
						<DialogActions>
							{tabsValue === 0 ?
							<Button
								variant="contained"
								color="primary"
								className='settings-main-buttons setting-manage-templates-buttons--width'
								onClick={this.handleOpenCannedAnswer}
							>
								Add Canned answer
							</Button>
							:
								<Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this)} multiple={false} accept="image/*">
									{({getRootProps, getInputProps}) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<Button
													variant="contained"
													color="primary"
													className='settings-main-buttons setting-manage-templates-buttons--width'
												>
													Add Canned Image
												</Button>
											</div>
										</section>
									)}
								</Dropzone>
							}
						</DialogActions>
					</DialogContent>
				</Dialog>
				<Dialog 
					open={cannedDialogShow}
					className='settings-manage-templates-canned-answer'
				>
					<DialogTitle className='settings-manage-templates-canned-answer-dialog-text'>
						{tabsValue === 0 ? cannedAnswerEdit ? 'Edit Canned Answer' : 'Add Canned Answer' : cannedAnswerEdit ? 'Edit Image Caption' : 'Add Image Caption'}
					</DialogTitle>
					<DialogContent className='settings-manage-templates-canned-answer-content'>
						<TextField
							className="settings-manage-templates-canned-answer-input"
							fullWidth
							multiline
							rows="4"
							placeholder='Enter a message you frequently use'
							value={newCannedAnswer !== '' ? newCannedAnswer : ''}
							onChange={this.handleAddNewCannedAnswer}
							variant="filled"
							error={cannedAnswerError}
							helperText={cannedAnswerError ? cannedAnswerErrorMsg : ''}
						/>
						<DialogActions className='settings-manage-temlplate-canned-answer-dialog-action'>
							<Typography className='settings-manage-templates-max-letters'>{lettersCounter}</Typography>
							<Button
								variant="outlined"
								color="primary"
								className='promotion-main-outlined-buttons settings-manage-templates-buttons--width'
								onClick={this.handleCloseCannedAnswer}
							>
								Cancel
							</Button>
							<Button
								disabled={newCannedAnswer === '' ? true : false}
								variant="contained"
								color="primary"
								className='settings-main-buttons settings-manage-templates-buttons--width'
								onClick={this.handleSaveCannedAnswer}
							>
								{cannedAnswerEdit ? 'Change': 'Add'}
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
				<Dialog
					open={openDelete}
					className='settings-manage-templates-delete'
				>
					<DialogTitle className='settings-manage-templates-canned-answer-dialog-text settings-manage-templates-canned-answer-dialog-text--font'>
						{tabsValue === 0 ? 'Do you want to delete this Canned Message?' : 'Do you want to delete this Canned Image'}
					</DialogTitle>
					<DialogActions className='settings-manage-temlplate-canned-answer-dialog-action'>
						<Button
							variant="outlined"
							color="primary"
							className='promotion-main-outlined-buttons'
							onClick={this.handleCloseDelete}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							className='settings-main-buttons settings-manage-templates-buttons--width'
							onClick={this.handleDeleteCanned}
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
          className="bizz-color-dialog"
          aria-labelledby="max-width-dialog-title"
          open={openCrop}
          onClose={this.handleClosePhotoSelection}
        >
					<DialogTitle className="bizz-color-dialog-title" disableTypography>
						Add Logo and Images
					</DialogTitle>
					<DialogContent className="bizz-color-dialog-content">
						<Cropper
							className="bizz-color-dialog-cropper"
							src={storeImage.photo}
							onChange={this.onCropChange}
							crop={crop}
							ref="imageCrop"
							onImageLoaded={this.handleImageLoaded}
							onComplete={this.handleImageCropComplete}
						/>
					</DialogContent>
					<DialogActions className="bizz-color-dialog-buttons">
						<Button className='bizz-color-dialog-button bizz-color-dialog-button--first' onClick={this.handleClosePhotoSelection} autoFocus variant="contained" color="primary">
							Cancel
						</Button>
						<Button className='bizz-color-dialog-button bizz-color-dialog-button--second' onClick={this.handleCrop} variant="contained" color="primary">
							Add
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		)
	}
}

ManageTemplates.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ common, auth }) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
		cannedImages: common.cannedImages ,
		cannedAnswers: common.cannedAnswers
  }
}, (dispatch) => {
  const actions = {
		listMerchantCannedAnswers,
		listMerchantCannedImages,
		addMerchantCannedAnswer,
		deleteMerchantCannedAnswer,
		editMerchantCannedAnswer,
		uploadError,
		beginUpload,
		deleteMerchantCannedImage,
		editMerchantCannedImageCaption
	};
  return bindActionCreators(actions, dispatch);
})(ManageTemplates);