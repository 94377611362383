import React, { Component } from 'react';
import './styles.css';

class Layout extends Component {

  render() {
    let layoutBgd = this.props.page === 'load' && 'layout--transparent'
    
    return (
      <div {...this.props} className={`layout ${this.props.className || ''} ${layoutBgd || ''}`} />
    )
  }
}

export default Layout;
