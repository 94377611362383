import React from 'react';
import { DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { ColorDialog, StyledDialog } from './styles.js'

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <ColorDialog disableTypography style={{}}>
          <span>Terms & Conditions</span>
        </ColorDialog>
        <DialogContent>
          <StyledDialog disableTypography>
            Disclaimer
          </StyledDialog>
          <DialogContentText>
            Piip Mobile AG cannot be held responsible for any information provided by
            merchants using Biiz by Piip or those taking advantage of Piip as an
            end-user. The responsibility for accurate, up-to-date and ethical
            information lies with information provider. Piip does not make any
            express or implied representations or warranties of any kind about
            any information and promotions provided by the users of Piip.
            <br/><br/>
            Piip
            is not liable for any loss or damage arising from loss of data or
            profits using Piip applications and services.
          </DialogContentText>
          <StyledDialog disableTypography>
            CopyRight
          </StyledDialog>
          <DialogContentText>
            No images and logos owned and provided by Piip itself may be reproduced,
            distributed or transmitted in any form without the permission of
            Piip. Copyright of images on merchant cards and end-user profiles
            must not be reproduced, distributed or transmitted in any form
            without the explicit permission of the respective owner. Piip cannot
            be held responsible for copyright infringement regarding images or
            information owned by merchants or end-users.
          </DialogContentText>
          <StyledDialog disableTypography>
            Terms of Service
          </StyledDialog>
          <DialogContentText>
            These Terms of Service govern access to and use of our Services, including our website, the Piip software, Piip and Biiz by Piip and other products and services provided on the Piip Website. Your access to and use of the Services are conditioned on your acceptance of and compliance with these Terms of Service. By accessing or using the Services you agree to be bound by these Terms of Service.
            <br/><br/>
            If you are entering into this Agreement on behalf of a company or other legal entity, you agree that you have the authority to bind such entity to these terms and conditions. If you do not have such authority, or if you do not agree with these terms and conditions, you must not accept this Agreement and may not use the Services.
            <br/><br/>
            You may not access our Services for purposes of monitoring its availability, performance or functionality, nor for any other benchmarking or competitive purposes. In addition to that, you may not reverse engineer or analyze any parts of our Service.
            <br/><br/>
            Piip and Biiz by Piip are apps and a service provided by Piip Mobile AG in Switzerland. By using Piip or Piiip Biiz the user confirms to have read and understood these terms of use and agrees to them.
          </DialogContentText>
          <StyledDialog disableTypography>
            Basic Terms
          </StyledDialog>
          <DialogContentText>
            a) Piip is made available to the user free of charge. Biiz by Piip as used by our partners is only available via subscription or purchase with costs. In order to use all features of Piip and Biiz by Piip, the registration of a user account with a valid and verified mobile phone number as well as access to the Internet may be necessary.
            <br/><br/>
            b) For some features, Piip will have access to or process or store private information of the user. See our privacy section for more information.
            <br/><br/>
            c) Piip allows for sending messages between Piip users and third parties using Piip messenger functionality. The user expressly agrees to receive such messages. The user agrees not to send any messages via Piip or Biiz by Piip that violate the laws and agrees not to cause others to send such messages. Piip Mobile AG shall be entitled to contact the user with messages via Piip, email or SMS at any time.
            <br/><br/>
            d) Piip Mobile AG will not tolerate objectionable content uploaded to Piip and Biiz by Piip. Piip Mobile AG respects the rights of others and expects its users to do the same. Our users must not post or send objectionable content that violates other’s rights; bullies, harasses, intimidates, or defames others; or spams or solicits Piip’s users. If you do send objectionable content, be prepared to be banned from Piip and Biiz by Piip. If somebody sends you objectionable content, you can either report the user within the app or send an email with details to <a href="https://support.piip.com/">our support</a>.
            <br/><br/>
            e) Piip Mobile AG shall endeavor to ensure high availability and reliability of Piip and Biiz by Piip. However, Piip Mobile AG cannot guarantee that Piip and Biiz by Piip or individual features of the provided services are continuously, at any time and/or completely available. Piip Mobile AG recommends the user to ensure a regular data backup of his/her smartphone including Piip and Biiz by Piip.
            <br/><br/>
            f) Piip Mobile AG may discontinue its providing of Piip and Biiz by Piip or exclude the user from using Piip or Biiz by Piip at any time without stating any reasons. Piip Mobile AG may change services at any time without stating any reasons, in particular with regard to paid functionalities, if any.
          </DialogContentText>
          <StyledDialog disableTypography>
            Privacy
          </StyledDialog>
          <DialogContentText>
            a) Piip Mobile AG, Piip and Biiz by Piip shall be subject exclusively as permitted to Swiss data privacy laws only. <br/><br/>
            b) How Piip Mobile AG receives, collects and uses information about you, from your use of the Service in general, including personal information, is more fully described in the Piip Privacy Policy.
            <br/><br/>
            c) By using the Service, you understand and agree that we collect, use, transfer, share and store personal information as described in the Piip Privacy Policy.
          </DialogContentText>
          <StyledDialog disableTypography>
            Liability
          </StyledDialog>
          <DialogContentText>
            a) Subject to mandatory legal provisions, Piip Mobile AG shall not assume any liability for direct or indirect damage caused by the use of Piip and Biiz by Piip or in connection with Piip and Biiz by Piip.
            <br/><br/>
            b) In case of violations of these terms of use and in case of violation of rights and corresponding claims of third parties, the user shall expressly indemnify Piip Mobile AG from any liability and shall be liable for damages of Piip Mobile AG to the full extent.
          </DialogContentText>
          <StyledDialog disableTypography>
            General Terms
          </StyledDialog>
          <DialogContentText>
            Piip Mobile AG may change these terms of use at any time. The user will be informed about such changes in a suitable manner. The user may discontinue the use of Piip and Biiz by Piip at any time. By continuing the use of Piip or Biiz by Piip after changes of these terms of use have been notified, the user will be expressly consenting to such changes.
            <br/><br/>
            Subject to mandatory legal provisions, the use of our websites, Piip and Biiz by Piip apps shall be exclusively governed by Swiss laws with Zurich (Switzerland) being the exclusive place of jurisdiction.
          </DialogContentText>
          <StyledDialog disableTypography>
            Privacy Policy
          </StyledDialog>
          <DialogContentText>
            Piip Mobile AG, the developer of Piip and Biiz by Piip, takes your privacy matters very seriously. Please read the following statement to learn more about our Privacy Policy. This Policy details how we receive, collect and use information in connection with our website, the Piip software (Piip and Biiz by Piip), and other products and services provided on the site.
            By visiting Piip’s Website or using Piip’s services, you are accepting the policies and practices described in this policy, as such policy may be amended from time to time. Each time you visit the site or use the service, you agree and expressly consent to our collection, use and disclosure of the information that you provide as described in this policy.<br></br><br/>
            To be clear about the terminology we are using, when we use the phrase „personal information“ in this policy, we mean information about you that is personally identifiable, like your name, address, e-mail address or phone number, as well as other non-public information that is associated with this personal information. When we use the phrase „Anonymous Information“ in this policy, we mean information that is not personally identifiable, or linked to your Personal Information such as aggregated information, general demographic information and IP addresses. Anonymous Information does not necessarily enable identification of individual persons.
          </DialogContentText>
          <StyledDialog disableTypography>
            1. Collection and Use of Information
          </StyledDialog>
          <DialogContentText>
            <span><b>Name, Phone Number and Photo</b><br/>
            When you install Piip or Biiz by Piip and register, you will be asked to provide us with your phone number, nick name and a photo (nickname and phone number are mandatory).
            Should you provide your name and/or photo it will be visible to other Piip users as long as:<br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;(i) these users have your phone number,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;(ii) or you all participate in the same Group
            </span>
            <span><b>Contacts and mobile device’s address book.</b><br/>
            When you install Piip or Biiz by Piip and register, you allow us access to your mobile device’s address book.<br/>
            A copy of the phone numbers and names in your address book (but not emails, notes or any other personal information in your address book) will be stored on our servers and will only be used to indicate which of your contacts are already Piip users and correctly display the name of each contact as it appears in your address book when a message is received (Push Notifications).
            <br/><br/>Your phone number will be used for identification purposes and is linked to your Piip user ID.
            <br/>Since Piip users may add your phone number to their address book and see that you registered to Piip, we do not consider the fact that your phone number exists on Piip as private information.
            </span>
            <span><b>Messages and user created content</b><br/>
            With Piip you can send messages to a group of other Piip users. Those messages are only visible to members of that group.&nbsp;Messages may contain text or other media data that you created, but messages may also be generated automatically on your behalf for specific events (e.g. Piip a promotion)
            </span>
            <span>
            We maintain copies of all messages on our servers. Piip may make the messages available on different devices in the future. We do not interpret the content of any messages. However, we may interpret the related meta information (time stamps, location, message length, sender and recipient) of such messages. We maintain this information in order to better understand network behavior and trends, detect potential outages and technical issues and for public safety reasons.
            </span>
            <span>
            Piip allows announcing your creation of a group or your participation in a group to other Piip users. The group creator and participants have full control if this announcement should happen for a group and if so what information about the group is shared (group name, participants, locations, dates and messages). This setting is a property of individual groups and therefore such announcement may occur whenever you join such a group.
            </span>
            <span><b>Online Status and Seen Status</b><br/>
            Piip App allows other users to see your connection status (whether you are online or not – and if not, when was the last time you were connected to the service) as well as whether you saw messages they sent to you.
            </span>
            <span><b>Location Information</b><br/>
            While using our Service we may collect periodically your location. When you share your location to other users your location information is attached to a message and processed and stored like other messages. Additionally, to improve our suggestions we may process, store and link your location information to your in-app behavior. This allows us to give you best suggestions depending on your location information. To retrieve details about nearby point of interests we may also submit raw location information to third-party data providers like Google Maps.
            </span>
            <span><b>Other Services</b><br/>
            Our servers may automatically collect data about your Internet address when you use our services. This information, known as an Internet Protocol Address, or IP Address, is a number that is automatically assigned to your computer by your Internet service provider whenever you are on the Internet. Our servers may log your IP Address and, if applicable, your domain name. Your IP Address is used to help identify you and to gather demographic information about our members and users as a whole, but does not include personally identifiable information. Our server may also record the referring page that linked you to us (e.g., another web site or a search engine); the pages you visit on this site; the website you visit after this site; the ads you see and/or click; other information about the type of web browser, computer, platform, related software and settings you are using; any search terms you have entered on this Site or a referral site; and other Web usage activity and data logged by our servers. We use this information for internal system administration, to help diagnose problems with our servers, and to administer our service. Such information may also be used to gather demographic information, such as country of origin and Internet and Service Provider.
            <br/>We will not link any personal information, including e-mail addresses, with aggregated data of our users.
            <br/>Any or all of these activities with regard to site usage information may be performed on our behalf by our service providers, including, for example, our analytics vendor(s) and our email management partner(s) and server hosting partner(s).
            </span>
          </DialogContentText>
          <StyledDialog disableTypography>
            2. Third-Party Sites and Privacy Practices
          </StyledDialog>
          <DialogContentText>
            <span>You may arrive at our site and/or download the Piip App from another website. In addition, our site may contain links that will let you leave our site and access another website. Websites linked to or from our site are not under the control of Piip and it is possible that these websites have a different privacy policy. Our Policy applies solely to personal information that is acquired on the site or through your use of our Service, and/or your relationship with Piip. We urge you to be careful when you enter any personal information online. We accept no responsibility or liability for these other websites.
            </span>
            <span><b>Analytics Collection</b><br/>
            Piip uses Google Analytics (google.com/analytics) and Fabric’s SDK (fabric.io) to help us anonymously track and report user/visitor 
            behavior information and users‘ standard log information to our websites (piip.com, piip.me, support.piip.com, and other Piip-brand 
            related websites), Piip and Biiz by Piip. This information (including but not limited to: IP address, data storage, maintenance services,
            database management, web analytics and information processing) helps us analyze and evaluate how Piip and Biiz by Piip are used as part 
            of our ongoing efforts to improve Piip’s features and services. No personally identifying data is included in this type of reporting. 
            Google Analytics may have access to your information only for the purposes of performing these tasks and on behalf of Piip and under 
            obligation similar in those in this Privacy Policy. For more information about the terms that govern analytics tool, please visit the 
            respective tools terms of service.
            </span>
            <span><b>Sharing and Disclosure of Information</b><br/>
            We do not rent, sell, or share any information about our users with any third-parties, except as specifically described herein. We may disclose your personal information if we believe such action is necessary to:<br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;(a) comply with the law, or legal process served on us;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;(b) protect and defend our rights or property (including the enforcement of our agreements); or<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;(c) act in urgent circumstances to protect the personal safety of users of our Service or members of the public.
            </span>
            <span>
            In order to provide you with Piip services you have requested, Piip may sometimes, if necessary, share your personal information (excluding your address book and related information) and traffic data with trusted partner service providers and/or agents, for example: banking organizations or other providers of payment and analytical services, customer support, or hosting services. Piip will always require these third parties to take appropriate organizational and technical measures to protect your personal data and traffic data and to observe the relevant legislation.
            </span>
            <span>
            Within the framework of the&nbsp;<a href="http://www.edoeb.admin.ch/datenschutz/00626/00753/00970/index.html">U.S.-Swiss Safe Harbor Framework </a> our US third-party providers have committed to warranting a reasonable level of privacy which is similar to the Swiss data privacy laws.
            The following is a description of how we may share your personal information with trusted third party partners. Should we add additional partners, we will immediately update this description:
            </span>
            <br/>
            <span>
            &nbsp;&nbsp;&nbsp;&nbsp;1. We use third party companies (digits.com) in order to send you an authentication SMS when you register with Piip or Piip Biiiz. In order to send you the SMS, we send one of these third party companies your phone number and the authentication message. These third parties are contractually obligated to only use your phone number for the transmission of the authentication SMS.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;2. If we fail to send you an SMS (this happens in certain cases) you may ask us to call you and read you the authentication code on the phone. This is done using a third party automated phone system. In order to call you, we provide this third party partner with your phone number and send the „audio content“ of the call to them. The third party partner in turn will typically use another phone provider who may use another phone provider and so on until the call reaches you. This is an industry standard mechanism for the transmission of phone calls. The third party partner is contractually obligated to only use your phone number for the completion of the authentication phone call.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;3. We may use Push Notification Services to notify you about various events related to the app. If we do, the phone number or the name of the person calling you or sending you a message may be part of the notification. This message is sent to the push notification service provider, for delivery to your device.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;4. We use third parties to provide you with information about locations and their descriptions as well as for displaying an interactive map.&nbsp; We may share anonymized location information with such a third party provider.
            </span>
            <span>
            Notwithstanding any of the foregoing, the Piip servers that are used to store your personal information are owned and hosted by Amazon (AWS).<br/>
            We may from time to time ask you to provide information about your experiences, which will be used to measure and improve quality. You are at no time under any obligation to provide any of such data. Any and all information which is voluntarily submitted in feedback forms on the site or any survey that you accept to take part in is used for the purposes of reviewing this feedback and improving the Piip software, products and websites.<br/>
            We may contact you to send you messages about faults and service issues. Furthermore, we reserve the right to use email, the Piip software or SMS to notify you of any eventual claims related to your use of our software, websites and/or products, including without limitation claims of violation of third party rights.<br/>
            We may send you alerts via the Piip software to notify you when someone has tried to contact you. We may also use the Piip software to keep you up to date with news about our software and products that you have purchased and/or to inform you about other Piip products and related information.
            </span>
          </DialogContentText>
          <StyledDialog disableTypography>
            3. Children&apos;s Privacy
          </StyledDialog>
          <DialogContentText>
           Piip websites and software clients are not intended for or designed to attract anyone under the age of 13. Piip software does not yet validate the age of its users. We encourage parents to be involved in the online activities of their children to ensure that no information is collected from a child without parental permission.
          </DialogContentText>
          <StyledDialog disableTypography>
            4. Security
          </StyledDialog>
          <DialogContentText>
            We take commercially reasonable precaution to protect personal information from misuse, loss and unauthorized access. Although we cannot guarantee that personal information will not be subject to unauthorized access, we have physical, electronic, and procedural safeguards in place to protect personal information. personal information is stored on our servers and protected by secured networks to which access is limited to a few authorized employees and personnel. However, no method of transmission over the Internet, or method of electronic storage is 100% secure.
          </DialogContentText>
          <StyledDialog disableTypography>
            5. Business Transitions
          </StyledDialog>
          <DialogContentText>
            In the event that we go through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of our assets, personal information will likely be among the assets transferred. As part of such a transaction the new entity will be required to commit to the same level of protection of your personal information as described in this Privacy Policy. If we cannot obtain such a commitment, we will not perform the business transition.
            We will post a notice on this site of any such change in ownership or control of the personal information we may have prior to such transfer.
          </DialogContentText>
          <StyledDialog disableTypography>
            6. Changes to Policy
          </StyledDialog>
          <DialogContentText>
            From time to time, we may revise this policy. We reserve the right to update or modify this policy, or any other of our policies or practices, at any time with or without notice. However, we will not use your personal information in a way that is materially different than the uses described in this policy without giving you an opportunity to opt-out of such differing uses. We will post the revised policy on the site, so that users can always be aware of what information we collect, how the information is used and under what circumstances such information may be disclosed. You agree to review the policy periodically so that you are aware of any modifications. Your continued use of the Service indicates your assent to any changes and signifies your agreement to the terms of our policy. If you do not agree with these terms, you should not use the site, the Piip App, or any other or our services.
          </DialogContentText>
          <StyledDialog disableTypography>
            7. Change or Removal of Information
          </StyledDialog>
          <DialogContentText>
            If you provided your email address to us and wish to update or delete it, you may do so by contacting <a href="https://support.piip.com/">our support</a>.
            Due to technological reasons, we do not know when and if you delete the application. Therefore, if we detect no activity from your device for 180 days, we will assume that you deleted the application and we will remove your address book as well as the data we collected to improve your suggestions from our servers. However, since we are not certain that you deleted the application (you may just be on a long vacation, etc.) we will not deactivate and delete your Piip account (which is your phone number and device ID, as well as all your messages). We do not want to force you to register again in the event of non-use.
            If you wish to delete your account completely (and remove all your messages), you can if present use the in-app option in the settings menu or write us an email with your phone number to <a href="https://support.piip.com/">our support</a>. Please note that this will remove your data from Piip Services, and deactivate Piip and Biiz by Piip on your device. If you have additional concerns and/or requests, you can contact <a href="https://support.piip.com/">our support</a>.
          </DialogContentText>
          <StyledDialog disableTypography>
            Compliance, Legal Actions, Law Enforcement
          </StyledDialog>
          <DialogContentText>
            Please direct issues in regards to compliance, legal actions and law enforcement to:<br/>
            - by Mail: see <a href="#imprint">our imprint</a> below<br/>
            - by Fax: +41 44 534 66 51<br/>
            - by Email: <i>legal-compliance@ this domain dot com</i><br/>
            (!) this address is for legal - compliance purposes only. No support or other requests will be handled.
          </DialogContentText>
          <StyledDialog id="imprint" disableTypography>
            Contact Information, Imprint
          </StyledDialog>
          <DialogContentText>
            Please direct all questions in connection with our policies digitally to <a href="https://support.piip.com">our support</a>, or by writing to us:
            <b>Piip Mobile AG</b><br/>
            Badenerstr. 141<br/>
            8004 Zürich<br/>
            Switzerland<br/><br/>
            Piip Mobile AG is a Swiss joint-stock corporation, registered in the commercial register of the Canton of Zürich with reg.no. CHE-165.443.923. <br/>
            For more information about the Company, please visit <a href="https://www.piipmobile.com">our corporate website</a> presence.<br/><br/>
            Zürich, 11. February 2017<br/>
            <span>&nbsp;</span>
            <Typography className="terms-dialog-footer-text" style={{"color": "Gray", "fontSize": "smaller", "fontStyle": "italic", "border": "1px dotted lightgray", alignItems: 'center', justifyContent: 'center', display: 'flex'}}> &nbsp;Copyright © 2017, Piip Mobile AG. All Rights Reserved.</Typography>
          </DialogContentText>
        </DialogContent>
      </>
    );
  }
}

export default Terms