export const GETOPENEDCHATS = 'GETOPENEDCHATS'
export const GETOPENEDCHATS_RESULT = 'GETOPENEDCHATS_RESULT'
export const GETOPENEDCHATS_ERROR = 'GETOPENEDCHATS_ERROR'
export const onMessage = 'onMessage'
export const CHATMESAGESTATE = 'CHATMESAGESTATE'
export const GETMESSSAGESBEFORE = 'GETMESSSAGESBEFORE'
export const GETMESSAGEHISTORY_RESULT = 'GETMESSAGEHISTORY_RESULT'
export const SETLASTCHATID = 'SETLASTCHATID'
export const ONLINESTATUSCHANGED = 'ONLINESTATUSCHANGED'
export const CHATOPENEDSTATUSCHANGED = 'CHATOPENEDSTATUSCHANGED'
export const SHOWNOTEMESSAGE = 'SHOWNOTEMESSAGE'
export const ASSIGNCHAT = 'ASSIGNCHAT'
export const ASSIGNCHAT_RESULT = 'ASSIGNCHAT_RESULT'
export const ASSIGNCHAT_ERROR = 'ASSIGNCHAT_ERROR'
export const ALLOWCHATSCROLLING = 'ALLOWCHATSCROLLING'
export const SETMESSAGESTATE = 'SETMESSAGESTATE'
export const SENDMESSAGE = 'SENDMESSAGE'
export const SENDMESSAGE_RESULT = 'SENDMESSAGE_RESULT'
export const SENDMESSAGE_ERROR = 'SENDMESSAGE_ERROR'
export const SENDTYPINGSTATE = 'SENDTYPINGSTATE'
export const USERTYPING = 'USERTYPING'
export const SHOWTYPINGOFF = 'SHOWTYPINGOFF'
export const LEAVECHAT = 'LEAVECHAT'
export const LEAVECHAT_RESULT = 'LEAVECHAT_RESULT'
export const LEAVECHAT_ERROR = 'LEAVECHAT_ERROR'
export const BEGINUPLOADMESSAGE = 'BEGINUPLOADMESSAGE'
