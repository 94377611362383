import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.css';
import Wink from './images/wink.png';
import Haha from './images/haha.png';
import Like from './images/like.png';
import Smile from './images/smile.png';
import DefaultAvatar from './images/default_avatar.png';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';

class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageMessage: '',
      openImageMessage: false,
      showCaptionMessage: ''
    };
  }

  isMe = (uid) => {
    return this.props.uid !== uid ? false : true; 
  };

  formatTime = (date) => {
    let h = date.getHours();
    let m = date.getMinutes();
    h = h <= 9 ? "0" + h : h;
    m = m <= 9 ? "0" + m : m;
    return h + ":" + m;
  };

  isEmoticon = (text) => {
    if (text && text !== "" && (text.indexOf("😉") !== -1 || text.indexOf("😊") !== -1 || text.indexOf("😃") !== -1 || text.indexOf("👍") !== -1)) {
      if (text.indexOf("😉") !== -1) return (<img className='emoticon' src={Wink} alt="wink" />);
      if (text.indexOf("😊") !== -1) return (<img className='emoticon' src={Smile} alt="wink" />);
      if (text.indexOf("😃") !== -1) return (<img className='emoticon' src={Haha} alt="wink" />);
      if (text.indexOf("👍") !== -1) return (<img className='emoticon' src={Like} alt="wink" />);
    }
    return null;
  };

  isAttachment = (attachment, text) => {
    if (attachment && attachment !== '') {
      return (<span><img className='attachment' src={attachment} alt="attachment" onClick={this.showBigImage.bind(this, attachment, text)} style={{cursor: "pointer"}}/><span style={{display: 'block'}}> {text} </span></span>)
    }
    return null;
  };

  isLocation = (location) => {
    if (location && location !== '') {
      return (<a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/search/?api=1&query='+location.latitude+','+location.longitude}><img src={'https://maps.googleapis.com/maps/api/staticmap?center='+location.latitude+','+location.longitude+'&size=400x200&zoom=15&markers=color:red%7C'+location.latitude+','+location.longitude+'&key='+process.env.GOOGLE_API_KEY} alt="location" style={{cursor: "pointer"}}/></a>)
    }
    return null;
  };

  parseContent = (text, viewStatus, messages, attachment, location, mineMsg, uid) => {
    let colorTxt = '';
    if ((mineMsg !== undefined && mineMsg) || this.isMe(uid)) {
      colorTxt = {color: 'black'};
      viewStatus = (<span className='smallStatus'><br />{viewStatus}</span>);
    } else {
      colorTxt = {color: 'gray'}
      viewStatus = '';
    }

    if (messages !== undefined && messages !== '' && attachment === undefined) {
      const len = messages.length-1;
      return messages.map((item, index) => {
        let res = this.isEmoticon(item);
        res = res !== null ? res : item;
        let viewStat = viewStatus ? viewStatus : '';
        if (parseInt(index) !== parseInt(len)) {
          viewStat = '';
        }
        if (index !== 0) {
          return (<p style={ colorTxt } key={(new Date).getTime() + '' + index} className='oneLine'>{res}{viewStat}</p>);
        } else if(item !== '') {
          return (<p style={ colorTxt } key={(new Date).getTime() + '' + index} className='oneLine'>{res}{viewStat}</p>);
        }
      });
    } else {
      let res = this.isEmoticon(text);
      if (res === null) res = this.isAttachment(attachment, text);
      if (res === null) res = this.isLocation(location);
      const ret = res !== null ? res : text;
      return (<Typography key={(new Date).getTime()} className='oneLine'>{ret}{viewStatus}</Typography>);
    }
  };

  showBigImage = (attachment, text) => {
    this.setState({
      openImageMessage: true,
      showImageMessage: attachment,
      showCaptionMessage: text,
    });
  }

  handleAvatarClick = () => {
    const uid = this.props.data.uid;
    this.props.avatarClick(uid);
  };

  handleCloseImageMessage = () => {
    this.setState({
      openImageMessage: false,
      showImageMessage: '',
      showCaptionMessage: '',
    });
  };

  msgMessage = (content, msg, type) => {
      let cntNew = content !== undefined && content !== '' ? content : msg;
      return cntNew; 
  };

  render() {
    const data = this.props.data;
    if (data.type === 12 || data.type === 5) {
      if (data.mineMsg) {
        try {
          const cnt = JSON.parse(data.content);
          if (cnt.price) cnt.value = cnt.price; // patch for invalid json earlier posted
          const cntNew = `${cnt.message}, Price: ${cnt.value} ${cnt.currency}`;
          data.content = cntNew;
        } catch (err) {
					// console.log('err chat messages', err)
				}
      } else {
        return null;
      }
    } else if (data.type === 11) {
      try {
        const msg11 = JSON.parse(data.content);
        data.content = this.msgMessage(msg11.message, '', data.type);
      } catch (err) {
				// console.log('err chat messages data type 11', err)
			}
    } else if (data.type === 13) {
      data.content = this.msgMessage(data.forWeb !== undefined ? data.forWeb[0] : '', 'I agree with this order');
    } else if (data.type === 14) {
      data.content = this.msgMessage(data.forWeb !== undefined ? data.forWeb[0] : '', 'I disagree with this order');
    }

    if (data.content && !data.content.message) {
      data.forWeb = data.content.split('\n');
    }

    const showDay = this.props.displayDay !== '' ? (<Grid className='chat-messages-show-day' item xs={12} container>{this.props.displayDay}</Grid>) : '';

    const content12 = this.parseContent(data.content, data.view_status, data.forWeb, data.attachment, data.location, data.mineMsg, data.uid);
    let avatar = (data.avatar !== '' && data.avatar !== null && data.avatar !== undefined) ? data.avatar : DefaultAvatar;

    //var d = data === undefined ? this.props.merchantInfo : data.merchantInfo;

    if ((data.mineMsg !== undefined && data.mineMsg) || this.isMe(data.uid)) {
      avatar = (this.props.merchantInfo.logo !== undefined && this.props.merchantInfo.logo !== null) ? this.props.merchantInfo.logo : DefaultAvatar;
      return (
        <Grid container className='chat-main-container'>
					<Grid item xs={12} container>
						{showDay}
					</Grid>
					<Grid item xs={12} container className='chat-message-main--mine-msg'>
						<Grid item xs={12} className='timeWraper--mine-msg'>
                {moment(data.time).format('HH:mm')}
            </Grid>
						<Grid
							item xs={11}
							container
							// className={`message ${this.isMe(data.uid) ? 'rightBubble' : 'leftBubble'}`}
							className='chat-messages--mine-msg'
						>
							{content12}
						</Grid>
						<Grid item xs={1} container className='chat-user-avatar-main'><img src={avatar} className='chat-user-avatar'/></Grid>
					</Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container className='chat-main-container'>
					<Grid item xs={12} container>
						{showDay}
					</Grid>
					<Grid item xs={12} container className='chat-message-main'>
						<Grid item xs={12} className='timeWraper'>
                {moment(data.time).format('HH:mm')}
            </Grid>
						<Grid item xs={12} container>
							<Grid item xs={1} container className='chat-user-avatar-main'><img src={avatar} className='chat-user-avatar'/></Grid>
							<Grid
								item xs={11}
								container
								// className={`message ${this.isMe(data.uid) ? 'rightBubble' : 'leftBubble'}`}
								className='chat-messages'
							>
								{content12}
							</Grid>
						</Grid>
					</Grid>
        </Grid>
      );
    }
  }
}

export default connect(({auth, common}) => {
  return {
    uid: auth.uid,
    merchantInfo: common.merchantInfo,
  }
}, (dispatch) => {
  const actions = {};
  return bindActionCreators(actions, dispatch);
})(ChatMessage);
