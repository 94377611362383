import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../../store/index';
import Layout from '../../components/Layout';
import './styles.css';
// import Circle from './images/no_circle.svg';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorModal from '../../components/ErrorModal';
import {setPreviousPath, loginAgent} from '../../store/auth/actions';
import { Grid, TextField } from '@material-ui/core';
import WrapperContent from '../../components/WrapperContent';
import { FormControlCss, ColorButton } from './styles.js'

class LoginAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameError: '',
      passwordError: '',
      icon: '',
      iconError: '',
      valid: 0,
    };
  }

  componentDidMount = () => {
    // document.title = text.title;
  }

  componentDidUpdate = () => {
    if (this.props.isAuthorized === true) {
      history.push('/messages');
    }
    if (this.props.isValidToken === false) {
      history.push('/register');
    }
    let currentLocation = history.location.pathname;
    this.props.setPreviousPath(currentLocation);
  };
  
  handleLoginAgent = () => {
    if(this._validate(true)){
      if(!this.props.isAuthorized){
        this.props.loginAgent(['', parseInt(this.state.username), this.state.password, 3]);
        localStorage.setItem('token', this.state.password)
        localStorage.setItem('uid', parseInt(this.state.username))
        localStorage.setItem('userType', 3)
      }
    }
  };

  _validate = () => {
    let error = false;
    let reg = /^\d+$/;
    if(!(this.state.username !== '' && this.state.username !== null && reg.test(this.state.username))){
      this.setState({titleError: "Invalid agent id"});
      error = true;
    }
    if(!(this.state.password !== '' && this.state.password !== null)){
      this.setState({passwordError: "Invalid agent password"});
      error = true;
    }
    return (error) ? false : true;  
  };
  
  handleUsernameChange = (event) => {
    this.setState({username: event.target.value});
    // this.setState({usernameError: ""});
  };
  
  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
    // this.setState({passwordError: ""});
  };

  render() {
    let icons = {};
    let check = (<CheckIcon className="circle"/>);
    let iconsDefaults = {
      username: (
        <Grid className="wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <span className="num">1</span>
        </Grid>),
      password: (
        <Grid className="wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <span className="num">2</span>
        </Grid>),
    };
    
    icons.username = this.state.username === "" ? iconsDefaults.username : check;
    icons.password = this.state.password === "" ? iconsDefaults.password : check;
    return (
			<WrapperContent header={true} title="Agent Login">
				<Layout>
					<div className='login-agent-main-div'>
						<Grid className="agent-login-wrapper" container>
              <Grid container item justify="center" alignItems="center">
                <Grid item className="form-label">
									{icons.username}
									<span className="step-name">Agent Name</span>
								</Grid>
								<Grid item>
									<FormControlCss variant="filled">
										<TextField
											style={{border: '2px solid #cf5ada'}}
											required={true}
											onChange={this.handleUsernameChange}
											id="filled-basic1"
											label="Enter Agent ID Here"
											variant="filled"
											defaultValue={this.state.username !== "" ? this.state.username : ""}
											size="small"
										/>
									</FormControlCss>
								</Grid>
              </Grid>
							<Grid className="agent-login-input-holder" container item justify="center" alignItems="center">
                <Grid item className="form-label">
									{icons.password}
									<span className="step-name">Password</span>
								</Grid>
                <FormControlCss variant="filled">
                  <TextField
                    style={{border: '2px solid #cf5ada'}}
                    required={true}
                    onChange={this.handlePasswordChange}
                    id="filled-basic"
                    label="Enter Password Here"
                    variant="filled"
                    defaultValue={this.state.password !== "" ? this.state.password : ""}
                    size="small"
                    type="password"
                  />
                </FormControlCss>
							</Grid>
							<Grid container>
								<Grid container item justify="center" alignItems="center" xs={12}>
									<ColorButton className="form-button" onClick={this.handleLoginAgent} variant="contained" size="small" color="primary">
                    Login
									</ColorButton>
								</Grid>
							</Grid>
						</Grid>
					</div>
					<ErrorModal />
				</Layout>
			</WrapperContent>
    );
  }
}

LoginAgent.propTypes = {
  userType: PropTypes.number.isRequired,
  //uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isValidToken: PropTypes.bool.isRequired,
  setPreviousPath: PropTypes.func,
  loginAgent: PropTypes.func
};

export default connect(({common, auth}) => {
  return {
    userType: auth.userType,
    isAuthorized: auth.isAuthorized,
    isValidToken: auth.isValidToken,
    uid: auth.uid,
    token: auth.token,
    useClipboard: common.useClipboard,
    previousPath: auth.previousPath,
  }
}, (dispatch) => {
  const actions = {setPreviousPath, loginAgent};
  return bindActionCreators(actions, dispatch);
})(LoginAgent);
