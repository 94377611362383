import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import avatar from './images/avatar.jpg';
import { Create as CreateIcon, DeleteOutlineOutlined as DeleteIcon } from '@material-ui/icons/';
import './styles.css';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { deleteSubPromotions, getSubPromotions, assignSubPromotion } from '../../store/promotions/actions';
function importAll(r) {
	const images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}

const iconImages = importAll(require.context('../../assets/images/icons', false, /\.(png|jpe?g|svg)$/));

class PreviewPromotions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPromotionData: [],
			open: false,
			orderItemSelected: true,
			selectedOrderItem: null,
			selectedPreviewPromotion: null,
			id: null,
			title: '',
			description: '',
			date: null,
			images: '',
			clickCounter: 0,
			isEditIconClicked: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.editPromotions) {
			if (prevProps.title !== this.props.title) {
				this.setState({ title: this.props.title })
			} else if (prevProps.description !== this.props.description) {
				this.setState({ description: this.props.description })
			} else if (prevProps.images !== this.props.images) {
				this.setState({ images: this.props.images })
			} else if (prevProps.date !== this.props.date) {
				this.setState({ date: this.props.date })
			}
		} else {
			if (prevState.selectedPromotionData.length === 0) {
				let findPromo = this.props.allPromotions.find((data => data.id === this.props.selectedPromo))
				this.setState({ selectedPromotionData: findPromo, id: findPromo.id, title: findPromo.title, description: findPromo.description, images: findPromo.template_id, date: findPromo.end_time })
			} else if (prevState.selectedPromotionData.id !== this.props.selectedPromo) {
				let findPromo = this.props.allPromotions.find((data => data.id === this.props.selectedPromo))
				this.setState({ selectedPromotionData: findPromo, id: findPromo.id, title: findPromo.title, description: findPromo.description, images: findPromo.template_id, date: findPromo.end_time })
			}
		}

	}

	deleteOrderItem = (orderItemId) => {
		this.props.deleteSubPromotions([[orderItemId]])
		this.props.getSubPromotions([this.state.id])
		this.props.flag()
		this.setState({ clickCounter: 0 });
	}

	handleOpenOrderItemsList = () => {
		this.setState({ open: true })
	}

	handleCloseOrderItemsList = () => {
		this.setState({ open: false })
	}

	handleSelectOrderItem = (value) => {
		this.setState({ orderItemSelected: true, selectedOrderItem: value })
	}

	handleAddOrderItem = () => {
		const orderItemId = this.state.selectedOrderItem;
		const promotionId = this.props.selectedPromo;
		this.props.assignSubPromotion([orderItemId, promotionId])
		this.props.getSubPromotions([promotionId])
		this.props.getSubPromotions([null])
		this.setState({ open: false, selectedOrderItem: null })
	}

	editOrderItem = (value, isEditIconClicked) => {
		this.props.editOrderItem('open', value)
		this.setState({ isEditIconClicked: isEditIconClicked });
	}

	handleSelectPreviewPromotion = (indexOfPromo) => {
		let handleIndex = this.state.clickCounter

		if (this.state.selectedPreviewPromotion === indexOfPromo && handleIndex === 0) {
			handleIndex += 1
			this.setState({ clickCounter: handleIndex });
		} else if (this.state.selectedPreviewPromotion === indexOfPromo && handleIndex === 1 && !this.props.isExistBackdrop) {
			handleIndex = 0
			this.setState({ selectedPreviewPromotion: indexOfPromo, clickCounter: 0 });
		}
		
		if (this.state.selectedPreviewPromotion !== indexOfPromo){
			handleIndex = 0
			this.setState({ selectedPreviewPromotion: indexOfPromo, clickCounter: 0 });
		}

		if (handleIndex === 1) {
			this.props.handleOpenPreviewPromotionBackdrop(true)
		} else if (handleIndex === 0) {
				if (this.state.selectedPreviewPromotion !== indexOfPromo) {
					this.props.handleOpenPreviewPromotionBackdrop(false)
				}
		}
	}

	render() {
		const { merchantInfo, isExistBackdrop, isEditPromotion } = this.props;
		const { title, description, images, date, selectedPreviewPromotion, clickCounter, isEditIconClicked } = this.state;
		const logo = merchantInfo ? merchantInfo.logo : avatar;
		const styledPromItemMiddle = { height: isEditPromotion ? '705px' : '350px' }
		const isDisable = isEditIconClicked ? 'disabled' : 'undisabled'

		return (
			<Grid className="preview-promotion-bordered border-radius shadow" item container justify="center" direction="column" alignItems="center">
				<Grid className="preview-promotion-item preview-promotion-item--start" item container>
					<Grid className="preview-promotion-item-img preview-promotion-item-img--left" item container xs={2} justify="flex-start">
						<img src={logo} />
					</Grid>
					<Grid className="preview-promotion-item-txt" item container xs={8} justify="center">
						<Typography className="preview-promotion-item-title">{title}</Typography>
						<Typography className="preview-promotion-item-label">{description}</Typography>
					</Grid>
					<Grid className="preview-promotion-item-img preview-promotion-item-img--right" item container xs={2} justify="flex-end">
						<img src={iconImages[images + '.png']} />
					</Grid>

					<Grid className="preview-promotion-item-date" container item direction='column' justify="flex-end" alignItems="center">
						<Typography>Valid Until:</Typography>
						<Typography>{this.props.editPromotions ? moment(this.state.date).format('YYYY-MM-DD hh:mm') : moment.unix(date).format('YYYY-MM-DD hh:mm')}</Typography>
					</Grid>
				</Grid>
				<Grid className="preview-promotion-item preview-promotion-item--middle" container item xs={12} justify="flex-start" style={styledPromItemMiddle}>
					{this.props.orderItemsForPromotion.map((data) => {
						return (
							<Grid onClick={() => { this.handleSelectPreviewPromotion(data.id) }}
								className={`preview-promotion-item-list ${selectedPreviewPromotion && selectedPreviewPromotion === data.id && clickCounter === 0 ? 'preview-promotion-item-list--active' : ''} 
									preview-promotion-item-list--${selectedPreviewPromotion === data.id && clickCounter === 1 && isExistBackdrop ? 'hidden' : 'visible'}
									preview-promotion-item-list--${isDisable}
								`}
								container
								item xs={12}
								key={data.id}
							>
								<Grid
									className={`preview-promotion-item-second-list-view preview-promotion-item-second-list-view--${selectedPreviewPromotion === data.id && clickCounter === 1 && isExistBackdrop ? 'visible' : 'hidden'}`}
									item
									container
								>
									<Grid container direction='column'>
										<Grid className="preview-promotion-item-box-first" item xs={12}>
											<Typography className='preview-promotion-item-second-list-view-title'>{data.title}</Typography>
										</Grid>
									</Grid>
									<Grid className="preview-promotion-item-box-second" container item xs={12}>
										<Grid className="preview-promotion-item-title" item xs={3}>
											<Typography className='preview-promotion-item-second-list-view-price'>{data.price} <span>{data.currency}</span></Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography className='preview-promotion-item-second-list-view-desc'>Spices your slice</Typography>
											<Grid item container xs={12} justify="center">
												{/* <Grid item xs={4}> */}
												<Grid className="preview-promotion-item-second-list-small-topic">
													<img className='preview-promotion-item-photos-img--avatar'
													// src={orderItemData ? orderItemData.images ? orderItemData.images.img_1  : null : null}  
													/>
												</Grid>
												{/* </Grid> */}
												{/* <Grid item xs={4}> */}
												<Grid className="preview-promotion-item-second-list-small-topic preview-promotion-item-second-list-small-topic--middle">
													<img className='preview-promotion-item-photos-img--avatar'
													// src={orderItemData ? orderItemData.images ? orderItemData.images.img_2 : null : null} 
													/>
												</Grid>
												{/* </Grid> */}
												{/* <Grid item xs={4}> */}
												<Grid className="preview-promotion-item-second-list-small-topic">
													<img className='preview-promotion-item-photos-img--avatar'
													// src={orderItemData ? orderItemData.images ? orderItemData.images.img_3 : null : null} 
													/>
												</Grid>
												{/* </Grid> */}
											</Grid>
										</Grid>
										<Grid className="preview-promotion-item-second-list-img" item xs={3}>
											<img src={data.logo_url} />
										</Grid>
									</Grid>
								</Grid>
								<>
									<Grid className="preview-promotion-item-list-logo" item container xs={2} justify="center" alignItems="center">
										<img src={data.logo_url} />
									</Grid>
									<Grid item xs={8} className='preview-promotion-item-list-txt'>
										<Typography>{data.title}</Typography>
										<Typography>{data.price}  {data.currency}</Typography>
									</Grid>
									<Grid item xs={2} className='preview-promotion-item-list-icons'>
										<CreateIcon 
											onClick={this.editOrderItem.bind(this, data.id, true)} 
											style={{ pointerEvents: isEditIconClicked ? 'none' : 'auto' }}
											/>
										<DeleteIcon 
											onClick={this.deleteOrderItem.bind(this, data.id)} 
											style={{ pointerEvents: isEditIconClicked ? 'none' : 'auto' }}	
										/>
									</Grid>
								</>
							</Grid>
						)
					})}
				</Grid>

				<Grid className="preview-promotion-item preview-promotion-item--button" item container>
					<Button
						onClick={this.handleOpenOrderItemsList}
						variant="outlined"
						endIcon={<AddCircleRoundedIcon />}
					>
						Add New Order Item
					</Button>
				</Grid>
				<Dialog
					open={this.state.open}
					className='preview-promotions-dialog'
				>
					<DialogTitle className='preview-promotions-dialog-text'>Add Order Item:</DialogTitle>
					<DialogContent className='preview-promotions-dialog-content'>
						{this.props.subPromotions.map((data) => {
							if (data.state === 0) {
								return (
									<Grid item xs={12} className={`preview-promotions-dialog-content-text ${this.state.orderItemSelected ? this.state.selectedOrderItem === data.id ? 'preview-promotions-dialog-content-text--color' : '' : ''}`} onClick={this.handleSelectOrderItem.bind(this, data.id)} key={data.id}>
										<Typography>{data.title}</Typography>
									</Grid>
								)
							}
						})}
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							className='promotion-main-outlined-buttons'
							onClick={this.handleCloseOrderItemsList}
						>
							Cancel
					</Button>
						<Button
							variant="contained"
							color="primary"
							className='promotion-main-buttons promotion-main-buttons-dialog'
							onClick={this.handleAddOrderItem}
						>
							Add
					</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		);
	}
}

PreviewPromotions.propTypes = {
	deleteSubPromotions: PropTypes.func.isRequired,
	getSubPromotions: PropTypes.func.isRequired
};

export default connect(({ common, promotions, auth }) => {
	return {
		allPromotions: promotions.allPromotions,
		merchantInfo: common.merchantInfo,
		orderItemsForPromotion: promotions.orderItemsForPromotion,
		subPromotions: promotions.subPromotions,
	}
}, (dispatch) => {
	const actions = { deleteSubPromotions, getSubPromotions, assignSubPromotion };
	return bindActionCreators(actions, dispatch);
})(PreviewPromotions);
