import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Dialog, Grid, DialogTitle, Divider, Typography, FormControl, RadioGroup, FormControlLabel, Radio, SvgIcon, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { ReactComponent as BillboardIcon } from './images/billboard_icon.svg'
import { ReactComponent as BillboardIconActive } from './images/billboard_icon_active.svg'
import { ReactComponent as QRCode } from './images/qr_code_active.svg'
import { ReactComponent as NextActive } from './images/next_active.svg'
import Chart from 'react-apexcharts'

import './styles.css'

// const billboardIconActive = require('./images/billboard_icon_active.svg')
// const billboardIcon = require('../../assets/images/billboard_icon.svg')

class Statistics extends Component {

	// this.state = {
          
	// 	series: [44, 55, 13, 43, 22],
	// 	options: {
	// 		chart: {
	// 			width: 380,
	// 			type: 'pie',
	// 		},
	// 		labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
	// 		responsive: [{
	// 			breakpoint: 480,
	// 			options: {
	// 				chart: {
	// 					width: 200
	// 				},
	// 				legend: {
	// 					position: 'bottom'
	// 				}
	// 			}
	// 		}]
	// 	},
	
	
	// };
	state = {
		isPieChartModalOpen: false,
		series: [66, 20, 7, 7],
		options: {
			chart: {
				width: 380,
				type: 'pie',
			},
			labels: ['8 km', '5 km', '3 km', '1 km'],
			responsive: [{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}],
			dataLabels: {
				formatter: function(val, opts) {
					return val + '%'
				}
			},
			colors: ['#cf5ada', '#e7acec', '#f3d5f6', '#47c43d'],
			plotOptions: {
				pie: {
					startAngle: 135
				}
			},
			legend: {
				markers: {
					size: 20,
					shape: 'square'
				}
			}
		}
	}

	handlePieChartModal = flag => {
		this.setState({ isPieChartModalOpen: flag })
	}
	render() {
		const { openStatistics, handleStatisticsModal } = this.props
		return (
			<>
			<Dialog
				open={ openStatistics }
				className="settings-dialog"
			>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text settings-dialog-text--suspend-agent'>
					<ArrowBackIcon onClick={() => handleStatisticsModal(false)} className="order-item-edit-dialog-back-btn" />
						Statistics
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Typography className="statistics-for">Show Statistics for:</Typography>
				<Grid container className="statistics-days" sm={9}>
					<FormControl component="fieldset" className="statistics-days-formcontrol">
						<RadioGroup aria-label="period" className="statistics-days-radiogroup">
							<Grid item xs={12} sm={3} className="statistics-day">
								<FormControlLabel value="today" control={ <Radio color="primary" /> } label="Today" />
							</Grid>
							<Grid item xs={12} sm={3} className="statistics-day">
								<FormControlLabel value="seven-days" control={ <Radio color="primary" /> } label="Last 7 days" />
							</Grid>
							<Grid item xs={12} sm={3} className="statistics-day">
								<FormControlLabel value="thirty-days" control={ <Radio color="primary" /> } label="Last 30 days" />
							</Grid>
							<Grid item xs={12} sm={3} className="statistics-day">
								<FormControlLabel value="first" control={ <Radio color="primary" /> } label="From My 1st Promo Ever" />
							</Grid>
						</RadioGroup>
					</FormControl>
				</Grid>
				<div className="settings">
				<Grid className="settings-container statistics-container" container item xs={5} alignItems="flex-start">
					<Grid className="settings-wrap" container item direction="column">
						<Typography className="settings-wrap-title">Followers</Typography>
						<Grid className="settings-wrap-box statistics-wrap-box shadow border-radius" container item>
							<Grid item container className="settings-item">
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenMembership}>
									<FavoriteBorderIcon />
									<Typography>Number of Followers in total:</Typography>
									<Typography component="span" color="primary">150</Typography>
								</Grid>
								<Divider className='settings-divider'/>
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
									<FavoriteBorderIcon />
									<Typography>Number of New Followers (for specified period):</Typography>
									<Typography component="span" color="primary">32</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid className="settings-wrap settings-wrap--gapper" container item direction="column">
						<Typography className="settings-wrap-title">Promos / Order Items</Typography>
						<Grid className="settings-wrap-box statistics-wrap-box shadow border-radius" container item>
							<Grid item container className="settings-item settings-item--stats">
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={() => this.handleStatisticsModal(true)}>
									{/* <TrendingUpIcon className="settings-icon" /> */}
									<SvgIcon color="primary" component={ BillboardIconActive } />
									<div>
										<Typography>Most Successful Promo (All Times):</Typography>
										<Typography><Typography component="span" color="primary">Super Promo 07</Typography> (<Typography component="span" color="primary">click</Typography> for preview)</Typography>
									</div>
								</Grid>
								<Divider className='settings-divider' />
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={() => this.handleStatisticsModal(true)}>
									<SvgIcon color="primary" component={ BillboardIconActive } />
									<div>
										<Typography>Most Successful Promo (for specified period):</Typography>
										<Typography><Typography component="span" color="primary">Current Promo 01</Typography> (<Typography component="span" color="primary">click</Typography> for preview)</Typography>
									</div>
								</Grid>
								<Divider className='settings-divider' />
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={() => this.handleStatisticsModal(true)}>
									<SvgIcon color="primary" component={ BillboardIcon } />
									<div>
										<Typography>Most Successful Order Item (All Times):</Typography>
										<Typography><Typography component="span" color="primary">Order Item 543</Typography> (<Typography component="span" color="primary">click</Typography> for preview)</Typography>
									</div>
								</Grid>
								<Divider className='settings-divider' />
								<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={() => this.handleStatisticsModal(true)}>
									<SvgIcon color="primary" component={ BillboardIcon } />
									<div>
										<Typography>Most Successful Order Item (for specified period):</Typography>
										<Typography><Typography component="span" color="primary">Order Item 601</Typography> (<Typography component="span" color="primary">click</Typography> for preview)</Typography>
									</div>
								</Grid>
								{/* <Divider className='settings-divider' /> */}
							</Grid>
						</Grid>
					</Grid>
					</Grid>
					<Grid className="settings-container settings-container--blank" container item xs={1}></Grid>
					<Grid className="settings-container statistics-container" container item xs={5} alignItems="flex-start">
						<Grid className="settings-wrap" container item direction="column">
							<Typography className="settings-wrap-title">Interaction Graphs</Typography>
							<Grid className="settings-wrap-box statistics-wrap-box shadow border-radius" container item>
								<Grid item container className="settings-item">
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenMembership}>
										<BarChartIcon className="settings-icon" />
										<Typography>Level of Daily Client Interaction by Hours (<Typography component="span" color="primary" className="link">view</Typography> graph)</Typography>
									</Grid>
									<Divider className='settings-divider'/>
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
										<PieChartIcon className="settings-icon" />
										<Typography>Pie Chart of Client Distance (<Typography onClick={() => this.handlePieChartModal(true)} component="span" color="primary" className="link">view</Typography> graph)</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid className="settings-wrap" container item direction="column">
							<Typography className="settings-wrap-title">Interaction Graphs</Typography>
							<Grid className="settings-wrap-box statistics-wrap-box shadow border-radius" container item>
								<Grid item container className="settings-item">
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenMembership}>
										<BarChartIcon className="settings-icon" />
										<Typography>Most Active Agent: <Typography component="span" color="primary">John Doe</Typography> / <Typography component="span" color="primary">42</Typography> interactions</Typography>
									</Grid>
									<Divider className='settings-divider'/>
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
										<PieChartIcon className="settings-icon" />
										<Typography>Less Active Agent: <Typography component="span" color="primary">Lazy Luke</Typography> / <Typography component="span" color="primary">17</Typography> interactions</Typography>
									</Grid>
									<Divider className='settings-divider'/>
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
										<PieChartIcon className="settings-icon" />
										<Typography>Number of Blocked Clients (for specified period):</Typography>
										<Typography component="span" color="primary">1</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid className="settings-wrap" container item direction="column">
							<Typography className="settings-wrap-title">QR code</Typography>
							<Grid className="settings-wrap-box statistics-wrap-box shadow border-radius" container item>
								<Grid item container className="settings-item">
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenMembership}>
										<div className="statistics-qr-icons reverse">
											<SvgIcon component={ QRCode } className="no-margin" />
											<SvgIcon component={ NextActive } className="no-margin" />
										</div>
										<Typography className="statistics-qr-label">Number of QR Code Requests:</Typography>
										<Typography component="span" color="primary">450</Typography>
									</Grid>
									<Divider className='settings-divider'/>
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
										<div className="statistics-qr-icons">
											<SvgIcon component={ QRCode } className="no-margin"/>
											<SvgIcon component={ NextActive } className="no-margin"/>
										</div>
										<Typography className="statistics-qr-label last">Number of Sent QR Codes:</Typography>
										<Typography component="span" color="primary">450</Typography>
									</Grid>
									<Divider className='settings-divider'/>
									<Grid item container alignItems="center" className="settings-wrap-of-item statistics-wrap-of-item stat" onClick={this.handleOpenEmailVerification}>
										<SvgIcon component={ QRCode } />
										<Typography>Nubmer of Scanned Codes (successful transaction):</Typography>
										<Typography component="span" color="primary">420</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Dialog>
			<Dialog open={this.state.isPieChartModalOpen} className="pie-chart-dialog" maxWidth="lg">
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text settings-dialog-text--suspend-agent pie-chart-dialog-title'>
					Pie Chart of Client Distance (in km)
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Chart options={this.state.options} series={this.state.series} type="pie" width={700} className="pie-chart" />
				<Button onClick={() => this.handlePieChartModal(false)} className="pie-chart-close" variant="outlined" color="primary">Close</Button>
			</Dialog>
			</>
		)
	}
}

Statistics.propTypes = {
	userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.number,
}

export default connect(({auth}) => {
	return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
	}
}, (dispatch) => {
	return
})(Statistics)