import {
	GETOPENEDCHATS,
	GETMESSSAGESBEFORE,
	SETLASTCHATID,
  SHOWNOTEMESSAGE,
  ASSIGNCHAT,
  ALLOWCHATSCROLLING,
  SETMESSAGESTATE,
  SENDMESSAGE,
  SENDTYPINGSTATE,
  SHOWTYPINGOFF,
  LEAVECHAT,
  BEGINUPLOADMESSAGE
} from './constants';


export function getOpenedChats(params) {
  return {
    type: GETOPENEDCHATS,
    params,
  };
}

export function getMessagesBefore(params) {
  return {
    type: GETMESSSAGESBEFORE,
    params,
  };
}

export function setLastChatId(params) {
  return {
    type: SETLASTCHATID,
    params,
  };
}

export function showNoteMessage(params) {
  return {
    type: SHOWNOTEMESSAGE,
    params
  };
}

export function assignChat(params) {
  return {
    type: ASSIGNCHAT,
    params,
  };
}

export function allowChatScrolling() {
  return {
    type: ALLOWCHATSCROLLING,
  };
}

export function setChatMessageState(params) {
  return {
    type: SETMESSAGESTATE,
    params
  };
}

export function sendMessage(params) {
  return {
    type: SENDMESSAGE,
    params,
  };
}

export function sendTypingState(params) {
  return {
    type: SENDTYPINGSTATE,
    params
  };
}

export function showTypingOff(params) {
  return {
    type: SHOWTYPINGOFF,
    params
  };
}

export function leaveChat(params) {
  return {
    type: LEAVECHAT,
    params,
  };
}

export function beginUploadMessage(params) {
  return {
    type: BEGINUPLOADMESSAGE,
    params
  };
}
