import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { history }from '../../store/index';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GridContainer } from './styles';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import User from '@material-ui/icons/PersonOutlineOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import logo from '../../pages/promos/images/logo.svg'
import logoutIcon from '../../assets/shapes/logout.svg';
import './styles.css';

class Header extends Component {

  componentDidMount() {
  }

  componentWillUnmount() {
  }
  
  handleClick = () => {
    if (this.props.action !== undefined) {
      //used when single chat was opened -> (back to all messages)
      this.props.action();
    } else {
      history.goBack();
    }
  };
  
  logout = () => {
    localStorage.removeItem('uid')
    localStorage.removeItem('userType')
    localStorage.removeItem('token')
    window.location = '/register'
    // history.push('/register');
  }

  handleHeaderAsideIcon = (title) => {
    switch (title) {
      case 'My Biiz Card':
        return <Grid className="header-aside-icon"><User /><MenuOutlinedIcon /></Grid>
      
      case 'Messages':
        return <Grid className="header-aside-icon"><ChatOutlinedIcon /></Grid>
      
      case 'Settings':
        return <Grid className="header-aside-icon"><SettingsOutlinedIcon /></Grid>

      case 'Promos & Order Items':
        return <Grid className="header-aside-icon"><InsertChartOutlinedOutlinedIcon /></Grid>

      default:
        return null
    }
  }

  handleRedirect = () => {
    history.push('/dashboard')
  }

  render() {
    let backBtn = null;
    let title = null;
    let logout = '';
    let headerAsideTitle = this.props.headerAsideTitle ? this.props.headerAsideTitle : null

    if (this.props.showBackBtn) {
      backBtn = (
        <Grid>
          <ArrowBackIcon className="header-back-btn" onClick={this.handleClick} />
        </Grid>);
    }

    if (this.props.title) {
      title = (
        <Grid container item xs={4} justify="center" alignItems="center">
          <Typography className="header-text">{this.props.title}</Typography>
        </Grid>
      )
    } else {
      title = (
        <Grid container item xs={4} justify="center" alignItems="center">
          <img className="header-logo" src={logo} alt="logo" draggable={false} />
          <div className="header-text">Biiz</div>
        </Grid>
      )
    }
    if (this.props.logout) {
      logout = (
        <Grid className="header-logout" item container xs={4} justify="flex-end" alignItems="center">
          <Grid item container justify="flex-end" onClick={this.logout}>
            <img src={ logoutIcon } alt="logo" draggable={false} />
            <span className="header-logout-text">Log out</span>
          </Grid>
        </Grid>
      )
    }
    return (
      <GridContainer item xs={12} sm container className={`gridCustom header`}>
        <Grid container item xs={4} justify="flex-start" alignItems="center">
          { this.props.headerAsideTitle && <Grid container className="no-wrap" item xs={12} direction="row" alignItems="center">
            <Grid className="header-aside-logo" onClick={this.handleRedirect}>
              <Grid className="header-aside-logo-cirlce">
                <Grid className="header-aside-quarter header-aside-quarter--1"></Grid>
                <Grid className="header-aside-quarter header-aside-quarter--2">
                  <Grid className="header-aside-quarter-badge">{this.props.badgeNum}</Grid>
                </Grid>
                <Grid className="header-aside-quarter header-aside-quarter--3"></Grid>
                <Grid className="header-aside-quarter header-aside-quarter--4"></Grid>
                <Grid className="header-aside-cutout"></Grid>
              </Grid>
              <Typography>Home</Typography>
            </Grid>
            <div className="header-title-aside ellipsis" title={ headerAsideTitle }>{ headerAsideTitle }</div>
            {this.handleHeaderAsideIcon(this.props.headerAsideTitle)}
          </Grid>}
          {backBtn}
        </Grid>
          {title}
          {logout}
      </GridContainer>
    );
  }
}

Header.propTypes = {
  userType: PropTypes.number.isRequired,
  info: PropTypes.object,
  img: PropTypes.string,
  logout: PropTypes.bool,
  title: PropTypes.string,
  showBackBtn: PropTypes.bool
};

export default connect(({auth}) => {
  return {
    userType: auth.userType,
  }
}, (dispatch) => {
  const actions = { };
  return bindActionCreators(actions, dispatch);
})(Header);
