import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, FormControl, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Checkbox } from '@material-ui/core';
import './styles.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/CheckCircle';
import {	registerAgent, getAgents, editAgent } from '../../store/common/actions';

class CreateAgent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username:'',
			usernameError: false,
			usernameErrorMsg: '',
			password: '',
			passwordError: false,
			passwordErrorMsg: '',
			passwordConfirm: '',
			qrCode: false,
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.agentData) {
			if(prevProps.agentData !== this.props.agentData) {
				this.setState({
					username: this.props.agentData.name,
					qrCode: this.props.agentData.enable_qr_code
				})
			}
		}
	}

  handleTitleChange = (event) => {
    this.setState({username: event.target.value});
  };

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
  };

  handlePasswordConfirm = (event) => {
    this.setState({passwordConfirm: event.target.value});
  };

	handleQrCode = (event) => {
		this.setState({qrCode: event.target.checked})
	}

	handleCloseCreateAgent = () => {
		this.setState({
			username: '',
			usernameError: false,
			usernameErrorMsg: '',
			password: '',
			passwordConfirm: '',
			passwordError: false,
			passwordErrorMsg: '',
			qrCode: false
		});
		this.props.handleCloseCreateAgent();
	}

	handleSaveAgent = () => {
    if(this._validate(true)){
			if(this.props.agentData) {
				this.props.editAgent([this.props.agentData.uid, this.state.username, this.state.password, this.state.qrCode])
			} else {
				this.props.registerAgent([this.state.username, this.state.password, this.state.qrCode]);
			}
			this.setState({
				username: '',
				usernameError: false,
				usernameErrorMsg: '',
				password: '',
				passwordConfirm: '',
				passwordError: false,
				passwordErrorMsg: '',
				qrCode: false
			});
			this.props.getAgents();
      this.props.handleCloseCreateAgent();
    }
	}

  _validate = (draft) => {
		const { username, password, passwordConfirm} = this.state;
    let error = false;
		let checkTitle = this.props.savedAgents.find(data => data.name === username);

		if (checkTitle) {
			// if (this.props.editAgentData) {
			// 	if (this.props.editAgentData.uid !== checkTitle.uid) {
			// 		this.setState({ titleError: 'Name already exists' });
			// 		error = true;
			// 	}
			// } else {
				this.setState({ usernameError: true, usernameErrorMsg: 'Name already exists' });
				error = true;
			// }
		}
    if(username === ''){
      this.setState({usernameError: true, usernameErrorMsg: "Invalid agent name"});
      error = true;
    }
    if(password === ''){
      this.setState({passwordError: true, passwordErrorMsg: "Invalid agent password"});
      error = true;
    }
    if(passwordConfirm !== '' && passwordConfirm !== password){
      this.setState({passwordError: true, passwordErrorMsg: "Password don`t match"});
      error = true;
    }
		if(passwordConfirm === ''){
      this.setState({passwordError: true, passwordErrorMsg: "Invalid agent password"});
      error = true;
    }
    return (error) ? false : true;
  };

	render() {
		const {	username, password, passwordError, passwordErrorMsg, passwordConfirm, qrCode, usernameError, usernameErrorMsg } = this.state;
		let icons = {};
    let check = (<CheckIcon className="settings-create-agent-circle"/>);
    let iconsDefaults = {
      username: (
        <div className="settings-create-agent-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="settings-create-agent-num">1</Typography>
        </div>),
      password: (
        <div className="settings-create-agent-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="settings-create-agent-num">2</Typography>
        </div>),
			passwordConfirm: (
        <div className="settings-create-agent-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="settings-create-agent-num">3</Typography>
        </div>),
			qrCode: (
        <div className="settings-create-agent-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="settings-create-agent-num">4</Typography>
        </div>),
    };
    
    icons.username = username === "" ? iconsDefaults.username : check;
    icons.password = password === "" ? iconsDefaults.password : check;
		icons.passwordConfirm = passwordConfirm === "" ? iconsDefaults.passwordConfirm : check;
		icons.qrCode = qrCode === false ? iconsDefaults.qrCode : check;
		return (
			<Dialog
				open={this.props.openCreateAgent}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text settings-dialog-text--create-agent'>
					<ArrowBackIcon onClick={this.handleCloseCreateAgent} className="order-item-edit-dialog-back-btn" />
					Create Agent
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogContent className='settings-create-agent-content popup-content-xs'>
					<Grid item xs={12}	container	 className='settings-create-agent-inline-grid'>
						<Grid item	container className="settings-create-agent-title">
							{icons.username}
							<Typography className="settings-create-agent-step-name">Enter Agent Name Here</Typography>
						</Grid>
						<FormControl variant="filled">
							<TextField
								className='settings-create-agent-input'
								required={true}
								onChange={this.handleTitleChange}
								InputLabelProps={{
									style: {fontSize: '20px', color: '#969696', lineHeight: 1, fontWeight: 300}
								}}
								label="Enter Agent ID Here"
								variant="filled"
								defaultValue={username !== "" ? username : ""}
								size="small"
								error={usernameError}
								helperText={usernameError ? usernameErrorMsg : ''}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}	container	 className='settings-create-agent-inline-grid'>
						<Grid item	container className="settings-create-agent-title">
							{icons.password}
							<Typography className="settings-create-agent-step-name">Password</Typography>
						</Grid>
						<FormControl variant="filled">
							<TextField
								className='settings-create-agent-input'
								required={true}
								onChange={this.handlePasswordChange}
								InputLabelProps={{
									style: {fontSize: '20px', color: '#969696', lineHeight: 1, fontWeight: 300}
								}}
								label="Enter Password Here"
								variant="filled"
								defaultValue={password !== "" ? password : ""}
								size="small"
								type="password"
								error={passwordError}
								helperText={passwordError ? passwordErrorMsg : ''}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}	container	 className='settings-create-agent-inline-grid'>
						<Grid item container	className="settings-create-agent-title">
							{icons.passwordConfirm}
							<Typography className="settings-create-agent-step-name">Confirm Password</Typography>
						</Grid>
						<FormControl variant="filled">
							<TextField
								className='settings-create-agent-input'
								required={true}
								onChange={this.handlePasswordConfirm}
								InputLabelProps={{
									style: {fontSize: '20px', color: '#969696', lineHeight: 1, fontWeight: 300}
								}}
								label="Repeat Password Here"
								variant="filled"
								defaultValue={passwordConfirm !== "" ? passwordConfirm : ""}
								size="small"
								type="password"
								error={passwordError}
								helperText={passwordError ? passwordErrorMsg : ''}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} container className='settings-create-agent-inline-grid-checkbox'>
						{icons.qrCode}
						<Typography className="settings-create-agent-step-name">Enable Qr Code</Typography>
						<Checkbox
							className='settings-create-agent-checkbox'
							checked={qrCode}
							onChange={this.handleQrCode}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					</Grid>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							className='settings-main-buttons setting-manage-templates-buttons--width'
							onClick={this.handleSaveAgent}
						>
							Save Agent
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		)
	}
}

CreateAgent.propTypes = {
  userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.number,
};
 
export default connect(({common, auth}) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
		savedAgents: common.savedAgents
  }
}, (dispatch) => {
  const actions = {registerAgent, getAgents, editAgent};
  return bindActionCreators(actions, dispatch);
})(CreateAgent);