import {
	GETPROMOTIONS,
	GETPROMOTIONS_RESULT,
	GETPROMOTIONS_ERROR,
	GETSUBPROMOTIONS,
	GETSUBPROMOTIONS_RESULT,
	GETSUBPROMOTIONS_ERROR,
  DELETESUBPROMOTIONS,
  DELETESUBPROMOTIONS_RESULT,
  DELETESUBPROMOTIONS_ERROR,
  RECREATEPROMOTION,
  RECREATEPROMOTION_RESULT,
  RECREATEPROMOTION_ERROR,
  SETPROMOTIONLONG,
  SETPROMOTIONLONG_RESULT,
  SETPROMOTIONLONG_ERROR,
  PUBLISHPROMOTION,
  PUBLISHPROMOTION_RESULT,
  PUBLISHPROMOTION_ERROR,
  ENDPROMOTION,
  ENDPROMOTION_RESULT,
  ENDPROMOTION_ERROR,
  ASSIGNSSUBPPROMOTION,
  DELETEPROMOTIONS,
  DELETEPROMOTIONS_RESULT,
  DELETEPROMOTIONS_ERROR,
  SETSUBPROMOTION,
  SETSUBPROMOTION_RESULT,
  SETSUBPROMOTION_ERROR,
  PUBLISHSUBPROMOTION,
  PUBLISHSUBPROMOTION_RESULT,
  PUBLISHSUBPROMOTION_ERROR,
  UNPUBLISHSUBPROMOTION,
  UNPUBLISHSUBPROMOTION_RESULT,
  UNPUBLISHSUBPROMOTION_ERROR
} from './constants';
import errorsLib from '../../lib/errorsLib';

let initialState = {
  allPromotions: [],
	promotions: [],
	activePromotions: [],
	expiredPromotions: [],
	promotionForPublish: {},
	clipboard: {},
  subPromotions: [],
  orderItemsForPromotion: [],
  savedPromotion: -1
}
import { sendMessage } from '../comm/sender';

export default (state = initialState, action) => {
	switch (action.type) {
		case GETPROMOTIONS: {
      sendMessage('getPromotions', action.params);
      return { ...state }
    }
		case GETPROMOTIONS_RESULT: {
      _log(action, state);
      let requestType = action.params.request.params;
      if (requestType[0] === null) {
        //all promotions
        //draft promotions

        if (requestType[1] === 0) {
          return { ...state, allPromotions: action.params.params[0] };
        }

        if (requestType[1] === 1) {
          return { ...state, promotions: action.params.params[0] };
        }
        //active promotion
        if (requestType[1] === 2) {
          return { ...state, activePromotions: action.params.params[0] };
          // sendMessage('getSubPromotions', [action.params.params[0][0].id]);
        }
        //expired promotion
        if (requestType[1] === 3) {
          return { ...state, expiredPromotions: action.params.params[0] };
        }
      } else {
        //one promotion
        return { 
          ...state, 
          promotionForPublish: action.params.params[0][0],
          clipboard: action.params.params[0][0] };
      }
    }
    break;
    case GETPROMOTIONS_ERROR: {
      return {...state}
    }
		case GETSUBPROMOTIONS: {
      sendMessage('getSubPromotions', action.params);
      return {...state}
    }
    case GETSUBPROMOTIONS_RESULT: {
      _log(action, state);
      let requestType = action.params.request.params;
      if (requestType[0] === null) {
        return { ...state, subPromotions: action.params.params[0] };
      } else {
        return { ...state, orderItemsForPromotion: action.params.params[0] };
      }
    }
    case GETSUBPROMOTIONS_ERROR: {
      return {...state}
    }
    case DELETESUBPROMOTIONS:
      sendMessage('deleteSubPromotions', action.params);
      return {...state}
    case DELETESUBPROMOTIONS_RESULT:
      return {...state}
    case DELETESUBPROMOTIONS_ERROR:
      return {...state}
    case RECREATEPROMOTION:
      sendMessage('recreatePromotion', action.params);
      return {...state}
    case RECREATEPROMOTION_RESULT:
      sendMessage('getPromotions', [null, 1]);
      return {...state}
    case RECREATEPROMOTION_ERROR:
      break;
    case SETPROMOTIONLONG:
      sendMessage('setPromotionLong', action.params);
      return{...state}
    case SETPROMOTIONLONG_RESULT: {
      let id2 = action.params.params[0];
      sendMessage('getPromotions', [[id2]]);
      sendMessage('getPromotions', [null, 1]);
      return{...state}
    }
    case SETPROMOTIONLONG_ERROR:
      return{...state}
    case PUBLISHPROMOTION:
      sendMessage('publishPromotion', action.params);
      return{...state}
    case PUBLISHPROMOTION_RESULT:
      return{...state}
    case PUBLISHPROMOTION_ERROR: {
      let promo_error = errorsLib('promotion_default', []);
      switch(action.params.params[0]){
        case 3:
          promo_error = errorsLib('end_piip_day', [action.params.params[1]]);
          break;
      }
      return { ...state, errorMsg: promo_error };
    }
    //endPromotion//
    case ENDPROMOTION:
      sendMessage('endPromotion', action.params);
      return{...state}
    case ENDPROMOTION_RESULT:
      return{...state}
    case ENDPROMOTION_ERROR:
      return{...state}
    case ASSIGNSSUBPPROMOTION:
      sendMessage('assignSubPromotionToPromotion', action.params);
      return{...state}
    case DELETEPROMOTIONS:
      _log(action, state);
      sendMessage('deletePromotions', action.params);
      return Object.assign({}, state, {
        forDelete: action.params[0][0]
      });
    case DELETEPROMOTIONS_RESULT:
      _log(action, state);
      return Object.assign({}, state, {
        promotions: state.promotions.filter(item => item.id !== state.forDelete),
        expiredPromotions: state.expiredPromotions.filter(item => item.id !== state.forDelete),
        forDelete: -1,
      });
    case DELETEPROMOTIONS_ERROR:
      return{...state}
    case SETSUBPROMOTION:
      sendMessage('setSubPromotion', action.params);
      //return {...state, clipboard: action.params[0]}
      return {...state}
    case SETSUBPROMOTION_RESULT:
      _log(action, state);
      return {...state}
      // return { ...state, savedPromotion: id };
    case SETSUBPROMOTION_ERROR:
      return {...state}
    //publishSubPromotion//
    case PUBLISHSUBPROMOTION:
      sendMessage('publishSubPromotion', action.params);
      return {...state}
    case PUBLISHSUBPROMOTION_RESULT:
      return {...state}
    case PUBLISHSUBPROMOTION_ERROR:
      return {...state}
    //unpublishSubPromotion//
    case UNPUBLISHSUBPROMOTION:
      sendMessage('unpublishSubPromotion', action.params);
      return {...state}
    case UNPUBLISHSUBPROMOTION_RESULT:
      return {...state}
    case UNPUBLISHSUBPROMOTION_ERROR:
      return {...state}
		
		default: 
		return {...state}

	}
}


function _log(action, state) {
  if (!process.env.debug) return;
  let css = 'color: #DBC144';
  if (action.type.indexOf('_RESULT') >= 0) {
    css = 'color: green';
  } else if (action.type.indexOf('_ERROR') >= 0) {
    css = 'color: red';
  }
  console.log('>>>>>>> %c' + action.type + '  ---', css);
  console.log('params: ', action.params);
  console.log('state: ', state);
  console.log('------------');
}