import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@material-ui/core';
import './styles.css';

class IconList extends Component {
  
  renderIconList = () => {
    return Object.keys(this.props.icons).map( (image) =>
      {
        let id = parseInt(image.match(/\d/g).join(""));
        let iconListImgSelected = id === this.props.choose ? 'selected' : 'initial'

        return (
          <img key={id} className={`icon-list-image  icon-list-image--${iconListImgSelected}`} src={this.props.icons[image]} alt={image} onClick={this.handleClick.bind(this, id)}/>
        );
      }
    );
  };
  
  handleClick = (id) => {
    this.props.handleClick(id);
  };
  
  render() {
    return (
      <DialogContent className="icon-list-modal">
        {this.renderIconList()}
      </DialogContent>
    );
  }
}

IconList.propTypes = {
  icons: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default IconList;
