import React, { Component } from 'react';
import Marker from '@material-ui/icons/PlaceOutlined';

export default class MapMarker extends Component {

  render() {
    return (
      <Marker
        className='map-searchbox-marker'
        color='primary'
      />
    );
  }
}
