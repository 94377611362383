import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.css';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { getContacts } from '../../store/common/actions';
import { getUnreadMessagesCount, displayOpenedChat, assignedByAgent } from '../../lib/utils';

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: null,
			allData: [],
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userId !== this.props.userId) {
			this.setState({userId: this.props.userId})
		}
		if (!prevProps.data.userName && this.props.newMessage === true) {
      this.props.resetNewMessage();
      this.props.getContacts();
    }
		if (prevProps.allData !== this.props.allData) {
			this.setState({allData: this.props.allData})
		}
	}

  getOpenedChat = (uid) => {
    return displayOpenedChat(uid, this.props.openedChats);
  }

  getAssignedStatus = (uid) => {
    const agentId = this.props.uid
    return assignedByAgent(uid, this.props.openedChats, agentId)
  }

	getOpenedChatTimer = (uid, time) => {
    let localStorageChatTimer = localStorage.getItem('timer' + uid);
    if (localStorageChatTimer === null) {
      localStorage.setItem('timer' + uid, time);
      return time
    } else {
      return parseInt(localStorageChatTimer);
    }
  }

	render() {
		const { data } = this.props;
		const { userId } = this.state;
		let openedChat = this.getOpenedChat(data.id);
		let assignedBy = this.getAssignedStatus(data.id);
		let assignedMessage = '';
		if (openedChat === 'Opened chat') {
			if (assignedBy[0] === 'Assigned') {
				if (assignedBy[1] === this.props.uid) {
					assignedMessage = 'Active - assigned to me'
				} else {
					assignedMessage = 'Active - assigned to agent' //treba da handleam kada je assign by agent
				}
			} else {
				assignedMessage = 'Unanswered'
			}
		} else {
			assignedMessage = 'Answered'
		}
		const cnt = getUnreadMessagesCount(data.id);
		
		return (
			<Grid className={`messages-user-profile-main ${data.id === userId ? 'messages-user-profile-main--border' : 'messages-user-profile-main--border-off'}`} container onClick={this.props.handleUserSelect.bind(this, data.id, true)}>
				<Grid className="messages-box-items" container item xs={8} alignItems="center">
					<Grid className="messages-box--users-avatar">
					{ cnt > 0 ?
						<Grid className="messages-box--users-avatar-badge">
							<span>{cnt}</span>
						</Grid>
						: null }
						<img className="messages-box--users-avatar-img" src={data.avatar} alt={data.userName}/>
					</Grid>
					<Grid className="messages-box--users-name"><Typography>{data.userName}</Typography></Grid>
					<Grid className="messages-box--users-phone"><Typography>{data.phone}</Typography></Grid>
					<Grid className="messages-box--users-status">
						<span className={`messages-box--users-bullet messages-box--users-bullet--${data.online ? 'green' : 'red'}`}>&bull;</span> 
						<Typography>{data.online ? 'Online' : 'Offline'}</Typography>
					</Grid>
					<Grid className="messages-box--users-answered">
						<p>{assignedMessage}</p>
					</Grid>
					<Grid><Typography>{moment(data.lastMessageTime).format("YY-MM-DD hh:mm")}</Typography></Grid>
					<Grid><Typography>{data.userName}</Typography></Grid>
				</Grid>
				{/* <Grid className=".messages-box-items messages-box-items--right" container item xs={4} alignItems="center" justify="space-around">
						<Grid><Typography>{moment(data.lastMessageTime).format("YY-MM-DD hh:mm")}</Typography></Grid>
						<Grid><Typography>{data.userName}</Typography></Grid>
				</Grid> */}
			</Grid>
		)
	}
}

UserProfile.propTypes = {
  userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  uid: PropTypes.number,
};
 
export default connect(({auth, messages}) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
		onlineStatusChanged: messages.onlineStatusChanged
  }
}, (dispatch) => {
  const actions = {getContacts};
  return bindActionCreators(actions, dispatch);
})(UserProfile);