import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WrapperContent from '../../components/WrapperContent'
import Layout from '../../components/Layout';
import { Grid, Typography, TextField, Button, Icon, Divider, List, ListItem, Link} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getMerchantCard,
  getStoreCategories,
  getMerchantMaxDistance,
  uploadError,
  beginUpload,
  addMerchantMaxDistance,
  addMerchantCard,
  editQrCode
} from '../../store/common/actions';
import { connectWeb } from '../../store/auth/actions';
import { isReadonly }from '../../lib/utils';
import Map from '../../components/Map/Map';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmailIcon from '@material-ui/icons/Email';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DvrIcon from '@material-ui/icons/Dvr';
import BlockIcon from '@material-ui/icons/Block';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MembershipStatus from '../../components/MyMembershipStatus';
import EmailVerification from '../../components/EmailVerification';
import AutoReply from '../../components/AutoReply';
import ManageTemplates from '../../components/ManageTemplates';
import BlockedClients from '../../components/BlockedClients';
import About from '../../components/About';
import InviteCustomers from '../../components/InviteCustomers';
import CreateAgent from '../../components/CreateAgent';
import SuspendAgent from '../../components/SuspendAgent';
import { PrettoSlider } from './styles.js'
import './styles.css'
import Statistics from '../../components/Statistics';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      maxDistance: '',
      maxDistanceError: '',
      selectedLocation: null,
      geolocationDisabled: true,
      geolocationDisabledError: '',
      googleObject: {},
			merchantData: null,
			merchantCategories: {},
			merchantCategory: '',
      membershipOpen: false,
      emailVerificationOpen: false,
      openAutoReply: false,
      openManageTemplates: false,
      openBlockedClients: false,
      openAbout: false,
      openInviteCustomers: false,
      openCreateAgent: false,
      openSuspendAgent: false,
      agentSelected: false,
      agentData: null,
      openStatistics: false
    }
  }

  static getDerivedStateFromProps(props, state) {
		if (state.merchantData !== props.merchantInfo) {
			return {
				merchantData: props.merchantInfo,
				selectedLocation: { latitude: props.merchantInfo.location ? props.merchantInfo.location.latitude : 0 , longitude: props.merchantInfo.location ? props.merchantInfo.location.longitude : 0}
			}
		} else {
			return null;
		}
  }

	componentDidMount() {

    if (this.props.maxDistance && !this.state.maxDistance) {
      this.setState({maxDistance: this.props.maxDistance})
    }
    this.props.getMerchantCard();
		this._updateState();
    this.props.getStoreCategories();
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.maxDistance !== this.props.maxDistance) {
      this.setState({
        maxDistance: this.props.maxDistance
      });
    } else if (this.props.maxDistance && this.state.maxDistance === '') {
      this.setState({
        maxDistance: this.props.maxDistance
      });
    }
		if (prevProps.merchantInfo === null) {
				if (this.props.merchantInfo !== null) {
					this._updateState(this.props);
				}
		}
		if (this.props.merchantCategories && (prevState.merchantCategory === '') && this.props.merchantInfo) {
			Object.keys(this.props.merchantCategories).map((key) => {
				if (this.props.merchantCategories[key].id === this.props.merchantInfo.category) {
					this.setState({merchantCategory: this.props.merchantCategories[key].english})
				}
			})
		}
	}

	handleOpenMembership = () => {
		this.setState({
			membershipOpen: true
		})
	}

	handleCloseMembership = () => {
		this.setState({
			membershipOpen: false
		})
	}

  handleMembershipContact = () => {
	}

  _updateState = (data) => {
    let d = data === undefined ? this.props.merchantInfo : data.merchantInfo;
    if(d !== null){
      this.props.getMerchantMaxDistance();
    }
  };

	onUpdateGoogleObject = (googleObject) => {
		this.setState({googleObject: googleObject});
	}

	onLocationChanged = (data) => {
		let coords = data[0];
		let address = data[1] !== undefined ? data[1].formatted_address : '';
		this.setState({selectedLocation: coords});
		this.setState({address: address});
	};

	handleMaxDistanceChange = (event) => {
		let value = parseInt(event.target.value, 10);
    this.props.addMerchantMaxDistance([parseFloat(event.target.value)]);
		this.setState({ maxDistance: value});
		this.setState({ maxDistanceError: '' });
	}

	sliderChange = (event, value) => {
		this.setState({ maxDistance: value });
    this.props.addMerchantMaxDistance([parseFloat(value)]);
	}

	checkUrl = (path) => {
		if (path === '' || path === null) return true;

		// With https:  let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
		let regexp = /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ //eslint-disable-line
		return regexp.test(path);
  }

	handleChangeMerchantCategories = (event) => {
		let categoryId = null;
		Object.keys(this.props.merchantCategories).map((key) => {
			if (this.props.merchantCategories[key].english === event.target.value) {
				categoryId = this.props.merchantCategories[key].id
			}
		})
		this.setState({merchantCategory: event.target.value, selectedCategory: categoryId})
	}

	handlePhoneChange = (event) => {
		this.setState({phoneNumber: event.target.value})
	}

	handleSaveMerchantInfo = () => {
		if(!isReadonly()) {
      this._saveBiizCard();
			this.props.getMerchantCard();
    }
	}

	_saveBiizCard = () => {
		this.props.addMerchantMaxDistance([parseFloat(this.state.maxDistance)]);
    let data = {
      name: this.state.biizName,
      description: this.state.description,
      website: (this.state.website !== null) ? this.state.website : "",
			phone: this.state.phoneNumber ? this.state.phoneNumber : '',
      category: this.state.selectedCategory,
      address: this.state.address,
      location: this.state.selectedLocation,
    };
    this.props.addMerchantCard([data]);
	}

	handleCancelMerchantInfo = () => {
		let info = this.props.merchantInfo;
		let maxDistance = this.props.maxDistance;
		this.setState({
			selectedLocation: info.location,
			maxDistance
		})
	}
	
  handleOpenEmailVerification = () => {
		this.setState({
			emailVerificationOpen: true
		})
	}

  handleCloseEmailVerification = () => {
		this.setState({
			emailVerificationOpen: false
		})
	}
  handleOpenAutoReply = () => {
    this.setState({
			openAutoReply: true
		})
  }

  handleCloseAutoReplyMessage = () => {
    this.setState({
			openAutoReply: false
		})
  }

  handleOpenManageTemplates = () => {
    this.setState({
			openManageTemplates: true
		})
  }

  handleCloseManageTemplates = () => {
    this.setState({
			openManageTemplates: false
		})
  }

	handleOpenBlockedClients = () => {
		this.setState({
			openBlockedClients: true
		})
	}

	handleCloseBlockedClients = () => {
		this.setState({
			openBlockedClients: false
		})
	}

  handleOpenAbout = () => {
    this.setState({
      openAbout: true
    })
  }

  handleCloseAbout = () => {
    this.setState({
      openAbout: false
    })
  }

  handleOpenInviteCustomers = () => {
    this.setState({
      openInviteCustomers: true
    })
  }

  handleCloseInviteCustomers = () => {
    this.setState({
      openInviteCustomers: false
    })
  }

  handleOpenCreateAgent = () => {
    this.setState({
      openCreateAgent: true
    })
  }

  handleCloseCreateAgent = () => {
    this.setState({
      openCreateAgent: false,
      agentData: null,
      agentSelected: false
    })
  }

  handleOpenSuspendAgent = () => {
    this.setState({
      openSuspendAgent: true
    })
  }

  handleCloseSuspendAgent = () => {
    this.setState({
      openSuspendAgent: false
    })
  }

  handleAgentSelect = (data) => {
    this.setState({
      agentSelected: true,
      agentData: data
    })
  }
  handleEditAgent = () => {
    this.setState({
      openCreateAgent: true
    })
  }

  handleQrCode = () => {
    const { agentData } = this.state;
    const type = 1;
		this.props.editQrCode([agentData.uid, !agentData.enable_qr_code, parseInt(type)]);
    this.setState({
      agentSelected: false,
      agentData: null
    })
  }

  handleStatisticsModal = flag => {
    this.setState({ openStatistics: flag })
  }

  render() {
    const { agentSelected, agentData } =this.state;
    const { savedAgents } = this.props
    const selected = agentSelected ? 'settings-agent-selected' : '';
    const qrCode = agentSelected ? agentData.enable_qr_code ? 'settings-qrcode-true' : 'settings-qrcode-false' : 'promos-buttons';
    return ( 
      <WrapperContent header headerAsideTitle="Settings" logout={true}>
        <Layout>
          <div className="settings">
            <Grid className="settings-container" container item xs={5} alignItems="flex-start">
              <Grid className="settings-wrap" container item direction="column">
                <Typography className="settings-wrap-title">My Account</Typography>
                <Grid className="settings-wrap-box shadow border-radius" container item>
                  <Grid item container className="settings-item">
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={this.handleOpenMembership}>
                      <CheckBoxIcon className="settings-icon" />
                      <Typography>My membership status</Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={this.handleOpenEmailVerification}>
                      <EmailIcon className="settings-icon" />
                      <Typography>Email verification</Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={this.handleOpenAutoReply}>
                      <ListAltIcon className="settings-icon" />
                      <Typography>Auto replay message</Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={this.handleOpenManageTemplates}>
                      <DvrIcon className="settings-icon" />
                      <Typography>Manage templates</Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={this.handleOpenBlockedClients}>
                      <BlockIcon className="settings-icon" />
                      <Typography>Blocked Clients</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className="settings-wrap settings-wrap--gapper" container item direction="column">
                <Typography className="settings-wrap-title">Statistics</Typography>
                <Grid className="settings-wrap-box shadow border-radius" container item>
                  <Grid item container className="settings-item settings-item--stats">
                    <Grid item container alignItems="center" className="settings-wrap-of-item" onClick={() => this.handleStatisticsModal(true)}>
                      <TrendingUpIcon className="settings-icon" />
                      <Typography>View Statistics</Typography>
                    </Grid>
                    <Divider className='settings-divider' />
                    <Grid item container alignItems="center" className="settings-wrap-of-item">
                      <EmailIcon className="settings-icon" />
                      <Typography>Send me my statistics</Typography>
                      <Button
                        className="settings-item-btn"
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>arrow_forward_ios</Icon>}
                      >
                        Once a week
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className="settings-wrap" container item direction="column">
                <Typography className="settings-wrap-title">App</Typography>
                <Grid className="settings-wrap-box shadow border-radius" container item>
                  <Grid item container className="settings-item">
                    <Grid onClick={this.handleOpenInviteCustomers} item container alignItems="center" className="settings-wrap-of-item">
                      <PersonAddIcon className="settings-icon" />
                      <Typography>Invite Customers to Piip</Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid item container alignItems="center" className="settings-wrap-of-item">
                      <InfoIcon className="settings-icon" />
                      <Typography >
                        <Link href="https://support-z.piip.com/help/en-us/23-web-biiz" className='link-style'>
                          Help
                        </Link>
                      </Typography>
                    </Grid>
                    <Divider className='settings-divider'/>
                    <Grid onClick={this.handleOpenAbout} item container alignItems="center" className="settings-wrap-of-item">
                      <DonutLargeIcon className="settings-icon" />
                      <Typography>About</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className="settings-container settings-container--blank" container item xs={1}></Grid>

            <Grid className="settings-container" container item xs={5} alignItems="flex-start">
              <Grid className="settings-wrap settings-wrap--map" container item direction="column">
                <Typography className="settings-wrap-title settings-wrap-title--map">Map overview</Typography>
                <Grid className="settings-wrap-box settings-wrap-box--map shadow border-radius overflow-hidden" container item>
                  { this.props.merchantInfo ? <Map
                    onLocationChanged={isReadonly() ? null : this.onLocationChanged}
                    radius={this.state.maxDistance}
                    location={this.state.selectedLocation}
                    onUpdateGoogleObject={(isReadonly(localStorage.getItem('tokener'))) ? null : this.onUpdateGoogleObject}
                    googleObject={(localStorage.getItem('tokener')) ? null : this.state.googleObject}
                  /> : null }
                </Grid>
              </Grid>

              <Grid className="settings-wrap settings-wrap--gapper" container item direction="column">
                <Typography className="settings-wrap-title">Distance</Typography>
                <Grid className="settings-wrap-box settings-wrap-box--distance shadow border-radius" container item>
                  <Grid item container alignItems="flex-start">
                    <Grid container>
                      <Grid item container xs={9} alignItems="center">
                        <Typography>Maximum distance from clients which are able to see promotions</Typography>
                      </Grid>
                      <Grid item container xs={2} alignItems="center">
                        <TextField
                          className='settings-distance-input'
                          color="secondary"
                          variant="filled"
                          onChange={isReadonly() ? null : this.handleMaxDistanceChange}
                          value={this.state.maxDistance === '' ? 0 : this.state.maxDistance}
                        />
                      </Grid>
                      <Grid item container xs={1} alignItems="center">
                        <Typography>km</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                    <Grid item container xs={9} alignItems="center">
                        <PrettoSlider
                          onChange={this.sliderChange}
                          max={200}
                          min={0}
                          aria-labelledby="discrete-slider-custom"
                          value={this.state.maxDistance === '' ? 0 : this.state.maxDistance}
                        />
                      </Grid>
                      <Grid item container xs={2} alignItems="center"></Grid>
                      <Grid item container xs={1} alignItems="center"></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="flex-rows settings-wrap-content">
                <Typography className="settings-wrap-title">Agents</Typography>
                <Grid className="settings-wrap-box settings-wrap-box--agents shadow border-radius" container item direction="column">
                  <Grid className="settings-wrap-content" container item justify="center">
                    <Grid container item xs={5} direction="column" alignItems="center">
                      <Typography>Agents</Typography>
                      <Grid className="settings-wrap-rectangle">
                        <small>Agents user name</small>

                        <List>
                          {(savedAgents || []).map((data, index) => {
                            if (!data.suspended) {
                              return (
                                <ListItem
                                  key={index}
                                  onClick={this.handleAgentSelect.bind(this, data)}
                                  className={`settings-agent-select ${agentSelected ? agentData.uid === data.uid ? selected : '' : ''}`}
                                >
                                  {data.uid}
                                </ListItem>
                              )
                            }
                          })}
                        </List>
                      </Grid>
                    </Grid>
                    <Grid className="settings-container settings-container--blank" container item xs={1}></Grid>
                    <Grid container item xs={5} direction="column" alignItems="center">
                      <Typography>Suspended</Typography>
                      <Grid className="settings-wrap-rectangle">
                        <small>Agents user name</small>

                        <List>
                          {(savedAgents || []).map((data, index) => {
                            if (data.suspended) {
                              return (
                                <ListItem key={index} className="settings-suspend-agent-select">
                                  {data.name}
                                </ListItem>
                              )
                            }
                          })}
                        </List>
                      </Grid>
                    </Grid>

                    <Grid className="settings-wrap-of-item--agents" container item>
                      <ListItem className="settings-item">
                        <CheckIcon className='circle'/>
                        <Typography>Allow agents to make personal templates</Typography>
                      </ListItem>
                      <Divider className="settings-item-border-line settings-item-border-line--agents" />
                    </Grid>
                    <div className="settigs-agents-main-button">
                      <div className="flex-cell centered padded-v-tiny promos-buttons">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={this.handleOpenCreateAgent}
                          className="promos-buttons"
                        >
                          Create Agent
                        </Button>
                      </div>
                      <div className="flex-cell centered padded-v-tiny promos-buttons">
                        <Button
                          size="small"
                          disabled={agentSelected ? false : true}
                          variant="contained"
                          color="primary"
                          onClick={this.handleEditAgent}
                          className="promos-buttons"
                        >
                          Edit Agent
                        </Button>
                      </div>                      
                      <div className="flex-cell centered padded-v-tiny promos-buttons">
                        <Button
                          size="small"
                          className={qrCode}
                          disabled={agentSelected ? false : true}
                          variant="contained"
                          color="primary"
                          onClick={this.handleQrCode}
                        >
                          QR Code
                        </Button>
                      </div>
                      <div className="flex-cell centered padded-v-tiny promos-buttons">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={this.handleOpenSuspendAgent}
                          className="promos-buttons"
                        >
                          Suspend Agent
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
          <MembershipStatus
						openMembership={this.state.membershipOpen}
						handleCloseMembership={this.handleCloseMembership}
						handleMembershipContact={this.handleMembershipContact}
					/>
          <EmailVerification
						openEmailVerification={this.state.emailVerificationOpen}
						handleCloseEmailVerification={this.handleCloseEmailVerification}
					/>
          <AutoReply
            openAutoReply={this.state.openAutoReply}
            handleCloseAutoReplyMessage={this.handleCloseAutoReplyMessage}
          />
          <ManageTemplates
            openManageTemplates={this.state.openManageTemplates}
            handleCloseManageTemplates={this.handleCloseManageTemplates}
          />
          <BlockedClients
            openBlockedClients={this.state.openBlockedClients}
            handleCloseBlockedClients={this.handleCloseBlockedClients}
          />
          <About
            openAbout={this.state.openAbout}
            handleCloseAbout={this.handleCloseAbout}
          />
          <InviteCustomers
            openInviteCustomers={this.state.openInviteCustomers}
            handleCloseInviteCustomers={this.handleCloseInviteCustomers}
          />
          <CreateAgent
            openCreateAgent={this.state.openCreateAgent}
            handleCloseCreateAgent={this.handleCloseCreateAgent}
            agentData={agentData}
          />
          <SuspendAgent
            openSuspendAgent={this.state.openSuspendAgent}
            handleCloseSuspendAgent={this.handleCloseSuspendAgent}
          />
          <Statistics 
            openStatistics={ this.state.openStatistics }
            handleStatisticsModal={ this.handleStatisticsModal }
          />
        </Layout>
      </WrapperContent>
    );
  }
}

Settings.propTypes = {
  maxDistance: PropTypes.number,
  merchantInfo: PropTypes.object,
  getStoreCategories: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  connectWeb: PropTypes.func.isRequired,
  uid: PropTypes.number,
  getMerchantCard: PropTypes.func.isRequired,
  getMerchantMaxDistance: PropTypes.func,
  beginUpload: PropTypes.func,
  addMerchantMaxDistance: PropTypes.func,
  addMerchantCard: PropTypes.func,
};
 
export default connect(({common, auth}) => {
  return {
		merchantInfo: common.merchantInfo,
		maxDistance: common.maxDistance,
		merchantCategories: common.merchantCategories,
		isAuthorized: auth.isAuthorized,
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
    savedAgents: common.savedAgents
  }
}, (dispatch) => {
  const actions = {
    getMerchantCard,
    getStoreCategories,
    connectWeb,
    getMerchantMaxDistance,
    uploadError,
    beginUpload,
    addMerchantMaxDistance,
    addMerchantCard,
    editQrCode
  };
  return bindActionCreators(actions, dispatch);
})(Settings);