/* eslint-disable */
import store from '../index';
import { pairMessages, setLastSeen, saveMessage, setLastUserMessage } from '../../lib/utils';
import { UPLOAD_ERROR } from '../common/constants';
import { 
  UPLOADCOMPLETE,
  CONNECTWEB,
  LOGINAGENT,
  SETAUTHORIZATION,
  HIDENOTEMESSAGE,
} from '../auth/constants';
import {
  GETCONTACTSWEB,
  USERCHANGEPROFILE
} from '../common/constants';
import { ONLINESTATUSCHANGED, CHATMESAGESTATE, CHATOPENEDSTATUSCHANGED, SHOWNOTEMESSAGE, SETMESSAGESTATE, USERTYPING } from '../messages/constants';

export default function (socket) {
  socket.onmessage = function(e) {
    if (typeof e.data === 'string') {
      const data = JSON.parse(e.data);
      if (data.method !== undefined && data.method === "_result") {
        const message = pairMessages(e.data);
        store.dispatch({
          type: message.method,
          params: message,
        });
      } else if (data.method !== undefined && data.method === "onMessage") {
				const state = store.getState();
				data.params[1] ? data.params[1] = new Date().getTime() : ''
        saveMessage(data.params);
				setLastUserMessage(data.params);
				if (state.auth.userType === 4) {
					store.dispatch({
						type: SETMESSAGESTATE,
						params: [data.params[2].msg_id, 1, data.params[2].for_merchant]
					});
					store.dispatch({
						type: 'FORMERCHANTMESSAGE',
						params: [data.params[2].for_merchant]
					});
				} else {
					store.dispatch({
						type: SETMESSAGESTATE,
						params: [data.params[2].msg_id, 1]
					});
				}
        setTimeout(() => {
          store.dispatch({
            type: data.method,
            params: data.params,
          });
        }, 300);
        return true;
      } else if (data.method !== undefined && data.method === "onAddContact") {
        const state = store.getState();
        const userType = state.auth.userType;
        saveContactsInfo([data.params[0].contact], data.params[1], userType);
        return true;
      } else if (data.method !== undefined && data.method === "onPresence") {
				const state = store.getState();
				const userType = state.auth.userType;
        //user is online/go offline
        const uid = data.params[0].uid;
        //user go offline if last_seen !== undefined
        if (data.params[0].last_seen !== undefined) {
					if (userType === 4) {
						setLastSeen(uid, (new Date()).getTime(), data.params[1], userType);
					} else {
						setLastSeen(uid, (new Date()).getTime());
					}
        } else {
					if (userType === 4) {
						setLastSeen(uid, null, data.params[1], userType);
					} else {
						setLastSeen(uid, null);
					}
        }
        store.dispatch({
          type: ONLINESTATUSCHANGED,
          params: data.params[0]
        });
      } else if (data.method !== undefined && data.method === "onTyping") {
				const state = store.getState();
				const userType = state.auth.userType;
				if (userType === 4) {
					//user is typing
					store.dispatch({
						type: USERTYPING,
						params: data.params
					});
				} else {
					//user is typing
					store.dispatch({
						type: USERTYPING,
						params: data.params[0]
					});
				}

      } else if (data.method !== undefined && data.method === "onChatMessageState") {
				const state = store.getState();
				const userType = state.auth.userType;
				// const merchantUid = state.merchant.merchantUid za generalnog agenta

				//changed message state
				if (userType === 4) {
					store.dispatch({
						type: CHATMESAGESTATE,
						params: [data.params, merchantUid, userType]
					});
				}	else {
					store.dispatch({
						type: CHATMESAGESTATE,
						params: data.params
					});
				}

      } else if (data.method !== undefined && data.method === "onProfileUpdate") {
        //user changed his profile -> data.params[0]
        store.dispatch({
          type: GETCONTACTSWEB
        });
        store.dispatch({
          type: USERCHANGEPROFILE,
          params: data.params[0]
        });
      } else if (data.method !== undefined && data.method === "_error") {
        const message = pairMessages(e.data);
        store.dispatch({
          type: message.method,
          params: message,
        });
      } else if (data.method !== undefined && data.method === "onUploadComplete") {
        if (data.params[0] !== 0) {
          store.dispatch({
            type: UPLOAD_ERROR,
            params: [data.params[0]]
          });
        } else {
          store.dispatch({
            type: UPLOADCOMPLETE,
            params: [data.params[1]],
          });          
        }
      } else if (data.method !== undefined && data.method === "chatCreated") {
        //chat opened
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid
        });
      } else if (data.method !== undefined && data.method === "chatAssigned") {
        //chat assigned
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid
        });
      } else if (data.method !== undefined && data.method === "chatDeleted") {
        //chat deleted
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid
        });
      }
    }
  };
  let connectionClosed = false;
  socket.onclose = function() {
    connectionClosed = true;
    store.dispatch({
      type: SETAUTHORIZATION,
      params: false
    });
    store.dispatch({
      type: SHOWNOTEMESSAGE,
      params: 'Connection lost. Reconnecting...',
    });
  };
  socket.onopen = function() {
    if (connectionClosed === true) {
      connectionClosed = false;
      let state = store.getState()
      if (state.auth.userType !== 3 && state.auth.userType !== 4) {
        store.dispatch({
          type: CONNECTWEB,
          params: [parseInt(localStorage.getItem('uid')), localStorage.getItem('token'), 2],
        });
        store.dispatch({
          type: HIDENOTEMESSAGE,
          params: 'Connection lost. Reconnecting...',
        });
      } else if (state.auth.userType === 3) {
				store.dispatch({
					type: LOGINAGENT,
					params: ['', parseInt(localStorage.getItem('uid')), localStorage.getItem('token'), 3],
				});
				store.dispatch({
					type: HIDENOTEMESSAGE,
					params: 'Connection lost. Reconnecting...',
				});
			} else {
				store.dispatch({
					type: LOGINAGENT,
					params: ['', parseInt(localStorage.getItem('uid')), localStorage.getItem('token'), 4],
				});
				store.dispatch({
					type: HIDENOTEMESSAGE,
					params: 'Connection lost. Reconnecting...',
				});
			}
    }
  };
}

