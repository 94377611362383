export default function errorsLib (error, params) {
  let message = 'Unknown Error';
  switch (error) {
    //bad time day ended//
    case 'end_piip_day':
      message = sprintf('To provide fresh content, Piip supports intra-day promotions only.<br />This promo needs to be end between now and latest %s<br />Please check your end-time.', ...params);
      break;
    case 'promotion_default':
      message = 'Problem publishing promotion';
      break;
    case 'promotion_overwrite':
      message = 'Your current promotion will expire. Are you sure?';
      break;
    case 'upload_default':
      message = 'Problem uploading image.';
      break;
    case 'verify_email':
      message = 'Problem verifying email.';
      break;
    case 'update_email':
      message = 'Problem updating email.';
      break;
    case 'upload_prevent': {
      let paramsString = (params !== undefined && params[0] !== undefined) ? params[0] : '';
      message = sprintf('Problem uploading image.<br />%s', paramsString);
      break;
    }
    case 'verify_default':
      message = 'Invalid code';
      break;
    case 'register_default':
      message = 'This phone number is not mobile app registered. Please register using your mobile app or check phone number you entered';
      break;
    case 'broadcast_defaul':
      message = 'You reached the limit of 1 message per day';
      break;
  }

  return message;
}


//helper function
function sprintf( format ){
  for( var i=1; i < arguments.length; i++ ) {
    format = format.replace( /%s/, arguments[i] );
  }
  return format;
}