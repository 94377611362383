import {
	GETPROMOTIONS,
  GETSUBPROMOTIONS,
  DELETESUBPROMOTIONS,
  RECREATEPROMOTION,
  SETPROMOTIONLONG,
  PUBLISHPROMOTION,
  ENDPROMOTION,
  ASSIGNSSUBPPROMOTION,
  DELETEPROMOTIONS,
  SETSUBPROMOTION,
  UNPUBLISHSUBPROMOTION,
  PUBLISHSUBPROMOTION
} from './constants';

export function getPromotions(params) {
  return {
    type: GETPROMOTIONS,
    params,
  };
}

export function recreatePromotion(params) {
  return {
    type: RECREATEPROMOTION,
    params,
  };
}

export function getSubPromotions(params) {
  return {
    type: GETSUBPROMOTIONS,
    params,
  };
}

export function deleteSubPromotions(params) {
  return {
    type: DELETESUBPROMOTIONS,
    params,
  };
}

export function setPromotionLong(params) {
  return {
    type: SETPROMOTIONLONG,
    params,
  };
}

export function publishPromotion(params) {
  return {
    type: PUBLISHPROMOTION,
    params,
  };
}

export function endPromotion(params) {
  return {
    type: ENDPROMOTION,
    params,
  };
}

export function assignSubPromotion(params) {
  return {
    type: ASSIGNSSUBPPROMOTION,
    params,
  };
}

export function deletePromotion(params) {
  return {
    type: DELETEPROMOTIONS,
    params,
  };
}

export function setSubPromotion(params) {
  return {
    type: SETSUBPROMOTION,
    params,
  };
}

export function unpublishSubPromotion(params) {
  return {
    type: UNPUBLISHSUBPROMOTION,
    params,
  };
}

export function publishSubPromotion(params) {
  return {
    type: PUBLISHSUBPROMOTION,
    params,
  };
}