import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Grid, Button, Typography, TextField, Checkbox, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogActions, DialogContent, Divider } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Dropzone from 'react-dropzone';
import camera from './images/camera_icon.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getRealMimeType, isReadonly, str2ab }from '../../lib/utils';
import { uploadError, beginUpload } from '../../store/common/actions';
import { getSubPromotions, setSubPromotion, publishSubPromotion, unpublishSubPromotion } from '../../store/promotions/actions';
import Cropper from 'react-image-crop';
import _ from 'lodash';
import './styles.css';

class OrderItemEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      status: 0,
      promotionId: null,
			title: '',
			titleError: false,
			description: '',
			descriptionError: false,
      currency: '',
      currencyPHPChecked: false,
      currencyEURChecked: false,
      currencyUSDChecked: false,
      currencyRSDChecked: false,
      currencyCHFChecked: false,
      currencySelected: '',
      price: '',
      open: false,
      storeImage: '',
      imageCrop0: '',
      imageCrop1: '',
      imageCrop2: '',
      imageCrop3: '',
      openCrop: false,
      crop: {
				unit: 'px',
				x: 10,
				y: 30,
				width: 200,
				height: 300,
			},
      cropedRes: {},
      fileSizeImageCrop0: null,
      fileSizeImageCrop1: null,
      fileSizeImageCrop2: null,
      fileSizeImageCrop3: null
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.orderItemEditData)) {
      this.setState({
        id: this.props.orderItemEditData.id,
        status: this.props.orderItemEditData.state,
        promotionId: this.props.orderItemEditData.promotion_id,
        title: this.props.orderItemEditData.title,
        description: this.props.orderItemEditData.description,
        imageCrop0: this.props.orderItemEditData ? this.props.orderItemEditData.logo_url : '',
        imageCrop1: this.props.orderItemEditData.images ? this.props.orderItemEditData.images.img_1 : '',
        imageCrop2: this.props.orderItemEditData.images ? this.props.orderItemEditData.images.img_2 : '',
        imageCrop3: this.props.orderItemEditData.images ? this.props.orderItemEditData.images.img_3 : '',
        price: this.props.orderItemEditData.price,
        currency: this.props.orderItemEditData.currency,
        currencyPHPChecked: this.props.orderItemEditData.currency === 'PHP' ? true : false,
        currencyEURChecked: this.props.orderItemEditData.currency === 'EUR' ? true : false,
        currencyUSDChecked: this.props.orderItemEditData.currency === 'USD' ? true : false,
        currencyRSDChecked: this.props.orderItemEditData.currency === 'RSD' ? true : false,
        currencyCHFChecked: this.props.orderItemEditData.currency === 'CHF' ? true : false,
      })
    }
  }

	handleTitleChange = (event) => {
    this.setState({title: event.target.value})
    this.props.title(event.target.value)
	}

	handleDescriptionChange = (event) => {
    this.setState({description: event.target.value})
    this.props.description(event.target.value)
	}

	handleCancelOrderItem = () => {
    this.setState({status: 0})
    this.props.closeEditOrderItem()
	}

	handleSaveOrderItem = () => {
    if(this._validate()){
      this._savePromotion(this.state.id);
      this.setState({
        id: null,
        promotionId: null,
        title: '',
        description: '',
        currency: '',
        currencyPHPChecked: false,
        currencyEURChecked: false,
        currencyUSDChecked: false,
        currencyCHFChecked: false,
        currencyRSDChecked: false,
        currencySelected: '',
        price: '',
      })
      this.props.closeEditOrderItem()
    }
	}

  _validate = (draft) => {  // izmeni ........
    let error = false;

    if(!(this.state.title !== '' && this.state.title !== null)){
      // this.setState({titleError: "Invalid promotion title"});
      error = true;
    }
    if(!(this.state.description !== '' && this.state.description !== null)){
      // this.setState({descriptionError: "Invalid promotion description"});
      error = true;
    }
    if(!(this.state.currency !== '' && this.state.currency !== null)){
      // this.setState({currencyError: "Invalid promotion currency"});
      error = true;
    }
    if(!(this.state.price !== '' && this.state.price !== null)){
      // this.setState({priceError: "Invalid promotion price"});
      error = true;
    }
    if(isNaN(this.state.price)){
      // this.setState({priceError: "You have to enter numerical value"});
      error = true;
    }
    return (error) ? false : true;  
  };

  _savePromotion = (id) => {
    let data = this._getPromotionData();
    if (this.state.promotionId === 0) {
      this.props.setSubPromotion([data, null, null]);   // ako je order item nov - ne pripada promociji

    } else {
      this.props.setSubPromotion([data, this.state.promotionId, id]);  // ako order item pripada promociji
      this.props.getSubPromotions([null])
    }
  };

  _getPromotionData = () => {
    return {
      title: this.state.title,
      description: this.state.description,
      currency: this.state.currencySelected,
      price: parseFloat(this.state.price),
    };  
  };

  handleChangeCurrency = (event) => {
    if (event.target.name === 'PHP') {
      this.setState({
        currencyPHPChecked: event.target.checked,
        currencyEURChecked: false,
        currencyUSDChecked: false,
        currencyCHFChecked: false,
        currencyRSDChecked: false,
        currency: event.target.checked,
      })
    } else if (event.target.name === 'EUR') {
      this.setState({
        currencyEURChecked: event.target.checked,
        currencyPHPChecked: false,
        currencyUSDChecked: false,
        currencyCHFChecked: false,
        currencyRSDChecked: false,
        currency: event.target.checked,
      })
    } else if (event.target.name === 'USD') {
      this.setState({
        currencyUSDChecked: event.target.checked,
        currencyPHPChecked: false,
        currencyEURChecked: false,
        currencyCHFChecked: false,
        currencyRSDChecked: false,
        currency: event.target.checked,
      })
    } else if (event.target.name === 'CHF') {
      this.setState({
        currencyCHFChecked: event.target.checked,
        currencyPHPChecked: false,
        currencyUSDChecked: false,
        currencyEURChecked: false,
        currencyRSDChecked: false,
        currency: event.target.checked,
      })
    } else {
      this.setState({
        currencyRSDChecked: event.target.checked,
        currencyPHPChecked: false,
        currencyEURChecked: false,
        currencyCHFChecked: false,
        currencyUSDChecked: false,
        currency: event.target.checked,
      })
    }
    this.setState({
      currencySelected: event.target.name
    })
    this.props.currency(event.target.name, event.target.checked)
  }

  handlePriceChange = (event) => {
    this.setState({price: event.target.value})
    this.props.price(event.target.value)
  }

  handleRedirectImage = () => {
    this.setState({open: true})
  }

  onUploadDrop = (logoIndex, acceptedFiles) => {
    this.setState({logoIndex: logoIndex});
    let index = logoIndex;
    let self = this;
    if(acceptedFiles.length > 0){
      let file = acceptedFiles[0];
			const preview = URL.createObjectURL(file)
      const fr = new FileReader();
      fr.readAsArrayBuffer(file);
      fr.addEventListener("loadend", function() {
        if (getRealMimeType(fr.result) === 'unknown'){
          self.props.uploadError([0, 'Please upload image file']);
        } else {
          self.setState({openCrop: true});
          let obj = {photo: preview};
          self.setState({storeImage: obj});

          if(index === '0'){
						self.setState({crop: {
							...self.state.crop,
							width: 300,
							height: 300
						}});
          }	else if(index === '1'){
							self.setState({crop: {
								...self.state.crop,
								width: 200,
								height: 300
						}});
          } else if(logoIndex === "2"){
							self.setState({
								crop: {
									...self.state.crop,
									width: 400,
									height: 300
								}
							});
          } else {
            self.setState({crop: {
              ...self.state.crop,
              width: 200,
              height: 300
						}});
          }
        }
      });
    }
	}

  handleClosePhotoSelection = () => {
		this.setState({
			openCrop: false,
			storeImage: { photo: null }
		});
	}

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  handleImageLoaded = (image) => {
		this.imageRef = image
		const width = 200;
    const height = 300;
    const x =	10;
    const y = 30;

    this.setState({
      crop: {
        unit: 'px',
        aspect: 1,
        width,
        height,
        x,
        y,
      },
    });

    return false;
	}

  handleImageCropComplete = (crop) => {
		this.setState({cropedRes: crop})
	}

  handleCrop = () => {
		if (this.imageRef && this.state.crop) {
			const { crop, logoIndex } = this.state
			const canvas = document.createElement("canvas");
			const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
			const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(
					this.imageRef,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width,
					crop.height
			)

			const base64Image = canvas.toDataURL('image/png');
			let dataArr = base64Image.split('base64,');
			let fileSize = str2ab(atob(dataArr[1])).byteLength;

			localStorage.setItem('fileToUpload', atob(dataArr[1]));
			switch(logoIndex){
				case '0':
					this.setState({
						imageCrop0: base64Image,
            fileSizeImageCrop0: fileSize
					});
          this.props.images(base64Image, 0)
					break;
				case '1':
					this.setState({
						imageCrop1: base64Image,
            fileSizeImageCrop1: fileSize
					});
          this.props.images(base64Image, 1)
					break;
				case '2':
					this.setState({
						imageCrop2: base64Image,
            fileSizeImageCrop2: fileSize
					});
          this.props.images(base64Image, 2)
					break;
				case '3':
					this.setState({
						imageCrop3: base64Image,
            fileSizeImageCrop3: fileSize
					});
          this.props.images(base64Image, 3)
					break;
			}
      
      if(logoIndex === "0") {
        this.props.beginUpload([12, fileSize, this.state.id]);
			} else if (logoIndex === "1") {
        this.props.beginUpload([13, fileSize, this.state.id, 1]);
      } else if (logoIndex === "2") {
        this.props.beginUpload([13, fileSize, this.state.id, 2]);
      } else if (logoIndex === "3") {
        this.props.beginUpload([13, fileSize, this.state.id, 3]);
      } else {
				this.props.beginUpload([6, fileSize, parseInt(logoIndex)]);     
			}

			this.setState({
				openCrop: false,
				storeImage: { photo: null }
			});
		}
	}

  // handleSaveImages = () => {
  //   this.props.beginUpload([12, this.state.fileSizeImageCrop0, this.state.id]);
  //   this.props.beginUpload([13, this.state.fileSizeImageCrop1, this.state.id, 1]);
  //   this.props.beginUpload([13, this.state.fileSizeImageCrop2, this.state.id, 2]);
  //   this.props.beginUpload([13, this.state.fileSizeImageCrop3, this.state.id, 3]);
  //   this.setState({
  //     open:false
  //   })
  // }

  handleCloseImages = () => {
    this.setState({open:false})
  }

  handlePublishOrderItem = () => {
    if (this.state.status === 0) {
      if (this.state.promotionId !== null) {
        if (this.props.activePromotions.length > 0) {
          if (this.props.activePromotions[0].id === this.state.promotionId) {
            this.setState({status: 1})
            this.props.publishSubPromotion([this.state.id]);
            this.props.closeEditOrderItem()
            this.props.getSubPromotions([null])
          }
        }
      }
    } else {
      this.setState({status: 0})
      this.props.unpublishSubPromotion([this.state.id]);
      this.props.getSubPromotions([null])
      this.props.closeEditOrderItem()
    }
  }

  render() {
    const { title, titleError, description, descriptionError, price, id } = this.state
    const croppedImg0 = this.state.imageCrop0 ? 'cropped' : ''
    const croppedImg1 = this.state.imageCrop1 ? 'cropped' : ''
    const croppedImg2 = this.state.imageCrop2 ? 'cropped' : ''
    const croppedImg3 = this.state.imageCrop3 ? 'cropped' : ''
    let check = (<CheckIcon className='promotion-edit-check-icon'/>);
		let icons = {};
    let iconsDefaults = {
      title: ( <Grid className='promotion-edit-create-promotion-icon-wraper'>1</Grid>),
      description: ( <Grid className='promotion-edit-create-promotion-icon-wraper'>2</Grid>),
      currency: ( <Grid className='promotion-edit-create-promotion-icon-wraper'>3</Grid>),
      price: ( <Grid className='promotion-edit-create-promotion-icon-wraper'>4</Grid>),
      logo: ( <Grid className='promotion-edit-create-promotion-icon-wraper'></Grid>)
    };

    icons.title = this.state.title === "" ? iconsDefaults.title : check;
    icons.currency = this.state.currency === '' || this.state.currency === false ? iconsDefaults.currency : check;
    icons.description = this.state.description === "" ? iconsDefaults.description : check;
    icons.price = this.state.price === "" ? iconsDefaults.price : check;
    // icons.time = check;
    const activePromotionsId = this.props.activePromotions.length > 0 ? this.props.activePromotions[0].id : null
    return (
      <Grid className="order-item-holder-wrap" item container xs={12}>
        <Grid className="order-item-holder-under" item xs={7} container direction='column' alignItems="flex-start">
          <Typography className="order-item-edit-title">Create Order Item</Typography>
          <Grid className="order-item-edit-box border-radius shadow" item container>
            <Grid item container className='order-item-edit-box-content' direction='row'>
              <Grid className="order-item-edit-checkpoint" item container>{icons.title}</Grid>
              <Grid className="order-item-edit-selection" item container direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Order Item Name</Typography>
                <TextField
                  className="promotionEditTitle"
                  fullWidth
                  placeholder={title ? title : 'Enter Promotion Title here'}
                  value={title ? title : ''}
                  onChange={isReadonly() ? null : this.handleTitleChange}
                  error={titleError}
                  helperText={titleError ? "Invalid promotion title" : ''}
                  inputProps={{
                    maxLength: 30
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container className='order-item-edit-box-content' direction='row'>
              <Grid className="order-item-edit-checkpoint" item container>{icons.description}</Grid>
              <Grid className="order-item-edit-selection" item container direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Describe Order Item shortly</Typography>
                <TextField
                  className="promotionEditTitle"
                  fullWidth
                  placeholder={description ? description : 'Promotion Description'}
                  value={description ? description : ''}
                  onChange={isReadonly() ? null : this.handleDescriptionChange}
                  error={descriptionError}
                  helperText={descriptionError ? "Invalid promotion description" : ''}
                  inputProps={{
                    maxLength: 10000
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container className='order-item-edit-box-content' direction='row'>
              <Grid className="order-item-edit-checkpoint" item container>{icons.currency}</Grid>
              <Grid className="order-item-edit-selection" item container direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Select Currency</Typography>
                <FormGroup row>
                  <FormControlLabel
                    className='order-item-checkbox'
                    control={<Checkbox checked={this.state.currencyPHPChecked} onChange={this.handleChangeCurrency} name="PHP" />}
                    label='PHP'
                  />
                  <FormControlLabel
                    className='order-item-checkbox'
                    control={<Checkbox checked={this.state.currencyEURChecked} onChange={this.handleChangeCurrency} name="EUR" />}
                    label='EUR'
                  />
                  <FormControlLabel
                    className='order-item-checkbox'
                    control={<Checkbox checked={this.state.currencyUSDChecked} onChange={this.handleChangeCurrency} name="USD" />}
                    label='USD'
                  />
                  <FormControlLabel
                    className='order-item-checkbox'
                    control={<Checkbox checked={this.state.currencyCHFChecked} onChange={this.handleChangeCurrency} name="CHF" />}
                    label='CHF'
                  />
                  <FormControlLabel
                    className='order-item-checkbox'
                    control={<Checkbox checked={this.state.currencyRSDChecked} onChange={this.handleChangeCurrency} name="RSD" />}
                    label='RSD'
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item container className='order-item-edit-box-content' direction='row'>
              <Grid className="order-item-edit-checkpoint" item container>{icons.price}</Grid>
              <Grid className="order-item-edit-selection" item container direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Enter Price</Typography>
                <TextField
                  className="promotionEditTitle"
                  fullWidth
                  placeholder='Enter Price'
                  value={price ? price : ''}
                  onChange={isReadonly() ? null : this.handlePriceChange}
                />
              </Grid>
            </Grid>
            <Grid item container className='order-item-edit-box-content' direction='row' xs={12}>
              <Grid className="order-item-edit-checkpoint order-item-edit-checkpoint--invisible" item container>{icons.price}</Grid>
              <Grid className='order-item-edit-box-logo-redirection order-item-edit-selection' item container direction="column" alignItems="flex-start" onClick={id ? this.handleRedirectImage : null}>
                <Typography className='promotion-edit-header-text'>Add images and Logo</Typography>
                <Typography className='promotion-edit-header-text promotion-edit-header-text--gray'>Tap to add Images and Logo to promote</Typography>
                <Typography className='promotion-edit-header-text promotion-edit-header-text--gray'>Your Order item in more effective way</Typography>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
        <Grid className="promotion-edit-buttons-holder" item container xs={2} alignItems="flex-end">
          <Grid item container xs={12} direction="column" alignItems="flex-end">
            <Button onClick={this.handleCancelOrderItem} className='promotion-main-buttons' variant="contained" color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSaveOrderItem} className='promotion-main-buttons' variant="contained" color="primary">
              Save
            </Button>
            <Button 
              onClick={this.handlePublishOrderItem} className={`promotion-main-buttons promotion-main-buttons--publish`} variant="contained" color="primary"
              disabled={this.props.activePromotions.length > 0 && activePromotionsId === this.state.promotionId ? false : true}
            >
              {this.state.status === 0 ? 'Publish' : 'Unpublish'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.open}
          className='order-item-edit-images'
        >
          <Grid className='settings-dialog-blank' container item xs={1}></Grid>
          <DialogTitle className='order-item-edit-dialog-text'>
            <ArrowBackIcon onClick={this.handleCloseImages} className="order-item-edit-dialog-back-btn" />
            Add Images and Logo for Your Order Item
            <Divider className='order-item-edit-dialog-text-border' />
            <Typography>Tap Camera Icon to upload Images and Logo to promote Your Order Item in more effective way</Typography>
          </DialogTitle>
          <Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
          <DialogContent className='order-item-edit-dialog-content popup-content-xs'>
            <Grid item container xs={12} justify="center" alignItems="flex-end" className='order-item-edit-dialog-content-inner'>
              <Grid item container xs={2} className="order-item-edit-main-img">
                <Typography>Logo</Typography>
                <Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '0')} multiple={false} accept="image/*">
                  {({getRootProps, getInputProps}) => (
                    <Grid>
                      <Grid {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Grid className="order-item-edit-photos-topic">
                          <img className={`order-item-edit-photos-img order-item-edit-photos-img--${croppedImg0}`} draggable={false} src={this.state.imageCrop0 === '' ? camera : this.state.imageCrop0} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Dropzone>
              </Grid>
              <Grid item container xs={2}/>
              <Grid item container xs={2} className="order-item-edit-main-img order-item-edit-main-img--width">
                <Typography>Main photo</Typography>
                <Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '1')} multiple={false} accept="image/*">
                  {({getRootProps, getInputProps}) => (
                    <Grid>
                      <Grid {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Grid className="order-item-edit-photos-topic order-item-edit-photos-topic--width">
                          <img className={`order-item-edit-photos-img order-item-edit-photos-img--${croppedImg1}`} draggable={false} src={this.state.imageCrop1 === '' ? camera : this.state.imageCrop1} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Dropzone>
              </Grid>
              <Grid item container xs={2} className="order-item-edit-main-img order-item-edit-main-img--width">
                <Typography>Additional photo 1</Typography>
                <Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '2')} multiple={false} accept="image/*">
                  {({getRootProps, getInputProps}) => (
                    <Grid>
                      <Grid {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Grid className="order-item-edit-photos-topic order-item-edit-photos-topic--opacity order-item-edit-photos-topic--width">
                          <img className={`order-item-edit-photos-img order-item-edit-photos-img--${croppedImg2}`} draggable={false} src={this.state.imageCrop2 === '' ? camera : this.state.imageCrop2} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Dropzone>
              </Grid>
              <Grid item container xs={2} className="order-item-edit-main-img order-item-edit-main-img--width">
                <Typography>Additional photo 2</Typography>
                <Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '3')} multiple={false} accept="image/*">
                  {({getRootProps, getInputProps}) => (
                    <Grid>
                      <Grid {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Grid className="order-item-edit-photos-topic order-item-edit-photos-topic--width">
                          <img className={`order-item-edit-photos-img order-item-edit-photos-img--${croppedImg3}`} draggable={false} src={this.state.imageCrop3 === '' ? camera : this.state.imageCrop3} />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Dropzone>
              </Grid>
              <DialogActions>
                <Button
                  variant="outlined"
                  className='promotion-main-outlined-buttons promotion-main-outlined-buttons--cancel'
                  onClick={this.handleCloseImages}
                >
                  Close
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  className='promotion-main-buttons promotion-main-buttons-dialog'
                  onClick={this.handleSaveImages}
                >
                  Save
                </Button> */}
              </DialogActions>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          className="bizz-color-dialog"
          aria-labelledby="max-width-dialog-title"
          open={this.state.openCrop}
          onClose={this.handleClosePhotoSelection}
        >
					<DialogTitle className="bizz-color-dialog-title" disableTypography>
						Add Logo and Images
					</DialogTitle>
					<DialogContent className="bizz-color-dialog-content">
						<Cropper
							className="bizz-color-dialog-cropper"
							src={this.state.storeImage.photo}
							onChange={this.onCropChange}
							crop={this.state.crop}
							ref="imageCrop"
							onImageLoaded={this.handleImageLoaded}
							onComplete={this.handleImageCropComplete}
						/>
					</DialogContent>
					<DialogActions className="bizz-color-dialog-buttons">
						<Button className='bizz-color-dialog-button bizz-color-dialog-button--first' onClick={this.handleClosePhotoSelection} autoFocus variant="contained" color="primary">
							Cancel
						</Button>
						<Button className='bizz-color-dialog-button bizz-color-dialog-button--second' onClick={this.handleCrop} variant="contained" color="primary">
							Add
						</Button>
					</DialogActions>
				</Dialog>
      </Grid>
    );
  }
}

OrderItemEdit.propTypes = {

};

export default connect(({ promotions }) => {
  return {
    activePromotions: promotions.activePromotions
  }
}, (dispatch) => {
  const actions = {uploadError, beginUpload, setSubPromotion, getSubPromotions, publishSubPromotion, unpublishSubPromotion};
  return bindActionCreators(actions, dispatch);
})(OrderItemEdit);
