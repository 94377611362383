import { withStyles, Slider, Dialog } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

export const CreateIconCustom = withStyles(theme => ({
  root: {
    width: '22.3px',
    height: '22.2px',
    display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: 'var(--brown-gray-color)',
		marginLeft: '10px'
  },
}))(CreateIcon);

export const PrettoSlider = withStyles({
  root: {
		color: 'var(--brown-gray-color)' 
  },
  thumb: {
    width: 24,
    height: 24,
    backgroundColor: 'var(--success-color)',
		color: 'var(--success-color)',
    marginTop: -10,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
})(Slider);

export const ColorDialog = withStyles(theme => ({
	paper: {
		width: '1000px',
		height: 'auto',
		border: 'var(--border)',
		borderRadius: 'var(--border-radius-box)',
		boxShadow: 'var(--box-shadow)'
	}
}))(Dialog);