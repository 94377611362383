import React, { Component } from 'react';
import text from './index.md';
import logo from './images/logo.svg';
import {connect} from 'react-redux';
import { history } from '../../store/index';
import { DialogActions, Button } from '@material-ui/core';
import Layout from '../../components/Layout';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Terms from '../../components/Terms/Terms';
import WrapperContent from '../../components/WrapperContent'
import PropTypes from 'prop-types';
import { ColorDialog } from './styles.js'
import './styles.css';

class Welcome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	componentDidMount() {
		if (this.props.token) {
			history.push('/load');
		}
		document.title = text.title;
	}

	handleClick = () => {
		//redirect -> term&conditions
	};

	handleSubmit = () => {
		history.push('/register');
	};

	handleOpen = () => {
		this.setState({open: true});
	};

	handleClose = () => {
		this.setState({open: false});
	};

	render() {
		return (
			<WrapperContent>
				<Layout className="welcome-layout">
					<ColorDialog open={this.state.open} onClose={this.handleClose} maxWidth={'md'}>
						<Terms/>
						<DialogActions>
							<Button className="welcome-dialog-btn" variant="outlined" onClick={this.handleClose}>
								Ok
							</Button>
						</DialogActions>
					</ColorDialog>
					<div className="abs-content flex-rows">
						<div className="flex-cell">
							<div className="centered"><img className="welcome-logo" src={logo} alt="logo"/></div>
							<div className="welcome-message padded-top centered">{text.message}</div>
						</div>

						<div className="flex-cell-min">
							<div className="welcome-terms padded-top centered" onClick={this.handleOpen}>{text.linkLabel}</div>
							<div className="margined-v centered">
								<Button className="welcome-button" onClick={this.handleSubmit} variant="contained" color="primary" endIcon={<ArrowForward className='iconCss'/>}>
									Accept & continue
								</Button>
							</div>
						</div>
					</div>
				</Layout>
			</WrapperContent>
		);
	}
}

Welcome.propTypes = {
	token: PropTypes.string.isRequired,
};

export default connect(({auth}) => {
	return {
		token: auth.token,
		uid: auth.uid
	}
})(Welcome);
