import { Slider, withStyles} from '@material-ui/core';

export const PrettoSlider = withStyles({
  root: {
		color: 'var(--brown-gray-color)'
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'var(--success-color)',
		color: 'var(--success-color)',
    marginTop: -10,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
})(Slider);