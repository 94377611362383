import Welcome from './pages/welcome';
import Register from './pages/register';
import ConfirmationCode from './pages/confirmationCode';
import Load from './pages/load';
import Promos from './pages/promos';
import AgentLogin from './pages/loginAgent';
import Dashboard from './pages/dashboard';
import BiizCard from './pages/biizCard';
import Messages from './pages/messages';
import Settings from './pages/settings';

const mainRoutes = [
		{ label: 'Home', path: '/welcome', component: Welcome, exact: true, default: true },
		{ label: 'Dashboard', path: '/dashboard', component: Dashboard, protected: true },
		{ label: 'Promos', path: '/promos', component: Promos, protected: true },
		{ label: 'BiizCard', path: '/biizCard', component: BiizCard, protected: true },
		{ label: 'Settings', path: '/settings', component: Settings, protected: true },
		{ label: 'BizzAdmin', path: '/biizadmin', component: Load, protected: true },
]
let userType = parseInt(process.env.USER_TYPE);
if (localStorage.getItem('userType')) userType = parseInt(localStorage.getItem('userType'))
const navConfig = {
	routes: [
		...(userType === 3 || userType === 4 ? [] : mainRoutes),
		{ label: 'Messages', path: '/messages', component: Messages, protected: true }
	],
	authRoutes: [
		{ label: 'Register', path: '/register', component: Register},
		{ label: 'ConfirmationCode', path: '/confirmationCode', component: ConfirmationCode },
		{ label: 'Load', path: '/load', component: Load },
		{ label: 'AgentLogin', path: '/loginAgent', component: AgentLogin },
	],
	additionalRoutes: [

	],
	notInMenuRoutes: [

	]
}

export default navConfig
