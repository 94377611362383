import React, {Component} from 'react';
import { Grid, Link, Dialog, DialogTitle, DialogContent, Typography, Divider, Card, CardMedia } from '@material-ui/core';
import './styles.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from './images/logo.svg';

export default class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Dialog
				open={this.props.openAbout}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text'>
					<ArrowBackIcon onClick={this.props.handleCloseAbout} className="order-item-edit-dialog-back-btn" />
					About
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogContent className='setting-about-content popup-content-xs'>
					<Card	className='settings-about-card'>
						<CardMedia
							className='settings-about-card-media'
							image={Logo}
						/>
					</Card>
					<Grid item container className='settings-about-main-text-grid'>
						<Typography className='settings-about-text'>Copyright © 2018 - {(new Date().getFullYear())} by Piip Mobile AG.</Typography>
						<Typography className='settings-about-text'>All Rights Reserved</Typography>
						<Link href="http://www.biiz.piip.com/" className='settings-about-link'>www.piip.com</Link>
						<Typography className='settings-about-text'>Build Version 3</Typography>
						<Link href="http://www.piip.com/legal/" className='settings-about-link'>Terms & Conditions</Link>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}