import React, { Component } from 'react';
import './styles.css';
import { Grid } from '@material-ui/core';
import Header from '../Header/index'
import { getUnreadMessagesUsersCount } from '../../lib/utils';

class WrapperContent extends Component {
  render() {
    const count = getUnreadMessagesUsersCount(this.props.openedChats);
    let cnt = 0;
    if (count !== 0) {
      cnt = count;
    }
    return (
      <Grid item xs={12} sm container className={`wrapper-content ${this.props.className || ''}`}>
        {this.props.header && <Header title={this.props.title} className='header-text' headerAsideTitle={this.props.headerAsideTitle} badgeNum={cnt} logout={this.props.logout}/>}
        {this.props.children}
      </Grid>
    )
  }
}

export default WrapperContent;
