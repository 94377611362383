import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Grid, ListItemText, Button, List, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles.css';

class OrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
			allPromos: [],
			options: [
				{ id: 0, text: 'Name' },
				{ id: 1, text: 'Date' },
				{ id: 2, text: 'Status' }
			],
			selectedOption: 0,
			isVisibleMenu: false
    };
  }

	componentDidUpdate(prevProps) {
		if (prevProps.subPromotions !== this.props.subPromotions) {
			let newArr = this.props.subPromotions.map((data) => {
				return {
					id: data.id,
					title: data.title,
					date: data.publish_time ? moment(data.publish_time).format("YY-MM-DD hh:mm") : data.publish_time,
					status: data.state === 0 ? 'Draft' : 'Active'
				}
			})
			this.setState({allPromos: newArr.sort((a, b) => a.title.localeCompare(b.title))})
		}
	}

	handleSort = (id) => {
		if (this.state.selectedOption !== id) {
			this.setState({ selectedOption: id });
		}

		if (id === 0) {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => a.title.localeCompare(b.title)), isVisibleMenu: false})
		} else if (id === 1) {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => a.date.localeCompare(b.date)), isVisibleMenu: false})
		}	else {
			this.setState({allPromos: this.state.allPromos.sort((a, b) => a.status.localeCompare(b.status)), isVisibleMenu: false})
		}
	}

	editOrderItem = (btnData) => {
		this.props.editOrderItem(btnData)
	}

	handleSeveralNameOfOptions = (index) => {
		switch (index) {
			case 0:
				return 'Name'
			case 1:
				return 'Date'
			case 2:
				return 'Status'
				
			default:
				return 'Name'
		}
	}

	handleOpenSelectOptionsMenu = () => {
		this.setState({ isVisibleMenu: true });
	}
	
	handleDeleteOrderItem = () => {
		this.props.deleteOrderItem()
	}

	handleCopyOrderItem = () => {
		this.props.copyOrderItem()
	}

  render() {
		const { options, selectedOption, isVisibleMenu } = this.state
		const selectedHeaderStyle = this.props.flag ? 'promotions-header-new' : '';
		const isVisible = isVisibleMenu ? 'visible' : 'hidden'
		// let btnClass = [
    //   'promotion-main-buttons',
		// 	this.props.flag ? 'promotions-open-button--select' : ''
    // ]
		// btnClass = btnClass.join(' ')
    return (
			<Grid className="promotions-wrapper" item xs={5}>
				<Typography className={`promotions-header ellipsis ${selectedHeaderStyle}`}>Order Items</Typography>
				{this.props.merchantInfo ? this.props.merchantInfo.enable_sub_promos === true ?
					<Grid item container direction='row' xs={12} 
						className={`promotions-first-row ${ this.props.flag ? 'promotions-first-row--selected' : ''}`}
						onClick={() => this.props.gridSelect(this.props.type)}
					>
							<Grid item container direction='row' xs={12} className={`promotions-list-header ${ this.props.flag ? 'promotions-list-header--selected' : ''}`}>
								<Grid item container xs={6} alignItems="center" className="promotions-list-header-inner">
									<Typography
										className="promotions-list-header-title ellipsis"
										onClick={() => { this.handleOpenSelectOptionsMenu() }}
										title={ 'Sort by '+ this.handleSeveralNameOfOptions(selectedOption)}
										>
											Sort by {this.handleSeveralNameOfOptions(selectedOption)}
									</Typography>
									<List className={`promotions-select-options promotions-select-options--${isVisible}`}>
										{options.map(opt => {
											return (
												<ListItem 
													className={`promotions-select-option ${ selectedOption === opt.id ? 'promotions-select-option--active' : ''}`} 
													onClick={() => { this.handleSort(opt.id) }} 
													key={opt.id}
													>
														<ListItemText>{`Sort By ${opt.text}`}</ListItemText>
												</ListItem>
											)
										})}	
									</List>	
								</Grid>
								<Grid item xs={3} className='promotions-and-order-item-header-main'>
									<Typography className='promotions-and-order-item-header-main-text ellipsis'>Date</Typography>
								</Grid>
								<Grid item xs={3} className='promotions-and-order-item-header-main'>
									<Typography className='promotions-and-order-item-header-main-text ellipsis'>Status</Typography>
								</Grid>
							</Grid>
							<Grid item container xs={12} className={`promotions-list-wrapper`}>
								<List className="promotions-list-items">
									{Object.keys(this.state.allPromos).map((key, index) => {
										return (
											<ListItem
												className="promotions-list-item"
												button={this.props.flag ? true : false}
												key={index}
												onClick={this.props.flag ? this.props.editOrderItemFlag ? null : this.props.handleOrderItemSelect.bind(this, this.state.allPromos[key].id) : null}
											>
												<Grid item container direction='row' className={`promotions-list ${this.props.flag ? this.props.selectedOrderItem === this.state.allPromos[key].id ? 'promotions-title--selected' : '' : ''}`}>
													<Grid item container xs={6} justify="flex-start" >
														<ListItemText
															className="promotions-list-title"
															primary={this.state.allPromos[key].title}
														/>
													</Grid>
													<Grid item xs={3} container justify="flex-start">
														<ListItemText
															className="promotions-list-date"
															primary={this.state.allPromos[key].date}
														/>
													</Grid>
														<Grid item xs={3} container justify="flex-start" className='promotions-and-order-item-list-status'>
															<ListItemText
																primary={this.state.allPromos[key].status}
															/>
													</Grid>
												</Grid>
											</ListItem>
										)
									})}
								</List>
						</Grid> 
					</Grid>
				: 
					<div className='promotions-no-access'>
						<p>You don&apos;t have access to this area</p>
					</div> : 
					<div className='promotions-no-access'>
						<p>You don&apos;t have access to this area</p>
					</div>
					}
				{this.props.merchantInfo ? this.props.merchantInfo.enable_sub_promos === true ?
				<div className="promotions-button-main-div">
					<div className="flex-cell centered promos-buttons">
						<Button 
							size="small"
							className="promos-buttons"
							variant="contained"
							color="primary"
							onClick={this.props.flagSecond ? this.editOrderItem.bind(this, 'open') : null}
							disabled={this.props.flagSecond ? this.props.editOrderItemFlag ? true : false : true }
						>
							Open
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button 
							size="small"
							className="promos-buttons"
							variant="contained"
							color="primary"
							onClick={this.handleCopyOrderItem}
							disabled={this.props.flagSecond ? this.props.editOrderItemFlag ? true : false : true }
						>
							Copy
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button 
							size="small"
							className="promos-buttons"
							variant="contained"
							color="primary"
							onClick={this.handleDeleteOrderItem}
							disabled={this.props.flagSecond ? this.props.editOrderItemFlag ? true : false : true }
						>
							Delete
						</Button>
					</div>
					<div className="flex-cell centered promos-buttons">
						<Button
							className="promos-buttons"
							size="small" variant="contained" color="primary"
							onClick={this.editOrderItem.bind(this, 'create')}
							disabled={this.props.flagThird ? this.props.flagThird : this.props.flagFour}
						>
							Create New
						</Button>
					</div>
				</div>
				: null : null }
			</Grid>
    );
  }
}

OrderItems.propTypes = {
  subPromotions: PropTypes.array,
};

export default connect(({promotions, common}) => {
  return {
		merchantInfo: common.merchantInfo,
		subPromotions: promotions.subPromotions,
  }
}, (dispatch) => {
  const actions = { };
  return bindActionCreators(actions, dispatch);
})(OrderItems);
