import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  InputLabel,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormGroup,
  Paper,
  Tabs,
  Tab,
  ListItem,
  Card,
  CardMedia,
  FormControlLabel
} from '@material-ui/core';
import { 
	Height as ResizeIcon,
	ReceiptOutlined as PaperIcon,
	InsertPhotoOutlined as PhotoIcon,
	RoomOutlined as Marker,
	SentimentVerySatisfiedOutlined as SmileFirstIcon,
	SentimentSatisfiedOutlined as SmileSecondIcon,
	EmojiEmotionsOutlined as SmileThirdIcon,
	ThumbUpOutlined as Like,
	DvrOutlined as Monitor,
	Send as SendIcon,
  CheckCircle as CheckIcon
} from '@material-ui/icons'
import './styles.css';
import { allowChatScrolling, setChatMessageState, getMessagesBefore, sendTypingState, sendMessage, showTypingOff, beginUploadMessage } from '../../store/messages/actions';
import { resetAttachment } from '../../store/auth/actions';
import { getMerchantCard, refreshNavigation, listMerchantCannedAnswers, listMerchantCannedImages, uploadError } from '../../store/common/actions';
import { setMessageRead, saveMessage, hasChatHistoryDate, setLastUserMessage, isReadonly, getRealMimeType, str2ab } from '../../lib/utils';
import moment from 'moment';
import ChatMessage from '../ChatMessage';
import Spinner from '../SimpleSpinner';
import QrCode from '../../assets/images/qrcode.svg';
import Dropzone from 'react-dropzone';
import { ColorDialog } from '../../pages/biizCard/styles';
import Cropper from 'react-image-crop';

class Chat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			targetUid: -1,
			qrCodePromotion: null,
			typing: false,
			allowChatScroll: true,
			message: null,
			lastRequestTime: new Date(),
			waitTime: 5000,
      qrCodeShow: false,
      price: '',
      tickets: '',
      note: '',
      promotionTitle: '',
      errorTickets: false,
      errorTicketsMsg: '',
      errorPrice: false,
      errorPriceMsg: '',
      tabsValue: 0,
      openManageTemplates: false,
			cannedImages: [],
			cannedAnswers: [],
      openCrop: false,
      storeImage: '',
      crop: {
				unit: 'px',
				x: 10,
				y: 30,
				width: 200,
				height: 300,
			},
      cropedRes: {},
      imageCrop: '',
      orderNowShow: false,
      usd: false,
      eur: false,
      php: false,
      chatResized: false,
      orderNowPrice: '',
      orderNowMsg: 'Do you agree with order of',
      orderCurrency: localStorage.getItem('currency') || 'PHP',
      orderNowPriceError: false,
      orderNowPriceErrorMsg: ''
		}
	}

  componentDidMount() {
    if (this.props.activePromotions.length > 0) {
      this.setState({
        promotionTitle: this.props.activePromotions[0].title
      })
    }
    this.setState({targetUid: this.props.userUid});
    this._scrollDown(true);
		this.props.getMerchantCard();
    if (this.props.userType !== 4) {
			this.props.listMerchantCannedAnswers()
			this.props.listMerchantCannedImages()
		}
  }

  componentDidUpdate(prevProps) {
		if (prevProps.cannedImages !== this.props.cannedImages) {
			this.setState({
				cannedImages: this.props.cannedImages
			})
		}
		if (prevProps.cannedAnswers !== this.props.cannedAnswers) {
			this.setState({
				cannedAnswers: this.props.cannedAnswers
			})
		}
    let _that = this;
		if (this.state.targetUid === undefined && this.props.userUid) {
			this.setState({ targetUid: this.props.userUid });
		}
    if (this.state.targetUid !== this.props.userUid) {
      this.setState({ targetUid: this.props.userUid });
    }
    if (this.props.activePromotions.length > 0) {
      if (prevProps.activePromotions.length === 0) {
        this.setState({
          promotionTitle: this.props.activePromotions[0].title
        })
      }
    }

    if (this.props.usertyping && prevProps.usertyping !== this.props.usertyping) {
      if (this.state.typing === false && this.props.usertyping === this.state.targetUid) _that.setState({ typing: true });
      setTimeout(() => {
        _that.setState({ typing: false });
        _that.props.showTypingOff();
      }, 5000);
    }

    if (prevProps.attachment === null && this.props.attachment !== null) {
      this.sendImageMessage(this.props.attachment);
    }

    (this.props.data || []).forEach((message) => {
      if (!message.read && !message.mineMsg) {
        setMessageRead(message.mid);
        if (message.view_status !== 'seen') {
          this.props.setChatMessageState([message.mid, 2]);
        }
      }
    });

    setTimeout(function(){
      if (_that.state.allowChatScroll) {
        _that._scrollDown(); // argument not sent. We don't know if it is from history or new message. Method will check if should scroll.
      }
    }, 500);

    //resetUnreadCounter(this.props.userUid);
    this.props.refreshNavigation();
  }

  sendImageMessage = (attachment) => {
    if (attachment && attachment !== null) {
      const _that = this;
      if (this.state.message !== '') {
        let params = [
          this.state.targetUid,
          {
            msg_type: 3,
            text: '',
            attachment
          },
        ];

        this.props.sendMessage(params);
        this.props.resetAttachment();
       
        saveMessage([
          this.state.targetUid,
          (new Date()).getTime(),
          {
            msg_id: (new Date()).getTime(),
            msg_type: 3,
            text: '',
            attachment
          },
          this.state.uid,
          true, //mine message (current merchant)
        ]);
        
        this.setState({ message: null });
        this.props.renderChat(this.state.targetUid);
        setTimeout(() => {
          if (this.state.allowChatScroll) {
            _that._scrollDown(true);
          }
        },0);
      }
    }
  };

	_scrollDown = (scroll) => {
    const elem = this.scroll;
    if (elem && (this.props.allowChatScroll || scroll)) {
      elem.scrollTop = elem.scrollHeight;
    } else if (elem) {
      elem.scrollTop = 5;
    }
    if (scroll) {
      this.props.allowChatScrolling();
    }
  };

  loadHistory = (from_link) => {
    if (from_link) {
      this.props.getMessagesBefore([this.props.userUid, false]);
    } else {
      // this is active only if line from renderAllMessages is included in div for triggering on scroll
      const elem = this.scroll;
      if (elem.scrollTop === 0) {
        this.props.getMessagesBefore([this.props.userUid, false]);
      }
    }
  }

  renderAllMessages = () => {
    let currentDay = '';  // onScroll={() => this.loadHistory()} out on ref="sroll" div element for triggering on scroll
    let assingErrorTxt = this.props.messages

    return (
      <div ref={node => this.scroll = node} className='allMessages'>
        {typeof this.props.spinner !== 'undefined' ? <Spinner/> : 
          typeof this.props.messages !== 'undefined' && this.props.messages !== null && this.props.messages !== '' ?
          (<Typography className='chat-main-no-messages-text' variant="h3">{assingErrorTxt}</Typography>) :
          this.props.oldMessagesDate !== false && typeof this.props.oldMessagesDate !== 'undefined' ?
          (<div className='loader'>Loading...</div>) 
						: hasChatHistoryDate(this.state.targetUid) ? (<Grid className='loaderStart' onClick={() => this.loadHistory(1)}>&uarr; click to load more messages &uarr;</Grid>) : (<div className='loaderStart'>&larr; no previous messages &rarr;</div>)}
        {(this.props.data || []).map((message) => {
          let displayDay = '';
          if (moment(message.time).format('YYYY-MM-DD') !== currentDay) {
            currentDay = displayDay = moment(message.time).format('YYYY-MM-DD');
          }
          return (<ChatMessage key={message.mid} data={message}  avatarClick={this.props.avatarClick} displayDay={displayDay} />);
        })}
      </div>
    );
  };

  handleMessage = (event) => {
    const params = [
      this.state.targetUid
    ];
    if (this.state.lastRequestTime) {
      const now = new Date();
      if (now.getTime() - this.state.lastRequestTime.getTime() > this.state.waitTime) {
        this.setState({ lastRequestTime: new Date() });
        this.props.sendTypingState(params);
      }
    }
    this.setState({ message: event.target.value });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      this.handleSend();
    }
  };

	handleSend = () => {
    if (this.state.message === null || this.state.message !== null && !this.state.message.replace(/\s/g, '').length) {
      // string only contained whitespace (ie. spaces, tabs or line breaks)
      this.setState({ message: null });
      return;
    }
    // replace last enter
    let messageFull = this.state.message.replace(/^\s+|\s+$/g, '');
    let _that = this;
    if (this.state.message !== '') {
      let msgType = 0;
      let params = [
        this.state.targetUid,
        {
          msg_type: msgType,
          text: messageFull.replace('null', '')
        },
      ];
      this.props.sendMessage(params);

      let msgSend = [
        this.state.targetUid,
        (new Date()).getTime(),
        {
          msg_id: (new Date()).getTime(),
          msg_type: msgType,
          text: messageFull,
          forWeb: messageFull.split('\n'),
        },
        this.state.uid,
        true, //mine message (current merchant)
      ];

      saveMessage(msgSend);
      setLastUserMessage(msgSend);
      
      this.setState({message: null});
      this.props.renderChat(this.state.targetUid);

      setTimeout(function(){
        if (_that.state.allowChatScroll) {
          _that._scrollDown(true);
        }
      },0);
    }
  };

  handleAllowScroll = (event) => {
    this.setState({allowChatScroll: event.target.checked})
  }

  sendLocation = () => {
    let _that = this;
    if(this.props.merchantInfo && this.props.merchantInfo.location){
      let params = [
        this.state.targetUid,
        {
          msg_type: 2,
          //text: "Location message",
          location: {
            latitude: this.props.merchantInfo.location.latitude,
            longitude: this.props.merchantInfo.location.longitude
          }
        },
      ];
      
      this.props.sendMessage(params);

      saveMessage([
        this.state.targetUid,
        (new Date()).getTime(),
        {
          msg_id: (new Date()).getTime(),
          msg_type: 2,
          //text: "Location message",
          location: {
            latitude: this.props.merchantInfo.location.latitude,
            longitude: this.props.merchantInfo.location.longitude
          }
        },
        this.state.uid,
        true, //mine message (current merchant)
      ]);
      
      this.setState({message: null});
      this.props.renderChat(this.state.targetUid);
      setTimeout(function(){
        if (_that.state.allowChatScroll) {
          _that._scrollDown(true);
        }
      },0);
    }
  };

  sendEmoticons = (emoticon, event) => {
    let _that = this;
    Promise.resolve(this.setState({message: this.state.message + ' ' + emoticon})) // dispatch
    .then(function (response) {
      _that.handleSend();
      return response;
    });
    this.setState({cannedAnswerNowShow: false})
  };

  handleOpenQrCode = () => {
    if (this.props.activePromotions.length > 0) {
      this.setState({ qrCodeShow: true });
    }
  }

  handleCloseQrCode = () => {
    this.setState({
      qrCodeShow: false,
      tickets: '',
      price: '',
      note: '',
      errorPrice: false,
      errorPriceMsg: '',
      errorTickets: false,
      errorTicketsMsg: ''
    });
  }

  handleSendQrCode = () => {
    const { tickets, price, note } = this.state;
    if (this._validate()) {
      const params = [
        this.state.targetUid,
        {
          msg_type: 16,
          qr_code: {
            promotion: this.props.activePromotions[0].id,
            howManyTickets: parseInt(tickets),
            price: parseInt(price),
            note: note,
          },
          text: 'QR code sent'
        },
      ];
      this.props.sendMessage(params);

      this.setState({
        qrCodeShow: false,
        price: '',
        tickets: '',
        note: '',
        errorPrice: false,
        errorTickets: false,
        errorPriceMsg: '',
        errorTicketsMsg: '',
        noteErrorMsg: ''
      });

      const textMsg = 'QR code sent'
      let msgSend = [
        this.state.targetUid,
        (new Date()).getTime(),
        {
          msg_id: (new Date()).getTime(),
          msg_type: 16,
          text: textMsg
        },
        this.state.uid,
        true,
      ];
      saveMessage(msgSend);
      this.props.renderChat(this.state.targetUid);
    }
  }

  _validate = () => {
    let error = false;
    const { tickets, price } = this.state
    if (tickets === '') {
      this.setState({ errorTickets: true, errorTicketsMsg: "You have to enter tickets" });
      error = true;
    } else if (isNaN(tickets)) {
      this.setState({ errorTickets: true, errorTicketsMsg: "You have to enter numerical value" });
      error = true;
    }
    if (price === '') {
      this.setState({ errorPrice: true, errorPriceMsg: "You have to enter price" });
      error = true;
    } else if (isNaN(price)) {
      this.setState({ errorPrice: true, errorPriceMsg: "You have to enter numerical value" });
      error = true;
    }

    return (error) ? false : true;
	};
  
  handleQrCode = (key, event) => {
    switch(key) {
      case 2: 
        this.setState({tickets: event.target.value, errorTickets: false, errorTicketsMsg: ''})
				break;
      case 3:
        this.setState({price: event.target.value, errorPrice: false, errorPriceMsg: ''})
				break;
      case 4:
        this.setState({note: event.target.value})
				break;
    }
  }

  handleOpenManageTemplate = () => {
    this.setState({
      openManageTemplates: true
    })
  }

  handleCloseManageTemplate = () => {
    this.setState({
      openManageTemplates: false
    })
  }

  handleChangeTab = (e, newValue) => {
		this.setState({
			tabsValue: newValue
		})
  }

  handleChange = (value) => {
    const params = [
      this.state.targetUid
    ];
    if (this.state.lastRequestTime) {
      const now = new Date();
      if (now.getTime() - this.state.lastRequestTime.getTime() > this.state.waitTime) {
        this.setState({ lastRequestTime: new Date() });
        this.props.sendTypingState(params);
      }
    }
    this.setState({ message: value });
  };


  handleOnClickAnswer = (value) => {
    this.handleChange(value)
    this.setState({openManageTemplates: false})
    this.handleCannedClick()
  }

  sendCannedImageMessage = (attachment, caption) => {
    if (caption === null) {
      caption = ''
    }
    if (attachment && attachment !== null) {
      const _that = this;
      if (this.state.message !== '') {
        let params = [
          this.state.targetUid,
          {
            msg_type: 3,
            text: caption,
            attachment
          },
        ];
        this.props.sendMessage(params);
        this.props.resetAttachment();
        saveMessage([
          this.state.targetUid,
          (new Date()).getTime(),
          {
            msg_id: (new Date()).getTime(),
            msg_type: 3,
            text: caption,
            attachment
          },
          this.state.uid,
          true, //mine message (current merchant)
        ]);
        // this.setState({ message: null });
        this.props.renderChat(this.state.targetUid);
        setTimeout(() => {
          if (this.state.allowChatScroll) {
            _that._scrollDown(true);
          }
        },0);
        this.setState({openManageTemplates: false})
      }
    }
  };

  handleOnClickImage = (image, caption) => {
    this.sendCannedImageMessage( image, caption)
  }

  onUploadDrop = (acceptedFiles) => {
    const self = this;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
			const preview = URL.createObjectURL(file)
      const fr = new FileReader();
      fr.readAsArrayBuffer(file);

      fr.addEventListener('loadend', () => {
        if (getRealMimeType(fr.result) === 'unknown') {
          self.props.uploadError([0, 'Please upload image file']);
        } else {
          self.setState({ openCrop: true });
          const obj = { photo: preview };
          self.setState({ storeImage: obj });
        }
      });
    }
  }

	handleClosePhotoSelection = () => {
		this.setState({
			openCrop: false,
			storeImage: { photo: null }
		});   
	}

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  handleImageLoaded = (image) => {
		this.imageRef = image
		const width = 100;
    const height = 100;
    const x =	10;
    const y = 30;

    this.setState({
      crop: {
        unit: '%',
        aspect: 1,
        width,
        height,
        x,
        y,
      },
    });

    return false;
	}

	handleImageCropComplete = (crop) => {
		this.setState({cropedRes: crop})
	}

  handleCrop = () => {
    if (this.imageRef && this.state.crop) {
      const { crop } = this.state
			const canvas = document.createElement("canvas");
			const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
			const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(
					this.imageRef,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width,
					crop.height
			)

			const base64Image = canvas.toDataURL('image/png');
			let dataArr = base64Image.split('base64,');
			let fileSize = str2ab(atob(dataArr[1])).byteLength;

			localStorage.setItem('fileToUpload', atob(dataArr[1]));
      this.props.beginUploadMessage([8, fileSize]);
      this.setState({
        openCrop: false,
        storeImage: { photo: null },
        imageCrop: base64Image
      });
    }
  }

  handleOpenOrderNow = () => {
    this.setState({ orderNowShow: true });
  }

  handleCloseOrderNow = () => {
    this.setState({ orderNowShow: false, orderText: 'Do you agree with order of', orderNowPrice: '', orderCurrency: localStorage.getItem('currency') || 'PHP' });
  }

  handleChangeOrderNowCurrency = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleResizeChatBox = () => {
    this.setState(prevState => ({
      chatResized: !prevState.chatResized
    }));
  }

  handleCloseResizedChatBox = () => {
    this.setState({ chatResized: false });
  }

  handleOrderNow = (key, event) => {
    this.setState({
      orderNowPriceError: false,
      orderNowPriceErrorMsg: ''
    })
    const params = [
      this.state.targetUid
    ];
    if (this.state.lastRequestTime) {
      const now = new Date();
      if (now.getTime() - this.state.lastRequestTime.getTime() > this.state.waitTime) {
        this.setState({ lastRequestTime: new Date() });
        this.props.sendTypingState(params);
      }
    }

    this.setState({
      [key]: event.target.value
    })
  }

  validatePrice = () => {
    if (this.state.orderNowPrice.match(/^[0-9.]+$/) != null || this.state.orderNowPrice === '') {
      return true
    }
    this.setState({
      orderNowPriceError: true,
      orderNowPriceErrorMsg: 'You have to enter numerical value'
    })
  }

  handleSendOrderNow = () => {
    const { php, eur, usd, orderCurrency, orderNowMsg, orderNowPrice, targetUid, allowChatScroll } = this.state;
    let orderNowCurrency = php ? 'PHP' : eur ? 'EUR' : usd ? 'USD' : orderCurrency;
    if (this.validatePrice()) {
      const text = { message: orderNowMsg, value: orderNowPrice, currency: orderNowCurrency };
      const messageFull = `${orderNowMsg}, Price: ${orderNowPrice} ${orderNowCurrency}`;

      const params = [
        targetUid,
        {
          msg_type: 12,
          text: JSON.stringify(text)
        },
      ];
      this.props.sendMessage(params);

      const msgSend = [
        targetUid,
        (new Date()).getTime(),
        {
          msg_id: (new Date()).getTime(),
          msg_type: 12,
          text,
          forWeb: messageFull.split('\n'),
        },
        this.state.uid,
        true, //mine message (current merchant)
      ];

      saveMessage(msgSend);
      setLastUserMessage(msgSend);

      this.handleCloseOrderNow();
      this.props.renderChat(targetUid);

      setTimeout(() => {
        if (allowChatScroll) {
          this._scrollDown(true);
        }
      }, 0);
    }
  }
	render() {
		const {
      tickets,
      message,
      qrCodeShow,
      promotionTitle,
      price,
      note,
      errorTickets,
      errorTicketsMsg,
      errorPrice,
      errorPriceMsg,
      tabsValue,
      openManageTemplates,
      cannedAnswers,
      cannedImages,
      openCrop,
      storeImage,
      crop,
      orderNowShow,
      usd,
      eur,
      php,
      chatResized,
      orderNowPrice,
      orderNowMsg,
      orderNowPriceError,
      orderNowPriceErrorMsg
    } = this.state;
    const { userSelected, merchantInfo } = this.props;
		const typingShow = this.state.typing === true ? '<strong>' + 'User' + '</strong> is typing' : '';
    const data = tabsValue === 0 ? cannedAnswers : cannedImages;
    const all = this.renderAllMessages();
    let isVisibleBackdrop = chatResized ? 'visible' : 'hidden';
    let chatBigger = chatResized ? 'messages-box--chat--resized' : '';
    let icons = {};
    let check = (<CheckIcon className="chat-qrcode-circle"/>);
    let iconsDefaults = {
      promotionTitle: (
        <div className="chat-qrcode-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="chat-qrcode-num">1</Typography>
        </div>),
      tickets: (
        <div className="chat-qrcode-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="chat-qrcode-num">2</Typography>
        </div>),
			price: (
        <div className="chat-qrcode-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="chat-qrcode-num">3</Typography>
        </div>),
			note: (
        <div className="chat-qrcode-wrapper-num">
          {/* <img src={Circle} className={s.circle} alt="circle" /> */}
          <Typography className="chat-qrcode-num">4</Typography>
        </div>),
    };
    icons.title = promotionTitle === "" ? iconsDefaults.promotionTitle : check;
    icons.tickets = tickets === "" ? iconsDefaults.tickets : check;
		icons.price = price === "" ? iconsDefaults.price : check;
		icons.note = note === "" ? iconsDefaults.note : check;
		return (
      <Fragment>
        <Grid onClick={this.handleCloseResizedChatBox} className={`message-box--chat-backdrop message-box--chat-backdrop--${isVisibleBackdrop}`}></Grid>
        <Grid className={`messages-box messages-box--chat ${chatBigger} overflow-hidden shadow`}>
          <Grid onClick={this.handleResizeChatBox} className="message-box--chat-resize-icon">
            <ResizeIcon />
          </Grid>
          <Grid style={{ flex: '1 1 auto', height: '480px', position: 'relative' }} container item xs={12} justify="center" alignItems="center">
            {userSelected ? all :
            <Typography className='chat-main-no-messages-text' variant="h3">Answer and communicate with client as soon as possible to achieve best customer service and satisfaction!</Typography>}
          </Grid>
          <Grid style={{ flex: '0 0 auto' }} container item xs={12} alignItems="center">
            <Grid className="messages-box--chat-bottom-bar">
              <div className='typingShow' dangerouslySetInnerHTML={{__html: typingShow}}></div>
              <Grid className="messages-box--chat-bottom-bar-textfield">
                <TextField
                  disabled={userSelected ? false : true}
                  className="messages-box--chat-bottom-bar-input"
                  fullWidth
                  color="secondary"
                  variant="filled"
                  placeholder='Write Message'
                  value={message ? message : ''}
                  onKeyPress={this.handleKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="messages-box--chat-bottom-bar-input-icon" position="start">
                        
                        <IconButton
                          disabled={userSelected === true ? false : true}
                          onClick={this.handleSend}
                          variant="contained"
                          color="primary"
                          className="messages-box--chat-bottom-bar-input-icon-circle"
                        ><SendIcon className='messages-box--chat-bottom-bar-input-send-icon'/></IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleMessage}
                />
              </Grid>
              <Grid className="messages-box--chat-bottom-bar-options" container item alignItems="center">
                <Grid container item xs={4} alignItems="center" justify="space-around">
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.handleOpenManageTemplate}
                  >
                    <PaperIcon/>
                  </IconButton>
                  <IconButton
                    disabled={userSelected === true ? false : true}
                  >
                    <Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this)} multiple={false} accept="image/*">
                      {({getRootProps, getInputProps}) => (
                        <div>
                          <div {...getRootProps()} style={{display: 'flex'}}>
                            <input {...getInputProps()} />
                            <PhotoIcon />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </IconButton>
                  {this.props.userType === 2 && this.props.merchantInfo && this.props.merchantInfo.enable_qr_code === true ?
                    <IconButton
                      disabled={userSelected === true ? false : true}
                      onClick={this.handleOpenQrCode}
                    >
                      <img src={QrCode} style={{cursor: 'pointer'}}/>
                    </IconButton>
                    :
                  // this.props.agentInfo && this.props.agentInfo.enable_qr_code === true ? 
                  // 	<img src={QrCode} style={{cursor: 'pointer'}} onClick={this.handleOpenQrCode}/>
                  //   :
                  // this.props.generalAgentInfo && this.props.generalAgentInfo.enable_qr_code === true ?
                  //   <img src={QrCode} style={{cursor: 'pointer'}} onClick={this.handleOpenQrCode}/>
                  //   :
                  null}
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.sendLocation.bind(this)}
                  >
                    <Marker/>
                  </IconButton>
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.sendEmoticons.bind(this, "😉")}
                  >
                    <SmileFirstIcon/>
                  </IconButton>
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.sendEmoticons.bind(this, "😊")}
                  >
                    <SmileSecondIcon/>
                  </IconButton>
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.sendEmoticons.bind(this, "😃")}
                  >
                    <SmileThirdIcon/>
                  </IconButton>
                  <IconButton
                    disabled={userSelected === true ? false : true}
                    onClick={this.sendEmoticons.bind(this, "👍")}
                  >
                    <Like/>
                  </IconButton>
                </Grid>
                <Grid container item justify="center" alignItems="center" xs={4}>
                  <Monitor style={{ marginRight: 5 }} />
                  <Typography>Menager Templates</Typography>
                </Grid>
                <Grid className="messages-box--chat-bottom-bar-options-item--checkbox" container item justify="flex-end" alignItems="center" xs={4}>
                  <Checkbox name="checkedA" onChange={this.handleAllowScroll} checked={this.state.allowChatScroll}/>
                  <InputLabel htmlFor="checkedA">Auto-scroll chat</InputLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="messages-wrapper-buttons messages-wrapper-buttons--chat" container item justify="space-between">
          <Button
            onClick={this.props.handleMessageItemLeave}
            disabled={this.props.chatAssigned ? false : true}
            className="messages-wrapper-button" variant="outlined">
            Leave Chat
          </Button>
          <Button
            disabled={userSelected ? merchantInfo ? merchantInfo.order_now === '' || merchantInfo.order_now === null ? true : false : true : true}
            className="messages-wrapper-button messages-wrapper-button--green"
            variant="contained"
            onClick={this.handleOpenOrderNow}
          >
            Confirm Order
          </Button>
        </Grid>
        <Dialog
          className="scan-popup shadow"
          open={qrCodeShow}
          onClose={this.handleCloseQrCode}
        >
          <DialogTitle className="scan-popup-text">
            QR Code
          </DialogTitle>
          <DialogContent className="scan-popup-content">
            <Grid className="scan-popup-content-wrap">
              <FormGroup className="scan-popup-form-wrap">
                <Grid className="scan-popup-form-wrap" container item>
                  {/* <Grid className="scan-popup-form-step"> */}
                    {icons.title}
                  {/* </Grid> */}
                  <Grid className="scan-popup-form-textfield" container item direction="column">
                    <InputLabel className="scan-popup-form-label">Promotion Title</InputLabel>
                    <TextField 
                      className="scan-popup-form-input"
                      defaultValue={promotionTitle ? promotionTitle : ''}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid className="scan-popup-form-wrap" container item>
                  {/* <Grid className="scan-popup-form-step"> */}
                    {icons.tickets}
                  {/* </Grid> */}
                  <Grid className="scan-popup-form-textfield" container item direction="column">
                    <InputLabel className="scan-popup-form-label">How Many Tickets</InputLabel>
                    <TextField 
                      className="scan-popup-form-input"
                      placeholder='Enter How Many Tickets Here'
                      defaultValue={tickets ? tickets : ''}
                      onChange={this.handleQrCode.bind(this, 2)}
                      error={errorTickets}
                      helperText={errorTickets ? errorTicketsMsg : ''}
                    />
                  </Grid>
                </Grid>
                <Grid className="scan-popup-form-wrap" container item>
                  {icons.price}
                  {/* <Grid className="scan-popup-form-step">
                    <span>3</span>
                  </Grid> */}
                  <Grid className="scan-popup-form-textfield" container item direction="column">
                    <InputLabel className="scan-popup-form-label">Price</InputLabel>
                    <TextField 
                      className="scan-popup-form-input"
                      placeholder='Enter Price Here'
                      defaultValue={price ? price : ''}
                      onChange={this.handleQrCode.bind(this, 3)}
                      error={errorPrice}
                      helperText={errorPrice ? errorPriceMsg : ''}
                    />
                  </Grid>
                </Grid>
                <Grid className="scan-popup-form-wrap" container item>
                  {icons.note}
                  {/* <Grid className="scan-popup-form-step">
                    <span>4</span>
                  </Grid> */}
                  <Grid className="scan-popup-form-textfield" container item direction="column">
                    <InputLabel className="scan-popup-form-label">Note</InputLabel>
                    <TextField 
                      className="scan-popup-form-input"
                      placeholder='Enter Note Here'
                      defaultValue={note ? note : ''}
                      multiline
                      onChange={this.handleQrCode.bind(this, 4)}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
              <Grid className="scan-popup-buttons" container item justify="flex-end">
                <DialogActions>
                  <Button
                    className='promotion-main-outlined-buttons promotion-main-outlined-buttons--cancel'
                    variant="outlined"
                    onClick={this.handleCloseQrCode}
                  >
                    Close
                  </Button>
                  <Button
                    className='promotion-main-buttons promotion-main-buttons-dialog'
                    variant="contained"
                    color="primary"
                    onClick={this.handleSendQrCode}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Grid>
            </DialogContent>
        </Dialog>
        <Dialog
          className="chat-popup shadow"
          open={openManageTemplates}
          onClose={this.handleCloseManageTemplate}
        >
          <DialogContent
            className="chat-manage-template-content"
          >
            <Paper square>
              <Tabs
                className='chat-manage-templates-tabs'
                centered
                indicatorColor="primary"
                textColor="primary"
                value={tabsValue}
                onChange={this.handleChangeTab}
                variant="fullWidth"
              >
                <Tab
                  className='chat-manage-templates-tab'
                  label="Answers"
                />
                <Tab
                  className='chat-manage-templates-tab'
                  label="Images"
                />
              </Tabs>
            </Paper>
            <div style={{overflowY: 'auto'}}>
              <p className='chat-manage-templates-content-header-text'>{tabsValue === 0 ? cannedAnswers.length > 0 ? 'Predefined answers' : 'No predefined answers' : cannedImages.length > 0 ? 'Predefined images' : 'No predefined images'}</p>
              <div>
                {data.length > 0 ? data.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      {tabsValue === 0 ?
                        <p onClick={this.handleOnClickAnswer.bind(this, item.answer)} style={{cursor: 'pointer'}}>{item.answer}</p>
                      : 
                        <div className='chat-card-main-div' onClick={this.handleOnClickImage.bind(this, item.image, item.caption)}>
                          <Card className='chat-card'>
                            <CardMedia
                              className='chat-manage-templates-card-media'
                              image={item.image}
                            />
                          </Card>
                          <p className='chat-manage-templates-card-text'>{item.caption}</p>
                        </div>
                      }
                    </ListItem>
                  )
                }) : null}
              </div>
            </div>
            <DialogActions className='chat-manage-template-button'>
              <Button
                className='promotion-main-outlined-buttons'
                variant="outlined"
                onClick={this.handleCloseManageTemplate}
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <ColorDialog
          className="bizz-color-dialog"
          aria-labelledby="max-width-dialog-title"
          open={openCrop}
          onClose={this.handleClosePhotoSelection}

        >
          <DialogTitle className="bizz-color-dialog-title" disableTypography>
            Add Logo and Images
          </DialogTitle>
          <DialogContent className="bizz-color-dialog-content">
            <Cropper
              className="bizz-color-dialog-cropper"
              src={storeImage.photo}
              onChange={this.onCropChange}
              crop={crop}
              ref="imageCrop"
              onImageLoaded={this.handleImageLoaded}
              onComplete={this.handleImageCropComplete}
            />
          </DialogContent>
          <DialogActions className="bizz-color-dialog-buttons">
            <Button className='bizz-color-dialog-button bizz-color-dialog-button--first' autoFocus onClick={this.handleClosePhotoSelection} variant="contained" color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCrop} className='bizz-color-dialog-button bizz-color-dialog-button--second' variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </ColorDialog>
        <Dialog
          open={orderNowShow}
          onClose={this.handleCloseOrderNow}
          className='chat-order-now-dialog'
        >
          <DialogTitle>
            <p className='chat-order-now-title'>Confirm Order</p>
          </DialogTitle>
          <DialogContent className='chat-order-now-content'>
            <TextField 
              className="chat-order-now-msg"
              placeholder='Enter Message Here'
              value={orderNowMsg}
              onChange={this.handleOrderNow.bind(this, 'orderNowMsg')}
            />
            <TextField 
              className="chat-order-now-msg"
              placeholder='Enter Price Here'
              defaultValue={orderNowPrice ? orderNowPrice : ''}
              error={orderNowPriceError}
              helperText={orderNowPriceError ? orderNowPriceErrorMsg : ''}
              onChange={this.handleOrderNow.bind(this, 'orderNowPrice')}
            />
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox className='chat-order-now-checkbox' disabled={eur ? true : usd ? true : false } checked={php} onChange={this.handleChangeOrderNowCurrency} name="php" />}
                label="PHP"
              />
              <FormControlLabel
                control={<Checkbox className='chat-order-now-checkbox' disabled={php ? true : usd ? true : false } checked={eur} onChange={this.handleChangeOrderNowCurrency} name="eur" />}
                label="EUR"
              />
              <FormControlLabel
                control={<Checkbox className='chat-order-now-checkbox' disabled={eur ? true : php ? true : false } checked={usd} onChange={this.handleChangeOrderNowCurrency}  name="usd" />}
                label="USD"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button className='bizz-color-dialog-button bizz-color-dialog-button--first' autoFocus onClick={this.handleCloseOrderNow} variant="contained" color="primary">
              Close
            </Button>
            <Button onClick={this.handleSendOrderNow} className='bizz-color-dialog-button bizz-color-dialog-button--second' variant="contained" color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
		)
	}
}

Chat.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ messages, promotions, auth, common }) => {
  return {
		token: auth.token,
    merchantInfo: common.merchantInfo,
		uid: auth.uid,
		userType: auth.userType,
		allowChatScroll: messages.allowChatScroll,
		activePromotions: promotions.activePromotions,
		usertyping: messages.usertyping,
		attachment: auth.attachment,
		oldMessagesDate: messages.oldMessagesDate,
    cannedImages: common.cannedImages,
		cannedAnswers: common.cannedAnswers
  }
}, (dispatch) => {
  const actions = {
    allowChatScrolling,
    showTypingOff,
    setChatMessageState,
    resetAttachment,
    getMerchantCard,
    refreshNavigation,
    getMessagesBefore,
    sendTypingState,
    sendMessage,
    listMerchantCannedImages,
    listMerchantCannedAnswers,
    uploadError,
    beginUploadMessage
  };
  return bindActionCreators(actions, dispatch);
})(Chat);