import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux'
// import { restoreAuth, setProtectedReferrer } from '../../store/actions'
import { isDefined } from '../../lib/utils'

class ProtectedRoute extends Component {
	// state = {
	// 	loading: true
	// }

	async componentDidMount() {
		const { Component, render } = this.props
		if (isDefined(localStorage.getItem('token')) && localStorage.getItem('token') && (Component || render)) {
			// this.setState({ loading: false })
		}
		//  else {
		// 	this.props.dispatch(setProtectedReferrer(path))
		// 	this.props.dispatch(restoreAuth())
		// 	// this.setState({ loading: false })
		// }
	}

	render() {
		const { component: Component, render, ...rest } = this.props
		// const { loading } = this.state
		return <Route { ...rest } render={ localStorage.getItem('token') && render ? render : () =>
			localStorage.getItem('token') && Component
				? <Component { ...rest }/>
				: <Redirect to='/register' />
		} />
	}
}

export default connect(({auth}) => {
  return {
    token: auth.token,
    uid: auth.uid,
    userType: auth.userType,
    isAuthorized: auth.isAuthorized,
    isValidToken: auth.isValidToken
  }
}, (dispatch) => {
  const actions = {};
  return bindActionCreators(actions, dispatch);
})(ProtectedRoute);