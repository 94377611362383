import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography, Dialog, DialogActions, Radio, FormControlLabel, DialogContent, Button, TextField, DialogTitle, RadioGroup } from '@material-ui/core';
import { getMerchantCard, getStoreCategories, getMerchantMaxDistance, uploadError, beginUpload, addMerchantMaxDistance, addMerchantCard } from '../../store/common/actions';
import { connectWeb } from '../../store/auth/actions';
import { isReadonly, getRealMimeType, str2ab }from '../../lib/utils';
import Dropzone from 'react-dropzone';
import camera from './images/camera_icon.svg';
import Cropper from 'react-image-crop';
import Map from '../../components/Map/Map';
import CheckIcon from '@material-ui/icons/CheckCircle';
import WrapperContent from '../../components/WrapperContent';
import 'react-image-crop/dist/ReactCrop.css';
import { CreateIconCustom, PrettoSlider, ColorDialog } from './styles.js';
import './styles.css';

class BiizCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      stepIndex: 0,
      selectedCategory: -1,
      selectedCategoryObj: {},
      biizName: '',
      website: '',
      description: '',
      biizNameError: false,
      websiteError: false,
      descriptionError: false,
      categoryError: '',
      maxDistance: '',
      maxDistanceError: '',
      selectedLocation: null,
      geolocationDisabled: true,
      geolocationDisabledError: '',
      logoImage: '',
      logo: {},
      storeImage: '',
      searchBox: '',
      address: '',
      googleObject: {},
      imageCrop0: '',
      imageCrop1: '',
      imageCrop2: '',
      imageCrop3: '',
      imageCropValues: '',
      imageCropLoaded: false,
      imageCropBeforeLoaded: false,
      openCrop: false,
      logoIndex: null,
      fileSize: 0,
			crop: {
				unit: 'px',
				x: 10,
				y: 30,
				width: 200,
				height: 300,
			},
			cropedRes: {},
			merchantData: null,
			merchantCategories: {},
			title: '',
			categoryOpen: false,
			merchantCategory: '',
			phoneNumber: '',
			cancelMap: false,
			saveMerchant: false
    };
  }

  static getDerivedStateFromProps(props, state) {
		if (state.merchantData !== props.merchantInfo) {
			return {
				merchantData: props.merchantInfo,
				selectedLocation: { latitude: props.merchantInfo.location ? props.merchantInfo.location.latitude : 0 , longitude: props.merchantInfo.location ? props.merchantInfo.location.longitude : 0}
			}
		} else {
			return null;
		}
  }

	componentDidMount() {
    this.props.getMerchantCard();
		this._updateState();
    this.props.getStoreCategories();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.maxDistance !== this.props.maxDistance) {
      this.setState({
        maxDistance: this.props.maxDistance
      });
    }	else if (this.props.maxDistance && this.state.maxDistance === '') {
      this.setState({
        maxDistance: this.props.maxDistance
      });
    }
		
		if (prevProps.merchantInfo === null) {
				if (this.props.merchantInfo !== null) {
					this._updateState(this.props);
				}
		}
		if (this.props.merchantCategories && (prevState.merchantCategory === '') && this.props.merchantInfo) {
			Object.keys(this.props.merchantCategories).map((key) => {
				if (this.props.merchantCategories[key].id === this.props.merchantInfo.category) {
					this.setState({merchantCategory: this.props.merchantCategories[key].english})
				}
			})
		}
	}

  _updateState = (data) => {
    let d = data === undefined ? this.props.merchantInfo : data.merchantInfo;
    if(d !== null){
      let info = d;
			this.setState({
				biizName: info.name,
				website: info.website,
				description: info.description,
				phoneNumber: info.phone
			})
      // checkGeolocationAvailability()
      //   .then(() => {
      //     this.setState({
      //       geolocationDisabled: false,
      //     });
      //     let location = info.location;
      //     if (location !== undefined) {
			// 			this.setState({selectedLocation: { latitude: location.latitude, longitude: location.longitude }})
      //     }
      //   })
      //   .catch((err) => {
      //     console.warn(err.message);
			// 		this.setState({selectedLocation: { latitude: 0, longitude: 0 }})
      //   });
      this.props.getMerchantMaxDistance();
			this.setState({selectedCategory: info.category})
      //this.state.selectedSubcategory = info.subcategory;

      info.logo ? this.setState({imageCrop0: info.logo}) : '';
      (info.covers && info.covers.left) ? this.setState({imageCrop1: info.covers.left}) : '';
      (info.covers && info.covers.left) ? this.setState({imageCrop2: info.covers.center}) : '';
      (info.covers && info.covers.right) ? this.setState({imageCrop3: info.covers.right}) : '';
    }
  };

  onUploadDrop = (logoIndex, acceptedFiles) => {
    this.setState({logoIndex: logoIndex});
    let index = logoIndex;
    let self = this;
    if(acceptedFiles.length > 0){
      let file = acceptedFiles[0];
			const preview = URL.createObjectURL(file)
      const fr = new FileReader();
      fr.readAsArrayBuffer(file);
      fr.addEventListener("loadend", function() {
        if (getRealMimeType(fr.result) === 'unknown'){
          self.props.uploadError([0, 'Please upload image file']);
        } else {
          self.setState({openCrop: true});
          let obj = {photo: preview};
          self.setState({storeImage: obj});

          if(index === '0'){
						self.setState({crop: {
							...self.state.crop,
							width: 300,
							height: 300
						}});
          }	else if(index === '1'){
							self.setState({crop: {
								...self.state.crop,
								width: 200,
								height: 300
						}});
          } else if(logoIndex === "2"){
							self.setState({
								crop: {
									...self.state.crop,
									width: 400,
									height: 300
								}
							});
          }
        }
      });
    }
	}

	handleClosePhotoSelection = () => {
		this.setState({
			openCrop: false,
			storeImage: { photo: null }
		});   
	}

  handleImageLoaded = (image) => {
		this.imageRef = image
		const width = 200;
    const height = 300;
    const x =	10;
    const y = 30;

    this.setState({
      crop: {
        unit: 'px',
        aspect: 1,
        width,
        height,
        x,
        y,
      },
    });

    return false;
	}

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

	handleImageCropComplete = (crop) => {
		this.setState({cropedRes: crop})
	}

  handleCrop = () => {
		if (this.imageRef && this.state.crop) {
			const { crop } = this.state
			const canvas = document.createElement("canvas");
			const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
			const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(
					this.imageRef,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width,
					crop.height
			)

			const base64Image = canvas.toDataURL('image/png');
			let dataArr = base64Image.split('base64,');
			let fileSize = str2ab(atob(dataArr[1])).byteLength;

			localStorage.setItem('fileToUpload', atob(dataArr[1]));

			switch(this.state.logoIndex){
				case '0':
					this.setState({
						imageCrop0: base64Image
					});
					break;
				case '1':
					this.setState({
						imageCrop1: base64Image
					});
					break;
				case '2':
					this.setState({
						imageCrop2: base64Image
					});
					break;
				case '3':
					this.setState({
						imageCrop3: base64Image
					});
					break;
			}

			if(this.state.logoIndex === "0") {
				this.props.beginUpload([4, fileSize]);
			} else{
				this.props.beginUpload([6, fileSize, parseInt(this.state.logoIndex)]);     
			}
			this.setState({
				openCrop: false,
				storeImage: { photo: null }
			});
		}
	}

	onUpdateGoogleObject = (googleObject) => {
		this.setState({googleObject: googleObject});
	}

	onLocationChanged = (data) => {
		let coords = data[0];
		let address = data[1] !== undefined ? data[1].formatted_address : '';
		this.setState({selectedLocation: coords});
		this.setState({address: address});
	};

	handleMaxDistanceChange = (event) => {
		let value = event.target.value
		if (!value) {
			value = 0
		}
		let result = parseInt(value, 10);
		this.setState({ maxDistance: result});
		this.setState({ maxDistanceError: '' });
	}

	sliderChange = (event, value) => {
		this.setState({ maxDistance: value });
	}

	checkUrl = (path) => {
		if (path === '' || path === null) return true;

		// With https:  let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
		let regexp = /(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ //eslint-disable-line
		return regexp.test(path);
	}

  handleWebsiteChange = (event) => {
		this.setState({website: event.target.value});
		if(!this.checkUrl(event.target.value)) {
			this.setState({websiteError: true});
		} else {
			this.setState({websiteError: false});
		}
  };

  handleBiizNameChange = (event) => {
		
		if(event.target.value === ' ' || event.target.value === null) {
			this.setState({biizNameError: true});
		} else {
			this.setState({biizName: event.target.value, biizNameError: false});
		}
  };

  handleDescriptionChange = (event) => {
		this.setState({description: event.target.value});
		if(event.target.value === ' ' || event.target.value === null) {
			this.setState({descriptionError: true});
		} else {
			this.setState({descriptionError: false});
		}
  };

	handleCategoryChange = () => {
		this.setState({categoryOpen: true})
	}

	handleCloseCategory = () => {
		this.setState({categoryOpen: false})
	}

	handleChangeMerchantCategories = (event) => {
		let categoryId = null;
		Object.keys(this.props.merchantCategories).map((key) => {
			if (this.props.merchantCategories[key].english === event.target.value) {
				categoryId = this.props.merchantCategories[key].id
			}
		})
		this.setState({merchantCategory: event.target.value, selectedCategory: categoryId})
	}

	handlePhoneChange = (event) => {
		this.setState({phoneNumber: event.target.value})
	}

	handleSaveMerchantInfo = () => {
		if(!isReadonly()) {
			this.setState({saveMerchant: true})
      this._saveBiizCard();
			this.props.getMerchantCard();
    }
	}

	_saveBiizCard = () => {
		this.props.addMerchantMaxDistance([parseFloat(this.state.maxDistance)]);
    let data = {
      name: this.state.biizName,
      description: this.state.description,
      website: (this.state.website !== null) ? this.state.website : "",
			phone: this.state.phoneNumber ? this.state.phoneNumber : '',
      category: this.state.selectedCategory,
      address: this.state.address,
      location: this.state.selectedLocation,
    };
    this.props.addMerchantCard([data]);
	}

	handleCancelMerchantInfo = () => {
		let info = this.props.merchantInfo;
		let maxDistance = this.props.maxDistance;
		this.setState({
			biizName: info.name,
			description: info.description,
			website: info.website,
			phoneNumber: info.phone,
			selectedCategory: info.category,
			address: info.address,
			selectedLocation: info.location,
			maxDistance,
			cancelMap: true
		})
	}

  render() {
		const { biizName, description, descriptionError, biizNameError } = this.state
		const isImageExist0 = this.state.imageCrop0 === '' ? 'normal' : 'avatar'
		const isImageExist1 = this.state.imageCrop1 === '' ? 'normal' : 'avatar'
		const isImageExist2 = this.state.imageCrop2 === '' ? 'normal' : 'avatar' 
		const isImageExist3 = this.state.imageCrop3 === '' ? 'normal' : 'avatar'
		
		let check = (<CheckIcon className='circle'/>);
		let icons = {};
		// let geolocationMessage = '';
		// if (geolocationDisabled) {
		// 	geolocationMessage = (
		// 		<div style={{
		// 			textAlign: 'left',
		// 			height: '20px',
		// 			color: 'red',
		// 			fontSize: '12px',
		// 		}}>For automatic location finding, you need to enable browser location</div>
		// 	);
		// }

		// let iconsDefaults = {
    //   title: (
    //     <div className='wraper'><img src={Circle} className='circle' alt="circle" />
    //       <span className='number'>1</span>
    //     </div>),
    //   password: (
    //     <div className='wraper'><img src={Circle} className='circle' alt="circle" />
    //       <span className='number'>2</span>
    //     </div>),
    // };

		icons.title = check;

    // icons.title = this.state.title === "" ? iconsDefaults.title : check;

		return (
			<WrapperContent header headerAsideTitle="My Biiz Card" logout={true}>
				<Layout className="bizz-card-layout">
					<div className='my-biiz-main-div'>
						<Grid item xs container direction='row'>
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
							<Grid className="bizz-col bizz-col--left" item container xs direction='row'>
								<Grid item container direction='column'>
									<Grid className="bizz-wrapper-title bizz-wrapper-title--first" item container>
										{icons.title}
										<Typography className="bizz-box-title">Main Info</Typography>						
									</Grid>
									<Grid className="bizz-main-info-box shadow border-radius" item xs={12} sm container direction='row' alignItems="baseline">
										<Grid item xs={6} sm>
											<Grid className="bizz-main-info-wrap" container item xs={12} sm direction="row" mb={20}>
												<Grid className="bizz-main-info-textfield" container item xs={12} sm alignItems="center">
													<Typography>Merchant Name</Typography>
													<CreateIconCustom />
												</Grid>
												<Grid item xs={12}>
													<TextField
														className="bizz-main-info-input"
														fullWidth
														placeholder={this.props.merchantInfo ? this.props.merchantInfo.name : ''}
														value={biizName !== '' ? biizName : ''}
														onChange={isReadonly() ? null : this.handleBiizNameChange}
														error={biizNameError}
														helperText={biizNameError ? "Please enter your Biiz name" : ''}
													/>
												</Grid>
											</Grid>
											<Grid className="bizz-main-info-wrap" container item xs={12} sm alignItems="center">
												<Grid className="bizz-main-info-textfield" container item xs={12} sm alignItems="center">
													<Typography>Description</Typography>
													<CreateIconCustom />
												</Grid>
												<Grid item xs={12}>
													<TextField
														className="bizz-main-info-input"
														fullWidth
														placeholder={this.props.merchantInfo ? this.props.merchantInfo.description : ''}
														value={description !== '' ? description : ''}
														onChange={isReadonly() ? null : this.handleDescriptionChange}
														error={descriptionError}
														helperText={descriptionError ? "Please enter description" : ''}
													/>
												</Grid>
											</Grid>
											<Grid className="bizz-main-info-wrap" container item xs={12}>
												<Grid className="bizz-main-info-textfield" container item xs={12} sm alignItems="center">
													<Typography className="bizz-main-info-category bizz-main-info-category--colored">Category</Typography>
														<p className="bizz-main-info-category bizz-main-info-category--action">
															<span>(tap</span> <span className="bizz-main-info-category-here" onClick={this.handleCategoryChange}>here</span> <span>to change)</span> 
														</p>
												</Grid>
												<Grid item container xs={12}>
													<Typography className="bizz-main-info-category--changer">
														{this.state.merchantCategory}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid className="bizz-main-info-logo-wrapper" item container xs={6} justify="center" alignItems="center">
											<div>
												<Grid className="bizz-main-info-logo-title" container item xs={12} alignItems="flex-end">
													<Typography className="bizz-main-info-logo-title-text bizz-main-info-logo-title-text--pink">Logo</Typography>
													<Typography className="bizz-main-info-logo-title-text">(tap logo to change)</Typography>
												</Grid>
												<Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '0')} multiple={false} accept="image/*">
													{({getRootProps, getInputProps}) => (
														<Grid>
															<Grid {...getRootProps()}>
																<input {...getInputProps()} />
																<Grid className="bizz-main-info-logo-img">
																	<img className={`bizz-photos-img bizz-photos-img--${isImageExist0}`} draggable={false} src={this.state.imageCrop0 === '' ? null : this.state.imageCrop0} />
																</Grid>
															</Grid>
														</Grid>
													)}
												</Dropzone>
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Grid className="bizz-location-wrapper" item container direction='column'>
									<Grid className="bizz-wrapper-title" item container> 
										{icons.title}
										<Typography className="bizz-box-title">Location</Typography>
										<span className="bizz-location-title-text">(click on map to change location)</span>
									</Grid>
									<Grid className="bizz-location-box overflow-hidden shadow border-radius" item container>
										{ this.props.merchantInfo ? <Map
											onLocationChanged={isReadonly() ? null : this.onLocationChanged}
											radius={this.state.maxDistance}
											location={this.state.selectedLocation}
											onUpdateGoogleObject={this.onUpdateGoogleObject}
											googleObject={this.state.googleObject}
											cancel={this.state.cancelMap}
											save={this.state.saveMerchant}
										/> : null }
									</Grid>
								</Grid>
								<Grid item container direction='column'>
									<Grid className="bizz-wrapper-title" item container>
										{icons.title}
										<Typography className="bizz-box-title">Distance</Typography>
									</Grid>
									<Grid className="bizz-distance-box shadow border-radius" item container>
										<Grid item container alignItems="flex-start">
											<Grid container>
												<Grid item container xs={9} alignItems="center">
													<Typography>Maximum distance from clients which are able to see promotions</Typography>
												</Grid>
												<Grid item container xs={2} alignItems="center">
													<TextField
														className='bizz-distance-input'
														color="secondary"
														variant="filled"
														onChange={isReadonly() ? null : this.handleMaxDistanceChange}
														value={this.state.maxDistance === '' ? 0 : this.state.maxDistance}
													/>
												</Grid>
												<Grid item container xs={1} alignItems="center">
													<Typography>km</Typography>
												</Grid>
											</Grid>
											<Grid item container>
												<Grid item container xs={9} alignItems="center">
													<PrettoSlider
														onChange={this.sliderChange}
														max={200}
														min={0}
														aria-labelledby="discrete-slider-custom"
														value={this.state.maxDistance === '' ? 0 : this.state.maxDistance}
													/>
												</Grid>
												<Grid item container xs={2} alignItems="center"></Grid>
												<Grid item container xs={1} alignItems="center"></Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
							<Grid className="bizz-col bizz-col--right"  item container xs direction='row'>
								<Grid item container direction='column'>
									<Grid className="bizz-wrapper-title" container item>
										{icons.title}
										<Typography className="bizz-box-title">Store Photos</Typography>
										<span>(tap on each camera icon to add store photo)</span>
									</Grid>
									<Grid className="bizz-store-photos-box shadow border-radius" item container>
										<Grid className="bizz-store-photos-dropzone" item xs={4}>
											<Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '1')} multiple={false} accept="image/*">
												{({getRootProps, getInputProps}) => (
													<Grid>
														<Grid {...getRootProps()}>
															<input {...getInputProps()} />
															<Grid className="bizz-photos-topic">
																<img  className={`bizz-photos-img bizz-photos-img--${isImageExist1}`} draggable={false} src={this.state.imageCrop1 === '' ? camera : this.state.imageCrop1} />
															</Grid>
														</Grid>
													</Grid>
												)}
											</Dropzone>
										</Grid>
										<Grid className="bizz-store-photos-dropzone" item xs={4}>
											<Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '2')} multiple={false} accept="image/*">
												{({getRootProps, getInputProps}) => (
													<Grid>
														<Grid {...getRootProps()}>
															<input {...getInputProps()} />
															<Grid className="bizz-photos-topic bizz-photos-topic--middle">
																<img className={`bizz-photos-img bizz-photos-img--${isImageExist2}`} draggable={false} src={this.state.imageCrop2 === '' ? camera : this.state.imageCrop2} />
															</Grid>
														</Grid>
													</Grid>
												)}
											</Dropzone>
										</Grid>
										<Grid className="bizz-store-photos-dropzone" item xs={4}>
											<Dropzone onDrop={isReadonly() ? null : this.onUploadDrop.bind(this, '3')} multiple={false} accept="image/*">
												{({getRootProps, getInputProps}) => (
													<Grid>
														<Grid {...getRootProps()}>
															<input {...getInputProps()} />
															<Grid className="bizz-photos-topic">
																<img className={`bizz-photos-img bizz-photos-img--${isImageExist3}`} draggable={false} src={this.state.imageCrop3 === '' ? camera : this.state.imageCrop3} />
															</Grid>
														</Grid>
													</Grid>
												)}
											</Dropzone>
										</Grid>
									</Grid>
								</Grid>
								<Grid className="bizz-merchant-wrapper" item xs={2} sm container direction='column'>
									<Grid className="bizz-wrapper-title" item container>
										{icons.title}
										<Typography className="bizz-box-title">Merchant Contact info</Typography>	
									</Grid>
									<Grid className="bizz-merchant-contact-info-box shadow border-radius" item xs={12} sm container>
										<Grid className="bizz-merchant-textfield" container item direction="column" alignItems="flex-start" xs={12}>
											<Typography>Your Web site (or Facebook Page)</Typography>
											<TextField
												className='bizz-merchant-input'
												fullWidth
												placeholder='Enter Your Web site or Facebook page here'
												value={this.state.website !== '' ? this.state.website : ''}
												onChange={isReadonly() ? null : this.handleWebsiteChange}
												error={this.state.websiteError}
												helperText={this.state.websiteError ? 'Please enter a valid URL.' : ''}
											>
											</TextField>
										</Grid>
										<Grid className="bizz-merchant-textfield" container item direction="column-reverse" alignItems="flex-start" xs={12}>
										<TextField
											// disabled
											className='bizz-merchant-input'
											fullWidth
											placeholder='Enter Your Phone Number Here'
											value={this.state.phoneNumber !== null ? this.state.phoneNumber : ''}
											onChange={(e) => this.handlePhoneChange(e)}
										>
										</TextField>
											<Typography>Phone Number</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item container direction='column'>
									<Grid item container justify="center" className="bizz-wrapper-title bizz-wrapper-title--preview">
										<Typography className="bizz-box-title bizz-box-title--preview">Preview</Typography>
										<span>(The way Your Customer see Your info on PIIP app Promotion)</span>
									</Grid>
									<Grid className="bizz-preview-box bizz-preview-box--preview shadow border-radius" item container>
									</Grid>
								</Grid>
							</Grid>
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
						</Grid>
						<Grid className="bizz-button-wrapper" item container direction="row" justify="space-around">
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
							<Button className="bizz-button-btn bizz-button-btn--cancel border-radius" variant="outlined" onClick={this.handleCancelMerchantInfo} color="primary">
								Cancel
							</Button>
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
							<Button className="bizz-button-btn bizz-button-btn--save border-radius" variant="contained" onClick={this.handleSaveMerchantInfo} color="primary">
								Save
							</Button>
							<Grid className='settings-dialog-blank settings-dialog-blank--half settings-dialog-blank--bizz-card' container item xs={1}></Grid>
						</Grid>
					</div>
				</Layout>
				<ColorDialog
						className="bizz-color-dialog"
						aria-labelledby="max-width-dialog-title"
						open={this.state.openCrop}
						onClose={this.handleClosePhotoSelection}

					>
					<DialogTitle className="bizz-color-dialog-title" disableTypography>
						Add Logo and Images
					</DialogTitle>
					<DialogContent className="bizz-color-dialog-content">
						<Cropper
							className="bizz-color-dialog-cropper"
							src={this.state.storeImage.photo}
							onChange={this.onCropChange}
							crop={this.state.crop}
							ref="imageCrop"
							onImageLoaded={this.handleImageLoaded}
							onComplete={this.handleImageCropComplete}
						/>
					</DialogContent>
					<DialogActions className="bizz-color-dialog-buttons">
						<Button className='bizz-color-dialog-button bizz-color-dialog-button--first' autoFocus onClick={this.handleClosePhotoSelection} variant="contained" color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleCrop} className='bizz-color-dialog-button bizz-color-dialog-button--second' variant="contained" color="primary">
							Add
						</Button>
					</DialogActions>
				</ColorDialog>
				<Dialog
					open={this.state.categoryOpen}
					onClose={this.handleCloseCategory}
					className='bizz-category-dialog'
				>
					<DialogTitle className="bizz-category-dialog-title" disableTypography>
						Choose Category:
					</DialogTitle>
					<DialogContent dividers className='bizz-category-dialog-content'>
						<RadioGroup
							aria-label="ringtone"
							name="ringtone"
							value={this.state.merchantCategory}
							onChange={this.handleChangeMerchantCategories}
							// defaultValue={this.state.merchantCategory}
						>
						{Object.keys(this.props.merchantCategories).map((key) => {
							return (
								<Grid className="bizz-category-dialog-item" key={this.props.merchantCategories[key].id} container justify="space-between" alignItems="center">
									<FormControlLabel value={this.props.merchantCategories[key].english} key={this.props.merchantCategories[key].id} control={<Radio />} label={this.props.merchantCategories[key].english} />
									<img src={this.props.merchantCategories[key].icon} draggable={false} />
								</Grid>
							)
						})}
						</RadioGroup>
					</DialogContent>
					<DialogActions justify="center">
						<Button className="bizz-category-dialog-btn" variant="outlined" autoFocus onClick={this.handleCloseCategory} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</WrapperContent>
		)
	}
}

	BiizCard.propTypes = {
		maxDistance: PropTypes.number,
		merchantInfo: PropTypes.object,
		getStoreCategories: PropTypes.func.isRequired,
		userType: PropTypes.number.isRequired,
		token: PropTypes.string.isRequired,
		isAuthorized: PropTypes.bool.isRequired,
		connectWeb: PropTypes.func.isRequired,
		uid: PropTypes.number,
		getMerchantCard: PropTypes.func.isRequired,
		getMerchantMaxDistance: PropTypes.func,
		beginUpload: PropTypes.func,
		addMerchantMaxDistance: PropTypes.func,
		addMerchantCard: PropTypes.func,
};

export default connect(({common, auth}) => {
  return {
		merchantInfo: common.merchantInfo,
		maxDistance: common.maxDistance,
		merchantCategories: common.merchantCategories,
		isAuthorized: auth.isAuthorized,
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
  }
}, (dispatch) => {
  const actions = { getMerchantCard, getStoreCategories, connectWeb, getMerchantMaxDistance, uploadError, beginUpload, addMerchantMaxDistance, addMerchantCard };
  return bindActionCreators(actions, dispatch);
})(BiizCard);