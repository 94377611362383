import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Grid, Button, Typography, Dialog, DialogTitle, DialogActions, TextField, Switch } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CheckIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import { isReadonly, isDateBefore }from '../../lib/utils';
import IconList from '../IconList';
import {  MuiPickersUtilsProvider, TimePicker, DatePicker  } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { getPayments } from '../../store/common/actions';
import { getPromotions, setPromotionLong, publishPromotion, endPromotion } from '../../store/promotions/actions';
import './styles.css';

function importAll(r) {
  const images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const iconImages = importAll(require.context('../../assets/images/icons', false, /\.(png|jpe?g|svg)$/));

class PromotionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      titleError: false,
      description: '',
      descriptionError: false,
      age: false,
      ageError: '',
      confirmAllLegal: false,
      confirmAllLegalError: false,
      limitAge: 0,
      date: new Date(),
      dateError: '',
      timeError: '',
      template_id: null,
      showIcons: false,
      showDate: false,
      defaultDate: new Date(),
      defaultTime: new Date(),
      icon: '',
      iconError: '',
      valid: 0,
      id: null,
      pushNotification: false,
      noSubscription: false,
      validEndDate: true,
      validEndTime: true,
      editPromoStatus: null,
      promotionSaved: false,
    };
  }

  componentDidMount() {
    if (!this.props.handleButtonSelect) {
      this.setPromo(this.props.data)
    }
    this.props.getPayments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.promotionForPublish !== prevProps.promotionForPublish) {
      this.setState({
        id: this.props.promotionForPublish.id
      })
    }
    if (this.props.data.length === 0 && this.props.promotionForPublish && this.state.id === null) {
       this.setState({
         id: this.props.promotionForPublish.id
       })
    }
  }
  setPromo = (data) => {
    let date = data.end_time;
    this.setState({
      id: data.id,
      title: data.title,
      description: data.description,
      age: data.limit_age === 18 ? true : false,
      defaultDate: date ? moment.unix(date).toDate() : moment(),
      defaultTime: date ? moment.unix(date).toDate() : moment(),
      icon: data.template_id,
      editPromoStatus: data.expired ? data.state === 0 ? 'Draft' : 'Expired' : data.state === 0 ? 'Draft' : 'Active',
      confirmAllLegal: data.expired ? false : data.state === 0 ? false : true,
    })
  }

  handleTitleChange = (event) => {
		// if(event.target.value === '' || event.target.value === null) {
		// 	this.setState({titleError: true});
		// } else {
			this.setState({title: event.target.value, titleError: false});
      this.props.title(event.target.value)
		// }
  };

  handleDescriptionChange = (event) => {
		if(event.target.value === ' ' || event.target.value === null) {
			this.setState({descriptionError: true});
		} else {
			this.setState({description: event.target.value, descriptionError: false});
      this.props.description(event.target.value)
		}
  };

  handleIconsShow = () => {
    this.setState({showIcons: true})
  }

  handleCloseIcons = () => {
    this.setState({showIcons: false, icon: ''})
  }

  handleIconChange = (value) => {
    this.setState({icon: value});
  };

  handleChooseIcons = () => {
    this.setState({showIcons: false, iconError: ''})
    this.props.images(this.state.icon)
  }

  handleDateChange = (value) => {
    this.setState({defaultDate: value, validEndDate: false, dateError: '', timeError: ''})
    this.props.date(value)
  }

  handleTimeChange = (value) => {
    this.setState({defaultTime: value, validEndTime: false, timeError: ''})
  };

  handleCloseDate = () => {
    this.setState({showDate: false})
  }

  handleChangeAllLegal = (event) => {
    this.setState({confirmAllLegal: event.target.checked, confirmAllLegalError: false});
  }

  handleAgeChange = (event) => {
    this.setState({age: event.target.checked, limit: this.state.age ? 18 : 0});
  }

  handlePushNotification = (event) => {
    this.setState({pushNotification: event.target.checked});
  }

  handleCancelPromo = () => {
    this.setState({
      id: null,
      title: '',
      description: '',
      age: false,
      defaultDate: new Date(),
      defaultTime: new Date(),
      icon: ''
    })
    this.props.closeEditPromotion()
  }

  handlePublishPromo = () => {
    if (this.state.editPromoStatus !== 'Active') {
      if (this.props.merchantPayment && this.props.merchantPayment[1] && isDateBefore(this.props.merchantPayment[1])) {
      this.setState({ noSubscription: true });
      return false;
    }

    if  (this.props.activePromotions.length > 0) {
      return false;
    }
    if (this.state.id !== undefined && this.state.id !== '') {
      this.props.publishPromotion([this.state.id, undefined, this.state.pushNotification]);
    }
    } else {
      this.props.endPromotion([this.state.id]);
    }
    this.props.getPromotions([null, 0]);
    this.props.getPromotions([null, 2]);
    this.props.closeEditPromotion()
  }

  _validate = (draft) => {
    let error = false;

    if(!(this.state.title !== '' && this.state.title !== null)){
      this.setState({titleError: true});
      error = true;
    }
    if(!(this.state.description !== '' && this.state.description !== null)){
      this.setState({descriptionError: true});
      error = true;
    }
    if(draft !== true){ // If saved to draft let it save without check
      if(this.state.validEndDate){
        if (this._mergeDateTime() < moment()) {
          this.setState({dateError: "Invalid promotion date"});
          error = true;
        }
      }
      if(this._mergeDateTime() < moment()){
        this.setState({timeError: "Promotion end time must be after current time"});
        error = true;
      } else {
        this.setState({validEndDate: false})
      }
      if(this._mergeDateTime() > moment().add(30, 'days')){
        this.setState({timeError: "Promotion end time must be within next 30 days"});
        error = true;
      }
      if(!(this.state.defaultTime !== '' && this.state.defaultTime !== null)){
        this.setState({timeError: "Invalid promotion time"});
        error = true;
      }
      if(this.state.confirmAllLegal === false || this.state.confirmAllLegal === undefined){
        this.setState({confirmAllLegalError: true});
        error = true;
      }
    }
    if(!(this.state.icon !== '' && this.state.icon !== null)){
      this.setState({iconError: "Invalid promotion icon"});
      error = true;
    }

    return (error) ? false : true;  
  };

  _mergeDateTime() {
    const momentTime = moment(this.state.defaultTime);
    const momentDate = moment(this.state.defaultDate);
    
    const endTime = moment({
      year: momentDate.year(),
      month: momentDate.month(),
      day: momentDate.date(),
      hour: momentTime.hours(),
      minute: momentTime.minutes()
    });
    
    return endTime;
  }
  
  handleSavePromo = () => {
    if(this._validate()){
      this._savePromotion(this.state.id);
    }
  }

  _getPromotionData = () => {
    const end_time = this._mergeDateTime();
    return {
      title: this.state.title,
      description: this.state.description,
      end_time,
      limit_age: this.state.limitAge,
      template_id: this.state.icon,
      limit_gender: "a",
      confirm: this.state.confirmAllLegal,
      pushNotification: this.state.pushNotification
    };  
  };

  _savePromotion = (promoId) => {
    let data = this._getPromotionData();
    if (this.props.handleButtonSelect === 'create') {
      data.end_time = moment.utc(data.end_time);
      this.props.setPromotionLong([data, null]);
    } else {
      data.end_time = moment.utc(data.end_time);
      this.props.setPromotionLong([data, this.state.id]);
    }
    this.setState({promotionSaved: true})
    this.props.getPromotions([null, 0]);
    this.setState({promotionSaved: true})
  };

  render() {
    const { iconError, title, titleError, description, descriptionError, dateError, timeError, confirmAllLegal, confirmAllLegalError } = this.state
    let check = (<CheckIcon className='promotion-edit-check-icon'/>);
    let icons = {};
    let iconsDefaults = {
      icon: ( <div className='promotion-edit-create-promotion-icon-wraper'>1</div> ),
      title: ( <div className='promotion-edit-create-promotion-icon-wraper'>2</div>),
      description: ( <div className='promotion-edit-create-promotion-icon-wraper'>3</div>),
      date: ( <div className='promotion-edit-create-promotion-icon-wraper'>4</div>),
      time: ( <div className='promotion-edit-create-promotion-icon-wraper'>5</div>)
    };

    icons.title = this.state.title === "" ? iconsDefaults.title : check;
    icons.icon = this.state.icon === "" ? iconsDefaults.icon : check;
    icons.description = this.state.description === "" ? iconsDefaults.description : check;
    icons.date = check;
    icons.time = check;
    let showIcon = this.state.icon === ""
      ? <div className='promotion-edit-create-promotion-icon-wraper--check-icon' onClick={this.handleIconsShow}/>
      : (
        <img className='promotion-edit-create-promotion-icon-wraper--check-image' src={iconImages[this.state.icon + '.png']}  onClick={this.handleIconsShow} />
      );
    return (
      <Grid className="promotion-edit" item container xs={12}>
        <Grid className="promotion-edit-item" item xs={7} container direction='column'>
          <Grid className="promotion-edit-title" item container>
            <Typography>Create Promo</Typography>
          </Grid>
          <Grid className="promotion-edit-box border-radius shadow" item container>
            <Grid className="promotion-edit-container" item container direction='row'>
              <Grid className="promotion-edit-header-texts" item container xs={8} alignItems="flex-start">
                <Grid className="promotion-edit-checkpoint">{icons.icon}</Grid>
								<Grid className="promotion-edit-header-selection" container item direction="column">
                  <Typography className='promotion-edit-header-text'>Select Icon</Typography>
									<Typography>Tap Circle to select</Typography>
									{iconError === "" ? null : <Typography className='promotion-edit-header-text-error'>{iconError}</Typography>}
								</Grid>
              </Grid>
              <Grid item container xs={4} justify="flex-end">
                {showIcon}
              </Grid>
            </Grid>
            <Grid className="promotion-edit-container" item container xs={12}>
							<Grid className="promotion-edit-checkpoint">{icons.title}</Grid>
              <Grid className="promotion-edit-header-selection" item container direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Promotion Title</Typography>
                <TextField
                  className="promotionEditTitle"
                  fullWidth
                  placeholder={title ? title : 'Enter Promotion Title here'}
                  value={title ? title : ''}
                  onChange={isReadonly() ? null : this.handleTitleChange}
                  error={titleError}
                  helperText={titleError ? "Invalid promotion title" : ''}
                  inputProps={{
                    maxLength: 30
                  }}
                />
              </Grid>
            </Grid>
            <Grid className="promotion-edit-container" item container direction='row' xs={12}>
              <Grid className="promotion-edit-checkpoint">{icons.description}</Grid>
              <Grid className="promotion-edit-header-selection" item container direction='column' alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Describe Promotion shortly</Typography>
                <TextField
                  className="promotionEditTitle"
                  fullWidth
                  placeholder={description ? description : 'Promotion Description'}
                  value={description ? description : ''}
                  onChange={isReadonly() ? null : this.handleDescriptionChange}
                  error={descriptionError}
                  helperText={descriptionError ? "Invalid promotion description" : ''}
                  inputProps={{
                    maxLength: 10000
                  }}
                />
              </Grid>
            </Grid>
            <Grid className="promotion-edit-container" item container direction='row' xs={12}>
              <Grid className="promotion-edit-checkpoint">{icons.date}</Grid>
              <Grid className="promotion-edit-header-selection" item container direction='column' alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Select Promotion ending date</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    value={this.state.defaultDate}
                    onChange={this.handleDateChange}
                    format='MM-DD-YYYY'
                    className={`promotion-edit-date-time ${dateError ? 'promotion-edit-header-text-error' : null}`}
                    helperText={dateError}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid className="promotion-edit-container" item container direction='row' xs={12}>
              <Grid className="promotion-edit-checkpoint" item container>{icons.time}</Grid>
              <Grid className="promotion-edit-header-selection"item container direction='column' alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>Select Promotion ending time</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    value={this.state.defaultTime}
                    onChange={this.handleTimeChange}
                    className={`promotion-edit-date-time ${timeError ? 'promotion-edit-header-text-error' : null}`}
                    helperText={timeError}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid className="promotion-edit-container" item container direction='row' xs={12} alignItems="center">
              <Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
              <Grid item container xs={9} direction="column" alignItems="flex-start">
                <Typography className='promotion-edit-header-text'>I confirm all is legal</Typography>
                {confirmAllLegalError ?
                  <Typography className='promotion-edit-header-text-error'>You must accept all is legal</Typography> :
                  null }
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <Switch
                  className='promotion-edit-switch'
                  checked={confirmAllLegal}
                  required={true}
                  onChange={this.handleChangeAllLegal}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} direction='row' alignItems="center">
              <Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
              <Grid item container xs={9}>
                <Typography className='promotion-edit-header-text'>Push notifications about Promo to followers</Typography>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <Switch
                  className='promotion-edit-switch'
                  checked={this.state.pushNotification}
                  onChange={this.handlePushNotification}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} direction='row' alignItems="center">
              <Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
              <Grid item container xs={9} >
                <Typography className='promotion-edit-header-text'>Viewers needs to be over 18yo (optional)</Typography>
              </Grid>
              <Grid item container xs={2} justify="flex-end">
                <Switch
                  className='promotion-edit-switch'
                  checked={this.state.age}
                  onChange={this.handleAgeChange}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="promotion-edit-item" item container xs={2} alignItems="flex-end">
          <Grid item container xs={12} direction="column" alignItems="flex-end">
            <Button  onClick={this.handleCancelPromo} className='promotion-main-buttons' variant="contained" color="primary">
              Cancel
            </Button>
            <Button disabled={this.props.handleButtonSelect === 'create' ? false : this.state.promotionSaved ? true : this.state.editPromoStatus === 'Draft' ? false : true} onClick={this.handleSavePromo} className='promotion-main-buttons' variant="contained" color="primary">
              Save
            </Button>
            <Button 
              disabled={
                // this.props.handleButtonSelect === 'create' ? true :
                this.state.promotionSaved ? false :
                this.state.editPromoStatus === 'Active' ? false :
                true
              }
              onClick={this.handlePublishPromo} className={`promotion-main-buttons promotion-main-buttons--publish`} variant="contained" color="primary">
              {this.state.editPromoStatus && this.state.editPromoStatus === 'Active' ? 'Unpublish' : 'Publish'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
					open={this.state.showIcons}
					onClose={this.handleCloseIcons}
					className='icon-list-dialog'
				>
					<DialogTitle className='icon-list-dialog-title' disableTypography>
						Select Icon
					</DialogTitle>
          <IconList icons={iconImages} handleClick={this.handleIconChange} choose={this.state.icon}/>
					<DialogActions className="icon-list-dialog-actions">
						<Button className={`promotion-edit-dialog-btn promotion-edit-dialog-btn--cancel`} variant="contained" autoFocus onClick={this.handleCloseIcons} color="primary">
							Cancel
						</Button>
            <Button className={`promotion-edit-dialog-btn promotion-edit-dialog-btn--choose`} variant="contained" autoFocus onClick={this.handleChooseIcons} color="primary">
							Choose
						</Button>
          </DialogActions>
				</Dialog>
      </Grid>
    );
  }
}

PromotionEdit.propTypes = {

};

export default connect(({ common, promotions, auth }) => {
  return {
    allPromotions: promotions.allPromotions,
    activePromotions: promotions.activePromotions,
    merchantPayment: common.merchantPayment,
    promotionForPublish: promotions.promotionForPublish
  }
}, (dispatch) => {
  const actions = { getPayments, getPromotions, setPromotionLong, publishPromotion, endPromotion };
  return bindActionCreators(actions, dispatch);
})(PromotionEdit);