import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducers';
import messages from './messages/reducers';
import common from './common/reducers';
import promotions from './promotions/reducers';
import socket from '../lib/socket';
import listeners from './comm/listener';


listeners(socket);

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
	messages,
	common,
	promotions
});

export default createRootReducer;