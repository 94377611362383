import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import logo from './images/logo.svg';
import sonar from './images/sonar.png';
import oval from './images/oval.svg';
import BackgroundImage from './images/background.png';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {connectWeb, verifyAdmin, loginAgent} from '../../store/auth/actions';
import { history } from '../../store/index';
import WrapperContent from '../../components/WrapperContent';
import Layout from '../../components/Layout';
import './styles.css';


class Load extends React.Component {
  
  componentDidMount(){
    let body = document.getElementsByTagName('body')[0];
    console.log('object', body)
    body.style.backgroundImage = `url(${BackgroundImage})`;
    body.style.backgroundSize = "100%";
    let that = this;
    setTimeout(function(){
      if(localStorage.getItem('adminlogin')){
        that.props.verifyAdmin([localStorage.getItem('token'), parseInt(localStorage.getItem('uid'))]);
        that.props.connectWeb([parseInt(localStorage.getItem('uid')), localStorage.getItem('token'), that.props.userType]);
      }else{
        if (that.props.userType !== 3) {
          that.props.connectWeb([that.props.uid, that.props.token, that.props.userType]);
          localStorage.removeItem('tokener');
        } else {
          that.props.loginAgent(['', parseInt(localStorage.getItem('uid')), localStorage.getItem('token'), that.props.userType])
        }
      }
    },2500);
  }

  componentWillUnmount(){
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundImage = "";
    body.style.backgroundSize = "";
    // unlisten();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthorized === true) {
      localStorage.removeItem('adminlogin');
      history.push('/dashboard');
    }
    if (this.props.isValidToken === false) {
      history.push('/register');
    }
  }

  render() {
    return (
      <WrapperContent className="wrapper-content--load">
        <Layout page="load">
          <Grid className="loader">
            <img className="loader-logo" src={logo} alt="logo"/>
            <img className="loader-oval" src={oval} alt="oval"/>
            <img className="loader-sonar" src={sonar} alt="sonar"/>
            <Grid className="loader-text">
              <Typography>Biiz</Typography>
              <Typography>by Piip</Typography>
            </Grid>
          </Grid>
        </Layout>
      </WrapperContent>
    );
  }
}

Load.propTypes = {
  //token: PropTypes.string,
  //uid: PropTypes.number,
  userType: PropTypes.number.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isValidToken: PropTypes.bool.isRequired,
  connectWeb: PropTypes.func.isRequired,
  loginAgent: PropTypes.func.isRequired,
  verifyAdmin: PropTypes.func
};

export default connect(({auth}) => {
  return {
    token: auth.token,
    uid: auth.uid,
    userType: auth.userType,
    isAuthorized: auth.isAuthorized,
    isValidToken: auth.isValidToken
  }
}, (dispatch) => {
  const actions = {connectWeb, verifyAdmin, loginAgent};
  return bindActionCreators(actions, dispatch);
})(Load);
