import {
  REGISTERWEB,
  REGISTERWEB_RESULT,
  REGISTERWEB_ERROR,
  VERIFYWEB,
  VERIFYADMIN,
  CONNECTWEB,
  LOGINAGENT,
  HIDE_ERROR_AUTH,
  SETACTIVEPAGE,
  SETPREVIOUSPATH,
  SETAUTHORIZATION,
  LOADER,
  RESETATTACHMENT
} from './constants';

export function registerWeb(params) {
  return {
    type: REGISTERWEB,
    params
  };
}

export function registerWebResult(params) {
  return {
    type: REGISTERWEB_RESULT,
    params
  };
}

export function registerWebError(params) {
  return {
    type: REGISTERWEB_ERROR,
    params
  };
}

export function verifyWeb(params) {
  return {
    type: VERIFYWEB,
    params
  };
}

export function verifyAdmin(params) {
  return {
    type: VERIFYADMIN,
    params
  };
}

export function connectWeb(params) {
  return {
    type: CONNECTWEB,
    params
  };
}

export function loginAgent(params) {
  return {
    type: LOGINAGENT,
    params
  };
}

export function hideErrorAuth() {
  return {
    type: HIDE_ERROR_AUTH
  };
}

export function setActivePage(params) {
  return {
    type: SETACTIVEPAGE,
    params
  };
}

export function setPreviousPath(params) {
  return {
    type: SETPREVIOUSPATH,
    params
  };
}

export function setAuthorization(params) {
  return {
    type: SETAUTHORIZATION,
    params
  };
}

export function loader(params) {
  return {
    type: LOADER,
    params
  };
}

export function resetAttachment() {
  return {
    type: RESETATTACHMENT
  };
}