import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Divider, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAutoReplyMessage, addAutoReplyMessage } from '../../store/common/actions';
import './styles.css';

const maxLetters = 4096;

class AutoReply extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoReply: '',
			autoReplyError: false,
			autoReplyErrorMsg: '',
			lettersCounter: maxLetters,
		}
	}

  componentDidMount() {
    this.props.getAutoReplyMessage()
  }

	componentDidUpdate(prevProps)	{
		if (prevProps.autoReply !== this.props.autoReply) {
			this.setState({
				autoReply: this.props.autoReply.length > 0 && this.props.autoReply[0].answer !== '' && this.props.autoReply[0].answer !== null ? this.props.autoReply[0].answer: ''
			})
		}
	}

	handleChangeAutoReplyMessage = (e) => {
		let letters = maxLetters - e.target.value.length;
    this.setState({autoReply: e.target.value, lettersCounter: letters})
	}

	handleSaveAutoReplyMessage = () => {
		let msg = this.state.autoReply !== '' ? this.state.autoReply : null;
		this.props.addAutoReplyMessage([msg]);
    this.setState({autoReply: '', lettersCounter: maxLetters})
	}

	render() {
		const { autoReply, autoReplyError, autoReplyErrorMsg } = this.state
		return (
			<Dialog
				open={this.props.openAutoReply}
				className='settings-dialog'
			>
				<Grid className='settings-dialog-blank' container item xs={1}></Grid>
				<DialogTitle className='settings-dialog-text'>
					<ArrowBackIcon onClick={this.props.handleCloseAutoReplyMessage} className="order-item-edit-dialog-back-btn" />
					Auto Replay
					<Divider className='settings-dialog-title-divider'/>
				</DialogTitle>
				<Grid className='settings-dialog-blank settings-dialog-blank--half' container item xs={1}></Grid>
				<DialogContent className='settings-auto-replay-content-main'>
					<Typography className='settings-dialog-content-header-text'>Auto replay message</Typography>
					<TextField
						className="settings-auto-replay-input settings-auto-replay--border"
						fullWidth
						multiline
						rows="4"
						value={autoReply !== '' ? autoReply : ''}
						onChange={this.handleChangeAutoReplyMessage}
						variant="filled"
						error={autoReplyError}
						helperText={autoReplyError ? autoReplyErrorMsg : ''}
					/>
					<DialogActions>
						<Button
							disabled={autoReply === '' ? true : false}
							variant="contained"
							color="primary"
							className='settings-main-buttons settings-auto-replay-buttons--width'
							onClick={this.handleSaveAutoReplyMessage}
						>
							Set Auto replay message
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		)
	}
}

AutoReply.propTypes = {
  userType: PropTypes.number.isRequired,
  uid: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(({ common, auth }) => {
  return {
		token: auth.token,
		uid: auth.uid,
		userType: auth.userType,
		autoReply: common.autoReply
  }
}, (dispatch) => {
  const actions = {getAutoReplyMessage, addAutoReplyMessage};
  return bindActionCreators(actions, dispatch);
})(AutoReply);